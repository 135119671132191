import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { blockUser, updateUserProfile } from '../../api/user'
import { objectToForm, timeDiff, getS3Url, historyPushState } from '../../utils/tool'

import noProfileImage from '../../assets/images/asset--no-profile.svg'
import noBackgroundImage from '../../assets/images/asset--no-background.svg'
import UiButton from '../../components/ui/Button'
import UiIcon from '../../components/ui/Icon'
import UiCountry from '../../components/ui/Country'
import UiImage from '../../components/ui/Image'
import UiInput from '../../components/ui/Input'
import UiEnlargeImage from '../../components/ui/EnlargeImage'
import UiConfirm from '../../components/ui/layer/Confirm'

import './Profile.scss'

class Profile extends Component {
  imageRef = createRef()

  state = {
    loading: false,
    editMode: false,
    value: {
      profileImage: null,
      profileBackground: null,
      nickname: '',
      tags: []
    },
    error: {
      profileImage: null,
      profileBackground: null,
      nickname: null,
      tags: null
    },
    description: {
      profileImage: null,
      profileBackground: null,
      nickname: null,
      tags: null
    },
    tagsValue: '',
    showAlert: {
      block: false
    },
    enlarge: false
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
  }

  inputNicknameOnChange = (value) => {
    this.inputOnChange('nickname', value)
  }

  inputTagsOnFocus = () => {
    const {
      tagsValue
    } = this.state

    if (tagsValue.length < 1) {
      this.setState({ tagsValue: '#' })
    }

    for (let i = 0; i < 300; i++) {
      setTimeout(() => {
        document.getElementById('defaultLayout').scrollTop = i
      }, i)
    }
  }

  inputTagsOnBlur = () => {
    const {
      tagsValue
    } = this.state

    if (tagsValue.trim() === '#') {
      this.setState({ tagsValue: '' })
    }
  }

  inputTagsOnChange = (value, ref) => {
    const {
      tagsValue
    } = this.state

    let cursor = ref.selectionStart

    if (tagsValue.length > 0) {
      const newValue = value
      let lastNew = false
      if ([' ', '#'].includes(newValue.substr(-1, 1))) {
        lastNew = true
        if ([' #'].includes(tagsValue.substr(-2, 2))) {
          lastNew = false
        }
      }
      const tags = newValue.split(/[\s|#]+/g).reduce((result, tag) => {
        if (tag) {
          result.push(tag)
        }
        return result
      }, [])
      this.setStateInside('value', 'tags', tags)

      let inputValue = tags.join(' #')
      if (inputValue) {
        inputValue = '#' + inputValue
      }
      if (lastNew) {
        if (inputValue.length > 0) {
          inputValue += ' #'
        }
      }
      const beforeTagCount = (tagsValue.match(/#/g) || []).length
      const afterTagCount = (inputValue.match(/#/g) || []).length

      cursor += afterTagCount - beforeTagCount

      this.setState({ tagsValue: inputValue || '#' })
    } else {
      this.setState({ tagsValue: value })
    }
    this.setStateInside('error', 'tags', null)

    setTimeout(() => {
      if (ref.createTextRange) {
        const range = ref.createTextRange()
        range.move('character', cursor)
        range.select()
      } else {
        if (ref.selectionStart) {
          ref.focus()
          ref.setSelectionRange(cursor, cursor)
        } else {
          ref.focus()
        }
      }
    }, 0)
  }

  toggleEditMode = () => {
    const {
      editMode
    } = this.state

    const {
      t,
      user
    } = this.props

    this.setState({ editMode: !editMode })

    if (!editMode) {
      const userTags = user.tags || []
      let tagsValue = userTags.join(' #')
      if (tagsValue) {
        tagsValue = '#' + tagsValue
      }

      this.setState({
        value: {
          profileImage: user.profileImage || null,
          profileBackground: user.profileBackground || null,
          nickname: user.nickname || '',
          tags: userTags
        },
        error: {
          profileImage: null,
          profileBackground: null,
          nickname: null,
          tags: null
        },
        description: {
          profileImage: null,
          profileBackground: null,
          nickname: t('setting.profile.error.afterMonth'),
          tags: null
        },
        tagsValue: tagsValue
      })
    }
  }

  onSetEnlarge = (image, back = true) => {
    if (image) {
      let url = window.location.pathname
      if (window.location.search) {
        url += window.location.search
      }
      const hash = window.location.hash
      if (hash && !hash.includes('i')) {
        url += hash + 'i'
      } else {
        url += '#ri'
      }
      historyPushState(url)
    } else {
      if (back) {
        window.history.back()
      }
    }
    this.setState({ enlarge: image })
  }

  toTalk = () => {
    const {
      action,
      user,
      history
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      history.push({
        pathname: '/t/r',
        state: { action: 'direct', id: user.id || user._id }
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  toBlock = () => {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.toggleAlert('block')
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  confirmBlock = () => {
    const {
      action,
      user
    } = this.props

    this.setState({ loading: true })
    this.toggleAlert('block')

    blockUser({
      target: user.id
    }).then(() => {
      action.fetchUserData(true)
      this.setState({ loading: false })
    }).catch(error => {
      this.setState({ loading: false })
      console.error(error)
    })
  }

  editModeOnSubmit = () => {
    const {
      t,
      user
    } = this.props

    const {
      setUserData
    } = this.props.action

    const {
      profileImage,
      profileBackground,
      nickname,
      tags
    } = this.state.value

    const params = {}
    if (user.profileImage !== profileImage) {
      params.profileImage = profileImage
    }
    if (user.profileBackground !== profileBackground) {
      params.profileBackground = profileBackground
    }
    if (user.nickname !== nickname) {
      params.nickname = nickname
    }
    if (JSON.stringify(user.tags) !== JSON.stringify(tags)) {
      params.tags = tags
    }

    updateUserProfile(objectToForm(params)).then(response => {
      if (response.success) {
        setUserData(response.data)
        this.toggleEditMode()
      } else {
        this.setStateInside('error', 'nickname', t('setting.profile.error.alreadyUsingNickname'))
      }
    }).catch(error => {
      console.error(error)
    })
  }

  detectHash = () => {
    const hash = window.location.hash
    if (!hash || !hash.includes('i')) {
      this.onSetEnlarge(null, false)
    }
  }

  componentDidMount() {
    this.setState({ editMode: false })

    window.addEventListener('popstate', this.detectHash)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectHash)
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefImage = createRef(null)

    const {
      editMode,
      value,
      error,
      description,
      tagsValue,
      showAlert,
      loading,
      enlarge
    } = this.state

    const {
      t,
      goBack,
      user
    } = this.props

    const {
      userData
    } = this.props.state

    const isEditModeSubmitDisabled = () => {
      return !value.nickname || error.nickname
    }

    const isEditModeNicknameDisabled = () => {
      return user && user.nicknameUpdatedAt && timeDiff('d', user.nicknameUpdatedAt) < 30
    }

    const hasUserTags = () => {
      return user && user.tags && user.tags.length > 0
    }

    const isMe = user && userData && (user.id === userData.id || user.userId === userData.userId)

    const isBlock = userData ? userData.blockList.includes(user.id) : false

    return(
      <div className="layout-container">
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        {user &&
          <div className="my-profile">
            <div className="profile-background"
                 style={{backgroundImage: (!isBlock && user.profileBackground) ? `url('${getS3Url(user.profileBackground)}')` : null}}>
              <div className="profile-background-wrap"
                   onClick={() => {
                     if (user.profileBackground) {
                       this.onSetEnlarge(user.profileBackground)
                     } else if (isMe) {
                         this.toggleEditMode()
                       }
                     }} />
              {!editMode &&
                <>
                  <div className="country">
                    <UiCountry code={user.country} />
                  </div>
                  <div className="buttons">
                    {goBack &&
                      <UiIcon name="close white"
                              onClick={() => { goBack() }} />
                    }
                  </div>
                </>
              }
              {editMode &&
                <>
                  <UiImage blankImage={noBackgroundImage}
                           value={user.profileBackground}
                           resizeSize={{ width: 1024, height: 1024 }}
                           width="100%"
                           height="100%"
                           placeholder={(
                             <div className="background-icon">
                               <UiIcon name="photo" />
                             </div>
                           )}
                           onChange={value => this.setStateInside('value', 'profileBackground', value)} />
                  <div className="buttons left">
                    <UiIcon name="back white"
                            onClick={() => {
                              this.toggleEditMode()
                            }} />
                  </div>
                </>
              }
            </div>
            <div className="profile-content">
              {!editMode &&
                <>
                  <div className="profile-information">
                    <div className="profile-image"
                         style={{backgroundImage: `url('${isBlock ? noProfileImage : (getS3Url(user.profileImage) || noProfileImage)}')`}}
                         onClick={() => {
                           if (user.profileImage) {
                             this.onSetEnlarge(user.profileImage)
                           } else if (isMe) {
                             this.toggleEditMode()
                           }
                         }}> </div>
                    <div className="profile-nickname">
                      {user.nickname}
                    </div>
                    {isMe &&
                      <div className="profile-action">
                        <button onClick={this.toTalk}>
                          <UiIcon name="chat" />
                          {t('setting.profile.chatMe')}
                        </button>
                        <span className="gap" />
                        <button onClick={this.toggleEditMode}>
                          <UiIcon name="pencil" />
                          {t('setting.profile.edit')}
                        </button>
                      </div>
                    }
                    {(!isMe && !isBlock) &&
                      <div className="profile-action">
                        <button onClick={this.toTalk}>
                          <UiIcon name="chat" />
                          {t('setting.profile.chat')}
                        </button>
                        <span className="gap" />
                        <button onClick={this.toBlock}>
                          <UiIcon name="deny" />
                          {t('setting.profile.block')}
                        </button>
                      </div>
                    }
                    {(!isMe && isBlock) &&
                      <div className="profile-action">
                        <button onClick={this.toBlock}>
                          <UiIcon name="deny" />
                          {t('setting.profile.unblock')}
                        </button>
                      </div>
                    }
                  </div>
                  <div className="profile-description">
                    {isBlock &&
                      <div className="tag">
                        <div className="content blocked">
                          {t('community.blocked')}
                        </div>
                      </div>
                    }
                    {!isBlock &&
                      <div className="tag">
                        <div className="label">
                          {t('setting.profile.tag.title')}
                        </div>
                        {!hasUserTags() &&
                          <div className="content empty">
                            {t('setting.profile.tag.description')}
                          </div>
                        }
                        {hasUserTags() &&
                          <div className="content">
                            {user.tags.map((tag, i) => {
                              return (
                                <span className="tag"
                                      key={`user-tag-${i}`}>
                                  #{tag}
                                </span>
                              )
                            })}
                          </div>
                        }
                      </div>
                    }
                  </div>

                  <CSSTransition in={!!enlarge}
                                 timeout={500}
                                 mountOnEnter={true}
                                 unmountOnExit={true}
                                 nodeRef={nodeRefImage}
                                 classNames="fade">
                    <div className="user-profile-image"
                         ref={nodeRefImage}>
                      <UiEnlargeImage imageKey={enlarge}
                                      imageRef={this.imageRef}
                                      onClose={() => { this.onSetEnlarge(null) }} />
                    </div>
                  </CSSTransition>
                </>
              }
              {editMode &&
                <>
                  <div className="profile-editmode">
                    <div className="profile-image">
                      <UiImage blankImage={noProfileImage}
                               value={user.profileImage}
                               resizeSize={{ width: 256, height: 256 }}
                               placeholder={(
                                 <div className="profile-icon">
                                   <UiIcon name="photo" />
                                 </div>
                               )}
                               onChange={value => this.setStateInside('value', 'profileImage', value)} />
                    </div>
                    <div className="profile-information">
                      <UiInput type="text"
                               color="box"
                               placeholder={t('setting.profile.placeholder.nickname')}
                               label={t('common.nickname')}
                               value={value.nickname}
                               error={error.nickname}
                               description={description.nickname}
                               isDisabled={isEditModeNicknameDisabled()}
                               onChange={val => this.inputNicknameOnChange(val)} />

                      <UiInput type="textarea"
                               color="box"
                               placeholder={t('setting.profile.placeholder.tag')}
                               label={t('setting.profile.tag.title')}
                               value={tagsValue}
                               error={error.tags}
                               description={description.tags}
                               max={35}
                               maxExcept={/[#\s]/g}
                               onFocus={this.inputTagsOnFocus}
                               onBlur={this.inputTagsOnBlur}
                               onChange={(val, ref) => this.inputTagsOnChange(val, ref)} />

                      <UiButton text={t('common.complete')}
                                isDisabled={isEditModeSubmitDisabled()}
                                isFixed={true}
                                onClick={this.editModeOnSubmit} />
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        }

        {showAlert.block &&
          <UiConfirm content={isBlock ? t('talk.alert.unblock') : t('talk.alert.block')}
                     onOkay={() => { this.confirmBlock() }}
                     onCancel={() => { this.toggleAlert('block') }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(Profile, props)
export default withRouter(StateContainer)
