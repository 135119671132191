import httpClient from '../utils/http'

export function boardList (params) {
  const url = '/c/b/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardRequest (params) {
  const url = '/c/b/r'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardInfo (params) {
  const url = '/c/b/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardListBookmark (params) {
  const url = '/c/b/m'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostList (params) {
  const url = '/c/b/p/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostListAll (params) {
  const url = '/c/b/p/l/a'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCreate (params) {
  const url = '/c/b/p/c'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostInfo (params) {
  const url = '/c/b/p/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostBookmark (params) {
  const url = '/c/b/p/m'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostLike (params) {
  const url = '/c/b/p/k'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostNotification (params) {
  const url = '/c/b/p/n'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostDelete (params) {
  const url = '/c/b/p/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostReport (params) {
  const url = '/c/b/p/r'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostPickList (params) {
  const url = '/c/b/p/l/p'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostPopularList (params) {
  const url = '/c/b/p/l/r'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCommentCreate (params) {
  const url = '/c/b/p/c/c'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCommentList (params) {
  const url = '/c/b/p/c/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCommentInfo (params) {
  const url = '/c/b/p/c/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCommentLike (params) {
  const url = '/c/b/p/c/k'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCommentNotification (params) {
  const url = '/c/b/p/c/n'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCommentDelete (params) {
  const url = '/c/b/p/c/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostCommentReport (params) {
  const url = '/c/b/p/c/r'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostUrlCreate (params) {
  const url = '/c/b/p/c/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
