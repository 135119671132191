const LANG_KEY_NAME = 'lang'

export function getStoredLang () {
  return localStorage.getItem(LANG_KEY_NAME)
}

export function getLang () {
  return localStorage.getItem(LANG_KEY_NAME) || 'en-US'
}

export function setLang (lang) {
  localStorage.setItem(LANG_KEY_NAME, lang)
}
