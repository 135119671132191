import Dropzone from 'react-dropzone'
import Component from '../../utils/Component'
import { StateConsumer } from '../../context/StateProvider'
import { getS3Url, resizeImage } from '../../utils/tool'

import UiIcon from '../../components/ui/Icon'
import UiButton from '../../components/ui/Button'
import UiAlert from '../../components/ui/layer/Alert'

import './Images.scss'

class Images extends Component {
  state = {
    thumbnails: [],
    error: null,
    processing: []
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  addThumbnail = (index, thumbnail) => {
    const {
      thumbnails
    } = this.state

    thumbnails[index] = thumbnail
    this.setState({ thumbnails })
  }

  removeImage = (index) => {
    const {
      thumbnails
    } = this.state

    thumbnails.splice(index, 1)
    this.setState({
      thumbnails: [ ...thumbnails ]
    })

    if (this.props.onRemoveFile) {
      this.props.onRemoveFile(index)
    }
  }

  openFiles = () => {

  }

  onDrop = (files, change = true) => {
    const {
      thumbnails
    } = this.state

    const {
      t,
      resizeSize,
      imageType,
      imageQuality
    } = this.props

    const setFile = (index, file) => {
      return new Promise(resolve => {
        const reader = new FileReader()

        const setError = () => {
          this.setState({ error: t('community.error.image') })
          resolve()
        }
        reader.onabort = () => setError()
        reader.onerror = () => setError()
        reader.onload = () => {
          resizeImage(file, reader.result, { resizeSize, imageType, imageQuality })
            .then(({ thumbnailUrl, resizedFile, originalFile }) => {
              this.addThumbnail(index, thumbnailUrl)
              if (change) {
                this.imageOnChange(originalFile, resizedFile)
              }
              resolve()
            }).catch(err => {
              console.error(err)
              setError()
            })
        }
        reader.readAsDataURL(file)
      })
    }

    const promises = []
    const originalIndex = thumbnails.length

    for (const [index, file] of files.entries()) {
      if (!file || (file.type && file.type.substr(0, 6) !== 'image/')) {
        this.setState({ error: t('community.error.imageOnly') })
      } else {
        this.addThumbnail(index + originalIndex, null)

        if (typeof file !== 'string') {
          promises.push(() => setFile(index + originalIndex, file))
        } else {
          setTimeout(() => {
            this.addThumbnail(index + originalIndex, file)
          }, 0)
        }
      }
    }

    if (promises.length) {
      promises.reduce((chain, promise) => chain.then(() => promise()), Promise.resolve())
    }
  }

  imageOnChange = (file, thumbnail) => {
    if (this.props.onAddFile) {
      this.props.onAddFile(file, thumbnail)
    }
  }

  componentDidMount () {
    const {
      value
    } = this.props

    if (value) {
      this.onDrop(value, false)
    }
  }

  componentDidUpdate () {
    const {
      initialFiles
    } = this.props

    if (JSON.stringify(initialFiles) !== JSON.stringify(this.state.processing)) {
      this.setState({
        processing: initialFiles
      })
      this.onDrop(initialFiles.map(file => getS3Url(file.thumbKey)), false)
    }
  }

  render() {
    const {
      thumbnails,
      error
    } = this.state

    const {
      width,
      height,
      placeholder
    } = this.props

    let className = `ui-images`
    let thumbnailStyle = {
      width,
      height
    }

    return (
      <div className={className}>
        <div className="ui-image-dropzone">
          <Dropzone noClick
                    onDrop={acceptedFiles => this.onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps, open }) => {
              return (
                <div className="dropzone-wrap"
                     { ...getRootProps() }>
                  <input { ...getInputProps() } />
                  <div className="layout-container">
                    <div className="thumbnails">
                      {thumbnails.map((thumb, i) => {
                        return (
                          <div className="thumbnail"
                               key={`thumbnail-${i}`}
                               style={{
                                 ...thumbnailStyle,
                                 backgroundImage: `url(${thumb})`
                               }}>
                            {!!thumb &&
                              <button className="file-remove"
                                      onClick={() => { this.removeImage(i) }}>
                                &times;
                              </button>
                            }
                            {!thumb &&
                              <div className="file-loader">
                                <UiIcon name="loader" />
                              </div>
                            }
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  {placeholder}

                  <UiButton color="white"
                            isFloating={true}
                            text={<UiIcon name="gallery"
                                          onClick={open} />} />
                </div>
              )
            }}
          </Dropzone>

          {!!error &&
            <UiAlert content={error}
                     onOkay={() => { this.setState({ error: null }) }} />
          }
        </div>
      </div>
    )
  }
}

Images.defaultProps = {
  value: [],
  initialFiles: [],
  width: '110px',
  height: '110px',
  resizeSize: { width: null, height: null },
  imageType: 'image/jpeg',
  imageQuality: 0.7,
  placeholder: '',
  onAddFile: null,
  onClick: null
}

const StateContainer = (props) => StateConsumer(Images, props)
export default StateContainer