import { Link, withRouter } from 'react-router-dom'
import Component from '../../utils/Component'
import { StateConsumer } from '../../context/StateProvider'

import { signIn } from '../../api/auth'
import { isUserId, isPassword, isEmail } from '../../utils/tool'
import { setAuthToken } from '../../utils/auth'

import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiAlert from '../../components/ui/layer/Alert'

import './Signin.scss'

class Signin extends Component {
  state = {
    value: {
      userId: '',
      password: ''
    },
    error: {
      signIn: ''
    },
    showAlert: false
  }
  
  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
  }

  signInOnSubmit = () => {
    const {
      value
    } = this.state

    const {
      t,
      history,
      action
    } = this.props

    const v = {
      userId: value.userId.trim(),
      password: value.password.trim()
    }

    if ((!isUserId(v.userId) && !isEmail(v.userId)) || !isPassword(v.password)) {
      this.setStateInside('error', 'signIn', t('signIn.error.emailPassword'))
      this.setState({ showAlert: true })
      return
    }

    signIn(v).then(response => {
      setAuthToken(response.data.accessToken)
      action.fetchUserData(true)
      history.push('/')
    }).catch(error => {
      this.setStateInside('error', 'signIn',
        error.message === '"User Not Found"' ? t('signIn.error.emailPassword') : t('error.unknown'))
      this.setState({ showAlert: true })
    })
  }

  alertOnClick = () => {
    this.setStateInside('error', 'signIn', null)
    this.setState({
      value: { userId: '', password: '' },
      showAlert: false
    })
  }

  isSignInDisabled = () => {
    const {
      userId,
      password
    } = this.state.value

    return !userId.length > 0 || !password.length > 0
  }

  componentDidMount () {
    const {
      action
    } = this.props

    action.setLayoutShowFooter(false)
  }

  render() {
    const {
      value,
      error,
      showAlert
    } = this.state

    const {
      t
    } = this.props

    return(
      <div className="signin">
        <div className="signin-header">
          <UiIcon name="back"
                  onClick={() => { window.history.back() }} />
        </div>

        <div className="signin-wrap">
          <h1 className="signin-logo">
              <UiIcon name="logo-blue"
                      alt="Nearcle" />
          </h1>

          <UiInput type="email"
                   placeholder={t('signIn.placeholder.email')}
                   value={value.userId}
                   error={error.userId}
                   onKeyUp={key => { if (key === 'Enter') this.signInOnSubmit() } }
                   onChange={value => this.inputOnChange("userId", value)} />
          <UiInput type="password"
                   placeholder={t('signIn.placeholder.password')}
                   value={value.password}
                   error={error.password}
                   onKeyUp={key => { if (key === 'Enter') this.signInOnSubmit() } }
                   onChange={value => this.inputOnChange("password", value)} />

          <div className="signin-buttons">
            <UiButton text={t('signIn.login')}
                      onClick={this.signInOnSubmit}
                      isDisabled={this.isSignInDisabled()} />

            <Link className="signup"
                  to="/signup" >{t('signIn.signUp')}</Link>
          </div>
        </div>

        <Link className="forgot-password"
              to="/sign/forgot">{t('signIn.forgotPassword')}</Link>

        {showAlert &&
          <UiAlert content={error.signIn} onOkay={this.alertOnClick} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(Signin, props)
export default withRouter(StateContainer)
