import React from 'react';
import Component from '../../utils/Component';

import UiCheckItem from '../../components/ui/CheckItem';

import './CheckSelect.scss'

class CheckSelect extends Component {
  state = {
    value: Array.isArray(this.props.value) ?
      this.props.value :
      [this.props.value]
  };

  onClick = (value) => {
    const {
      onClick
    } = this.props
    if (onClick) {
      onClick(value)
    }
  };

  onChange = (value) => {
    const {
      onChange
    } = this.props
    if (onChange) {
      onChange(value)
    }
  };

  componentDidUpdate() {
    const {
      value
    } = this.props;

    if (JSON.stringify(value) !== JSON.stringify(this.state.value)) {
      this.setState({
        value: Array.isArray(value) ? value : [value]
      });
    }
  }

  render() {
    const {
      label,
      thisValues,
      titles,
      value
    } = this.props;

    const itemWidth = `calc(${100 / thisValues.length}% - 17px)`;

    return (
      <div className="check-select">
        {label &&
          <label className="check-select-label">
            {label}
          </label>
        }

        <div className="check-select-items">
          {thisValues.map((thisValue, i) => {
            return (
              <div className="check-select-item"
                   style={{ width: itemWidth }}
                   key={`check-select-items-${thisValue}-${titles[i]}-${i}`}>
                <UiCheckItem thisValue={thisValue}
                             value={value}
                             title={titles[i]}
                             onClick={value => { this.onClick(value) }}
                             onChange={value => { this.onChange(value) }} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

CheckSelect.defaultProps = {
  label: null,
  thisValues: [],
  value: [],
  titles: [],
  onChange: null,
  onClick: null
};

export default CheckSelect;
