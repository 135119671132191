import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLang } from '../utils/lang'

// import i18nAr from '../assets/data/i18n/ar.json'
import i18nZhCN from '../assets/data/i18n/zh-CN.json'
import i18nEnUS from '../assets/data/i18n/en-US.json'
// import i18nHiIN from '../assets/data/i18n/hi-IN.json'
// import i18nIdID from '../assets/data/i18n/id-ID.json'
import i18nJaJP from '../assets/data/i18n/ja-JP.json'
import i18nKoKR from '../assets/data/i18n/ko-KR.json'
// import i18nPlPL from '../assets/data/i18n/pl-PL.json'
// import i18nPtPT from '../assets/data/i18n/pt-PT.json'
// import i18nRuRU from '../assets/data/i18n/ru-RU.json'
// import i18nEsES from '../assets/data/i18n/es-ES.json'
// import i18nThTH from '../assets/data/i18n/th-TH.json'
// import i18nTrTR from '../assets/data/i18n/tr-TR.json'
// import i18nViVN from '../assets/data/i18n/vi-VN.json'

const resource = {
  // 'ar': {
  //   translation: i18nAr
  // },
  'zh-CN': {
    translation: i18nZhCN
  },
  'en-US': {
    translation: i18nEnUS
  },
  // 'hi-IN': {
  //   translation: i18nHiIN
  // },
  // 'id-ID': {
  //   translation: i18nIdID
  // },
  'ja-JP': {
    translation: i18nJaJP
  },
  'ko-KR': {
    translation: i18nKoKR
  },
  // 'pl-PL': {
  //   translation: i18nPlPL
  // },
  // 'pt-PT': {
  //   translation: i18nPtPT
  // },
  // 'ru-RU': {
  //   translation: i18nRuRU
  // },
  // 'es-ES': {
  //   translation: i18nEsES
  // },
  // 'th-TH': {
  //   translation: i18nThTH
  // },
  // 'tr-TR': {
  //   translation: i18nTrTR
  // },
  // 'vi-VN': {
  //   translation: i18nViVN
  // }
}

i18n.use(initReactI18next).init({
  resources: resource,
  lng: getLang(),
  fallbackLng: 'en-US',

  debug: false,
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  }
})

export default i18n