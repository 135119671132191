import React from 'react';
import Component from '../../utils/Component';

import UiIcon from '../../components/ui/Icon';

import './Check.scss'

class UiCheck extends Component {
  state = {
    value: Array.isArray(this.props.value) ?
      this.props.value :
      [this.props.value],
    thisValue: this.props.thisValue
  };

  inputOnClick = () => {
    const {
      onChange,
      onClick
    } = this.props;

    const {
      value,
      thisValue
    } = this.state;

    const originalValue = JSON.stringify(value)

    if (value.includes(thisValue)) {
      let i = 0;
      while (i < value.length) {
        if (value[i] === thisValue) {
          value.splice(i, 1);
        } else {
          ++i;
        }
      }
    } else {
      value.push(thisValue);
    }

    this.setState({
      value
    });

    if (JSON.stringify(value) !== originalValue) {
      if (onChange) {
        onChange(value);
      }
    }

    if (onClick) {
      onClick(thisValue);
    }
  };

  componentDidUpdate() {
    const {
      value,
      thisValue
    } = this.props;

    if (thisValue !== this.state.thisValue) {
      this.setState({
        thisValue
      });
    }
    if (JSON.stringify(value) !== JSON.stringify(this.state.value)) {
      this.setState({
        value: Array.isArray(value) ? value : [value]
      });
    }
  }

  render() {
    const {
      type
    } = this.props;

    let className = `ui-check`;

    let checked = this.state.value.includes(this.state.thisValue);

    return (
      <div className={className}>
        <label>
          <input className="check-element a11y-invisible"
                 type="checkbox"
                 checked={checked}
                 onChange={this.inputOnClick} />
          {type !== 'switch' &&
            <span className="style-box">
              <span className="check-icon-wrap unchecked">
                <UiIcon name="check-circle-gray"/>
              </span>
              <span className="check-icon-wrap checked">
                <UiIcon name="check-circle-blue"/>
              </span>
            </span>
          }
          {type === 'switch' &&
            <span className="style-switch">
              <span className="switch-bullet"></span>
            </span>
          }
        </label>
      </div>
    );
  }
}

UiCheck.defaultProps = {
  type: 'box',
  thisValue: '',
  value: [],
  onChange: null,
  onClick: null
};

export default UiCheck;
