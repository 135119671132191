import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import PullToRefresh from 'pulltorefreshjs'

import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { timeString, addListener, removeListener, getS3Url, historyPushState, bodyScroll } from '../../utils/tool'

import { boardInfo, boardListBookmark, boardPostList, boardPostInfo } from '../../api/community'

import BoardPostCreate from '../../pages/community/BoardPostCreate'
import BoardPostInfo from '../../pages/community/BoardPostInfo'

import UiIcon from '../../components/ui/Icon'
import UiCountry from '../../components/ui/Country'
import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiContext from '../../components/ui/Context'

import './BoardPostList.scss'

class CommunityBoardPostList extends Component {
  state = {
    loading: false,
    board: null,
    post: null,
    showContext: false,
    showSearch: 0,
    showWrite: 0,
    showRead: 0,
    error: {
      title: null,
      content: null,
      files: null
    },
    list: [],
    searchList: [],
    keyword: '',
    listOption: {},
    searchListOption: {},
    firstDate: null,
    initialListOption: {
      lastDate: null,
      page: 0,
      count: 10
    },
    noMoreList: false,
    noMoreSearchList: false
  }

  keywordOnChange = (keyword) => {
    const { keywordTimer } = this.state
    clearTimeout(keywordTimer)
    this.setState({
      loading: true,
      keyword,
      keywordTimer: setTimeout(() => {
        this.onSearch()
      }, 1500)
    })
  }

  onSearch = () => {
    const {
      board,
      keyword,
      initialListOption
    } = this.state

    if (keyword.trim()) {
      this.setState({
        searchListOption: initialListOption,
        noMoreSearchList: false
      })

      removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)

      boardPostList({
        board: board.id,
        keyword,
        ...initialListOption
      }).then(result => {
        const list = result.data.list
        this.setState({
          loading: false,
          searchList: list,
          searchListOption: {
            ...initialListOption,
            lastDate: list.length ? list[list.length - 1].createdAt : false
          },
          noMoreSearchList: list.length < initialListOption.count
        })

        addListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)
      }).catch(error => {
        console.error(error)
      })
    } else {
      this.setState({
        loading: false,
        searchList: []
      })
    }
  }

  loadNextSearchPage = (loading = true) => {
    const {
      board,
      keyword,
      searchList,
      searchListOption
    } = this.state

    const newListOption = {
      ...searchListOption,
      page: searchListOption.page + 1
    }

    this.setState({
      searchListOption: newListOption
    })

    if (loading) {
      this.setState({ loading: true })
    }
    boardPostList({
      board: board.id,
      keyword,
      ...newListOption
    }).then(result => {
      const nextList = result.data.list
      const newList = searchList.concat(nextList)
      this.setState({
        loading: false,
        searchList: newList,
        searchListOption: {
          ...newListOption,
          lastDate: newList.length ? newList[newList.length - 1].createdAt : false
        },
        noMoreSearchList: nextList.length < searchListOption.count
      })
    }).catch(error => {
      console.error(error)
    })
  }

  onClickPost = (post) => {
    if (!post.reported) {
      this.openRead(post)
    }
  }

  openSearch = () => {
    this.setState({
      showSearch: 1,
      searchList: [],
      keyword: ''
    })

    this.setSearchTitle()

    bodyScroll(true)
  }

  closeSearch = () => {
    this.setInitialTitle(true)
    this.setState({
      showSearch: -1
    })

    bodyScroll(false)
  }

  openContext = () => {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.setState({
        showContext: true
      })

      bodyScroll(true)
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  closeContext = () => {
    this.setState({
      showContext: false
    })

    bodyScroll(false)
  }

  onToggleBookmark = () => {
    const {
      board
    } = this.state

    this.setState({
      showContext: false
    })

    boardListBookmark({
      board: board.id
    }).then(() => {
      board.bookmark = !board.bookmark
    }).catch(error => {
      console.error(error)
    })
  }

  openWrite = () => {
    const {
      t,
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.setState({
        showWrite: 1,
        showContext: false
      })

      action.setNavbarTitle(t('community.write'))
      action.setNavbarBackbutton(
        <UiIcon name="back"
                onClick={() => { this.closeWrite() }} />
      )
      action.setNavbarButtons(null)

      this.destroyPullToRefresh()

      bodyScroll(true)
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  closeWrite = (post) => {
    const {
      showSearch
    } = this.state

    if (post) {
      this.openRead(post)
      this.loadLatestPage()
    } else {
      if (showSearch > 0) {
        this.setSearchTitle(true)
      } else {
        this.setInitialTitle(true)
      }
      this.initPullToRefresh()
    }

    this.setState({
      showWrite: -1
    })

    bodyScroll(false)
  }

  openRead = (post, back) => {
    const {
      action,
      location
    } = this.props

    this.destroyPullToRefresh()

    this.setState({
      post: null,
      loading: true
    })

    setTimeout(() => {
      if (back) {
        action.setNavbarBackbutton(
          <UiIcon name="back"
                  onClick={() => { back() }} />
        )
      } else {
        action.setNavbarBackbutton(
          <UiIcon name="back"
                  onClick={() => { this.closeRead() }} />
        )
      }
    }, 0)

    return boardPostInfo({
      id: post.id
    }).then(response => {
      this.setState({
        post: response.data,
        loading: false,
        showRead: 1
      })

      if (!location.state || !location.state.post) {
        historyPushState(window.location.pathname + '?p=' + post.id, { post: post.id })
      }
      action.setNavbarTitle('')

      bodyScroll(true)
    }).catch(error => {
      console.error(error)
    })
  }

  closeRead = () => {
    const {
      list,
      searchList,
      showSearch,
      noMoreList,
      noMoreSearchList
    } = this.state

    this.initPullToRefresh()

    if (showSearch) {
      if (searchList.length < 1 && !noMoreSearchList) {
        this.loadList()
      }
      this.setSearchTitle(true)
    } else {
      if (list.length < 1 && !noMoreList) {
        this.loadList()
      }
      this.setInitialTitle(true)
    }
    this.setState({
      showRead: -1
    })

    bodyScroll(false)
  }

  detectPost = () => {
    const {
      showRead
    } = this.state

    const params = new URLSearchParams(window.location.search)
    if (params) {
      const postId = params.get('p')
      if (postId) {
        if (showRead <= 0) {
          this.openRead({ id: postId })
        }
        return true
      } else {
        if (showRead > 0) {
          this.closeRead()
        }
        return false
      }
    }
  }

  updatePostLike = (postMatch, liked, countLike) => {
    const {
      list
    } = this.state

    for (let i = 0; i < list.length; i ++) {
      const post = list[i]
      if (post.id === postMatch.id) {
        post.liked = liked
        post.meta.countLike = countLike
        break
      }
    }
  }

  updatePostComment = (postMatch, commented, countComment) => {
    const {
      list
    } = this.state

    for (let i = 0; i < list.length; i ++) {
      const post = list[i]
      if (post.id === postMatch.id) {
        post.commented = commented
        post.meta.countComment = countComment
        break
      }
    }
  }

  updatePostInfo = (post) => {
    this.openRead(post)
    this.updatePostList()
  }

  updatePostList = () => {
    const {
      showSearch
    } = this.state

    this.closeRead()

    if (showSearch) {
      this.onSearch()
    } else {
      this.loadList()
    }
  }

  setSearchTitle = (backward = false) => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('common.search'), backward)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.closeSearch() }} />
    )
    action.setNavbarButtons(null)
    action.setLayoutShowFooter(true)
  }

  setInitialTitle = () => {
    const {
      board
    } = this.state

    const {
      action,
      history
    } = this.props

    action.setNavbarTitle(board.title)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/c/b'); }} />
    )

    action.setNavbarButtons(
      <>
        <UiIcon name="search"
                onClick={() => { this.openSearch() }} />
        <UiIcon name="hamburger"
                onClick={() => { this.openContext() }} />
      </>
    )
    action.setLayoutShowFooter(true)
  }

  loadList = (loading = true) => {
    const {
      board,
      initialListOption
    } = this.state

    this.setState({
      listOption: initialListOption,
      noMoreList: false
    })

    if (loading) {
      this.setState({ loading: true })
    }

    removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    boardPostList({
      board: board.id,
      ...initialListOption
    }).then(result => {
      const list = result.data.list
      this.setState({
        loading: false,
        list,
        listOption: {
          ...initialListOption,
          lastDate: list.length ? list[list.length - 1].createdAt : false
        },
        firstDate: list.length ? list[0].createdAt : false,
        noMoreList: list.length < initialListOption.count
      })

      addListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)

      this.destroyPullToRefresh()
      this.initPullToRefresh()
    }).catch(error => {
      console.error(error)
    })
  }

  initPullToRefresh () {
    const {
      t
    } = this.props

    PullToRefresh.init({
      mainElement:'#boardPostListContainer',
      onRefresh: this.loadLatestPage,
      iconRefreshing: ' ',
      instructionsPullToRefresh: t('common.pullToRefresh'),
      instructionsReleaseToRefresh: t('common.releaseToRefresh'),
      instructionsRefreshing: ' ',
      refreshTimeout: 100,
      shouldPullToRefresh: () => !document.getElementById('defaultLayout').scrollTop
    })
  }

  destroyPullToRefresh () {
    PullToRefresh.destroyAll()
  }

  loadLatestPage = (loading = true) => {
    const {
      board,
      list,
      firstDate
    } = this.state

    if (loading) {
      this.setState({ loading: true })
    }
    boardPostList({
      board: board.id,
      firstDate
    }).then(result => {
      const latestList = result.data.list
      const newList = latestList.concat(list)
      this.setState({
        loading: false,
        list: newList,
        firstDate: newList.length ? newList[0].createdAt : false
      })
    }).catch(error => {
      console.error(error)
    })
  }

  loadNextPage = (loading = true) => {
    const {
      board,
      list,
      listOption
    } = this.state

    const newListOption = {
      ...listOption,
      page: listOption.page + 1
    }

    this.setState({
      listOption: newListOption
    })

    if (loading) {
      this.setState({ loading: true })
    }
    boardPostList({
      board: board.id,
      ...newListOption
    }).then(result => {
      const nextList = result.data.list
      const newList = list.concat(nextList)
      this.setState({
        loading: false,
        list: newList,
        listOption: {
          ...newListOption,
          lastDate: newList.length ? newList[newList.length - 1].createdAt : false
        },
        firstDate: newList.length ? newList[0].createdAt : false,
        noMoreList: nextList.length < listOption.count
      })
    }).catch(error => {
      console.error(error)
    })
  }

  trackListScrolling = () => {
    const {
      loading,
      noMoreList
    } = this.state
    if (noMoreList) {
      removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    }
    const el = document.getElementById('boardList');
    if (!noMoreList && !loading) {
      if (el.getBoundingClientRect().bottom < window.innerHeight + 500) {
        this.setState({ loading: true })
        this.loadNextPage()
      }
    }
  }

  trackSearchListScrolling = () => {
    const {
      loading,
      noMoreSearchList
    } = this.state
    if (noMoreSearchList) {
      document.getElementById('searchContainer').removeEventListener('scroll', this.trackSearchListScrolling)
    }
    const el = document.getElementById('boardSearchList');
    if (!noMoreSearchList && !loading) {
      if (el.getBoundingClientRect().bottom < window.innerHeight + 500) {
        this.setState({ loading: true })
        this.loadNextSearchPage()
      }
    }
  }

  computedContextItems = () => {
    const {
      board
    } = this.state

    const {
      t
    } = this.props

    return [{
      text: t('community.write'),
      action: () => { this.openWrite() }
    }, {
      text: (!!board && !!board.bookmark) ? t('community.favorite.remove') : t('community.favorite.add'),
      action: () => { this.onToggleBookmark() }
    }]
  }

  componentDidMount () {
    const {
      action,
      history,
      location,
      match
    } = this.props

    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/c/b') }} />
    )
    boardInfo({
      id: match.params.id
    }).then(response => {
      this.setState({
        board: response.data
      })

      if (!this.detectPost()) {
        if (location.state && location.state.post) {
          this.openRead({ id: location.state.post }, () => { window.history.back() })
        } else {
          this.setInitialTitle()
          this.loadList()
        }
      }
    }).catch(error => console.error(error))
    action.setLayoutShowHeader(true)

    window.addEventListener('popstate', this.detectPost)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectPost)
    removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)
    this.destroyPullToRefresh()
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefNoData = createRef(null)
    const nodeRefList = createRef(null)
    const nodeRefWrite = createRef(null)
    const nodeRefRead = createRef(null)
    const nodeRefSearchList = createRef(null)
    const nodeRefSearchListLoading = createRef(null)
    const nodeRefSearch = createRef(null)

    const {
      loading,
      board,
      post,
      showWrite,
      showRead,
      showSearch,
      showContext,
      list,
      searchList,
      keyword,
      firstDate
    } = this.state

    const {
      t
    } = this.props

    const {
      userData
    } = this.props.state

    const postItem = (post, i) => {
      const blocked = userData ? (userData.blockList && userData.blockList.includes(post.author.id)) : false

      return (
        <li key={`list-button-${i}`}>
          {blocked &&
            <button>
              <span className="author blocked">
                {t('community.blocked')}
              </span>
              <span className="meta">
                <span className="date">
                  {timeString(post.createdAt, t)}
                </span>
              </span>
            </button>
          }
          {!blocked &&
            <button onClick={() => { this.onClickPost(post) }}>
              {post.author &&
                <span className="author">
                  <UiCountry code={post.country} />
                  {post.author.nickname}
                </span>
              }
              {!post.reported &&
                <>
                  <span className={`content ${post.thumbnail ? 'thumb' : ''}`}>
                    <span className="title">
                      {post.title}
                    </span>
                    <span className="summary">
                      {post.summary}
                    </span>
                  </span>
                  {post.thumbnail &&
                    <span className="thumbnail"
                          style={{ backgroundImage: `url('${getS3Url(post.thumbnail)}')` }}> </span>
                  }
                </>
              }
              {post.reported &&
                <span className="content reported">
                  <span className="summary">
                    {t('community.reported.post')}
                  </span>
                </span>
              }
              <span className="meta">
                {!post.reported &&
                  <span className="count">
                    <span className="like">
                      <UiIcon name={`post-like ${post.liked ? 'active' : ''}`} />
                      {post.meta.countLike}
                    </span>
                    <span className="comment">
                      <UiIcon name={`post-comment ${post.commented ? 'active' : ''}`} />
                      {post.meta.countComment}
                    </span>
                  </span>
                }
                <span className="date">
                  {timeString(post.createdAt, t)}
                </span>
              </span>
            </button>
          }
        </li>
      )
    }

    return(
      <div className="layout-container">
        <div className="community-board-post-list">
          <div id="boardPostListContainer">
            <CSSTransition in={loading}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefLoader}
                           classNames="fade">
              <div className="data-loader"
                   ref={nodeRefLoader}>
                <UiIcon name="loader" />
              </div>
            </CSSTransition>

            <CSSTransition in={firstDate === false}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefNoData}
                           classNames="fade">
              <div className="no-data"
                   ref={nodeRefNoData}>
                <ul className="board-list">
                  <li className="no-data">
                    {t('community.postFirst')}
                  </li>
                </ul>
                <div className="data-loader">
                  <UiIcon name="loader-logo" />
                </div>
              </div>
            </CSSTransition>

            <CSSTransition in={list.length > 0}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefList}
                           classNames="fade">
              <ul className="board-list"
                  id="boardList"
                  ref={nodeRefList}>
                {list.map((post, i) => {
                  return postItem(post, i)
                })}
              </ul>
            </CSSTransition>

            <CSSTransition in={showSearch > 0}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefSearch}
                           classNames="page-slide">
              <div className={`search-container ${showSearch < 0 ? 'backward' : ''}`}
                   id="searchContainer"
                   ref={nodeRefSearch}>
                <div className="layout-container">
                  <div className="search">
                    <UiInput type="text"
                             color="fill"
                             placeholder={t('common.search')}
                             icon="search"
                             value={keyword}
                             onChange={value => this.keywordOnChange(value)} />
                  </div>
                  <CSSTransition in={searchList.length > 0}
                                 timeout={500}
                                 mountOnEnter={true}
                                 unmountOnExit={true}
                                 nodeRef={nodeRefSearchList}
                                 classNames="fade">
                    <ul className="board-list"
                        id="boardSearchList"
                        ref={nodeRefSearchList}>
                      {searchList.map((post, i) => {
                        return postItem(post, i)
                      })}
                    </ul>
                  </CSSTransition>
                  <CSSTransition in={searchList.length < 1}
                                 timeout={500}
                                 mountOnEnter={true}
                                 unmountOnExit={true}
                                 nodeRef={nodeRefSearchListLoading}
                                 classNames="fade">
                    <ul className="board-list"
                        ref={nodeRefSearchListLoading}>
                      <li className="data-loader">
                        <UiIcon name="loader-logo" />
                      </li>
                    </ul>
                  </CSSTransition>
                </div>
              </div>
            </CSSTransition>
          </div>

          <UiButton color="blue"
                    isFloating={true}
                    text={<UiIcon name="write white"
                                  onClick={() => { this.openWrite() }} />} />

          <CSSTransition in={showWrite > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefWrite}
                         classNames="page-slide">
            <div className={`post-create-wrap ${showWrite < 0 ? 'backward' : ''}`}
                 ref={nodeRefWrite}>
              <BoardPostCreate board={board}
                               afterPost={(post) => { this.closeWrite(post) }} />
            </div>
          </CSSTransition>

          <CSSTransition in={showRead > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefRead}
                         classNames="page-slide">
            <div className={`post-read-wrap ${showRead < 0 ? 'backward' : ''}`}
                 ref={nodeRefRead}>
              {board && post &&
                <BoardPostInfo board={board}
                               post={post}
                               afterLike={this.updatePostLike}
                               afterComment={this.updatePostComment}
                               afterEdit={() => { this.updatePostInfo(post) }}
                               afterDelete={this.updatePostList} />
              }
            </div>
          </CSSTransition>

          <UiContext show={showContext}
                     items={this.computedContextItems()}
                     onTryClose={() => { this.closeContext() }} />
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(CommunityBoardPostList, props)
export default withRouter(StateContainer)
