import React from 'react';
import Component from '../../utils/Component';
import { StateConsumer } from '../../context/StateProvider';

import './Button.scss'

class UiButton extends Component {
  onClick = () => {
    const {
      isDisabled,
      onClick
    } = this.props;

    if (!isDisabled && onClick) {
      onClick();
    }
  };

  render() {
    const {
      color,
      isSmall,
      isFull,
      isFixed,
      isFloating,
      isDisabled,
      text,
      title,
      width,
      height,
      state
    } = this.props;

    let className = `ui-button`
    if (color) {
      className += ` ${color}`
    }
    if (isSmall) {
      className += ` small`
    }
    if (isFull) {
      className += ` full`
    }
    if (isFixed) {
      className += ` fixed`

      if (!isFull) {
        className += ` layout-container`
      }
    } else if (isFloating) {
      className += ` floating layout-container`
    }

    let style = {}
    if (width) {
      style.width = width
    }
    if (height) {
      style.height = height
    }
    if (isFixed) {
      style.bottom = `${state.layoutBottom}px`
    } else if (isFloating) {
      style.bottom = `${state.layoutBottom + 60}px`
    }

    return (
      <div className={className}
           style={style}>
        <button title={title}
                disabled={isDisabled}
                onClick={this.onClick}>
          {text}
        </button>
      </div>
    );
  }
}

UiButton.defaultProps = {
  color: 'blue',
  isSmall: false,
  isFull: false,
  isFixed: false,
  isDisabled: false,
  isFloating: false,
  text: '',
  title: null,
  onClick: null,
  width: null,
  height: null
};

const StateContainer = (props) => StateConsumer(UiButton, props);
export default StateContainer;
