import httpClient from '../utils/http'

export function placeList (params) {
  const url = '/p/p/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function placeInfo (params) {
  const url = '/p/p/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function placeBookmark (params) {
  const url = '/p/p/b'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
