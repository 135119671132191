import React from 'react'
import Component from '../../utils/Component'

import './Highlight.scss'

class UiHighlight extends Component {
  render() {
    const {
      content,
      keyword
    } = this.props

    const text = keyword ? content.replace(keyword, `|@|:|:${keyword}|@|`) : content

    return (
      <span className="ui-highlight">
        {text.split('|@|').map(t => {
          if (t.includes(':|:')) {
            return (<mark>{t.replace(':|:', '')}</mark>)
          } else {
            return t
          }
        })}
      </span>
    )
  }
}

UiHighlight.defaultProps = {
  content: '',
  keyword: ''
}

export default UiHighlight
