import React, { Component, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { userInfo } from '../api/user'
import { removeAuthToken } from '../utils/auth'
import { setLang } from '../utils/lang'
import { getS3Url } from '../utils/tool'

import { talkSocket } from '../api/talk'

const StateContext = createContext()
const { Provider, Consumer } = StateContext

class StateProvider extends Component {
  state = {
    userData: null,
    userDataUpdatedAt: null,

    ws: null,

    layoutShowHeader: false,
    layoutShowFooter: false,
    layoutBottom: 0,
    layoutShowLogin: false,

    navbarTitle: null,
    navbarButtons: null,
    navbarBackButton: null,

    isMovingBack: false,

    tabbarButtons: [],

    notificationCount: 0
  }

  action = {
    fetchUserData: (force = false) => {
      const {
        userDataUpdatedAt
      } = this.state

      const now = Date.now()
      const refreshIntervalInMinute = 10

      if (force || !userDataUpdatedAt || now - userDataUpdatedAt > refreshIntervalInMinute * 60000) {
        this.setState({ userDataUpdatedAt: now })
        return userInfo().then(result => {
          if (result.success) {
            this.action.setUserData(result.data.user)
          } else {
            this.action.setUserData(null)
          }
        }).catch(() => {
          this.action.setUserData(null)
        })
      }
      return Promise.resolve()
    },

    setUserData: value => {
      if (!value) {
        removeAuthToken()
        if (window.nearcleAndroid) {
          window.nearcleAndroid.setFcmToken(null)
        }
        if (window.webkit && window.webkit.messageHandlers.nearcleIos) {
          window.webkit.messageHandlers.nearcleIos.postMessage(JSON.stringify({
            action: 'setFcmToken',
            data: null
          }))
        }
      } else {
        value = {
          ...this.state.userData,
          ...value,
          profileImage: getS3Url(value.profileImage),
          profileBackground: getS3Url(value.profileBackground)
        }
        if (value.lang) {
          setLang(value.lang)
        }
        if (window.nearcleAndroid) {
          window.nearcleAndroid.setFcmToken(value.id)
        }
        if (window.webkit && window.webkit.messageHandlers.nearcleIos) {
          window.webkit.messageHandlers.nearcleIos.postMessage(JSON.stringify({
            action: 'setFcmToken',
            data: value.id
          }))
        }
      }
      if (JSON.stringify(this.state.userData) !== JSON.stringify(value)) {
        this.setState({ userData: value })
        this.action.setWebSocket()
      }
    },

    setWebSocket: () => {
      const {
        userData
      } = this.state

      const query = {
        user: null
      }
      if (userData) {
        query.user = userData.id
      }
      this.setState({ ws: talkSocket(query) })
    },

    setLayoutShowHeader: value => {
      if (this.state.layoutShowHeader !== value) {
        this.setState({ layoutShowHeader: value })
      }
    },
    setLayoutShowFooter: value => {
      if (this.state.layoutShowFooter !== value) {
        this.setState({ layoutShowFooter: value })
      }
    },

    setLayoutBottom: value => {
      if (this.state.layoutBottom !== value) {
        this.setState({ layoutBottom: value })
      }
    },

    setNavbarTitle: (value, backward = false) => {
      if (this.state.navbarTitle !== value) {
        setTimeout(() => {
          this.setState({ navbarTitle: value })
        }, 0)
        if (backward) {
          this.setState({ isMovingBack: true })
        }
        setTimeout(() => {
          this.setState({ isMovingBack: false })
        }, 500)
      }
    },
    setNavbarButtons: value => {
      if (this.state.navbarButtons !== value) {
        this.setState({ navbarButtons: value })
      }
    },
    setNavbarBackbutton: value => {
      if (this.state.navbarBackButton !== value) {
        this.setState({ navbarBackButton: value })
      }
    },

    setMovingBack: (history = null, path = null) => {
      this.setState({ isMovingBack: true })
      if (history && path) {
        setTimeout(() => {
          history.push({
            pathname: path,
            state: { back: true }
          })
        }, 0)
      }
      setTimeout(() => {
        this.setState({ isMovingBack: false })
      }, 500)
    },

    setTabbarButtons: value => {
      if (this.state.tabbarButtons !== value) {
        this.setState({ tabbarButtons: value })
      }
    },

    setLayoutShowLogin: value => {
      if (this.state.layoutShowLogin !== value) {
        this.setState({ layoutShowLogin: value })
      }
    },

    setNotificationCount: value => {
      if (this.state.notificationCount !== value) {
        this.setState({ notificationCount: value })
      }
    }
  }

  componentDidUpdate() {
    this.action.fetchUserData()
  }

  componentDidMount() {
    this.action.fetchUserData()
  }

  render() {
    const { state, action } = this
    const value = { state, action }

    return (
      <Provider value={value}>
        {this.props.children}
      </Provider>
    )
  }
}

const StateConsumer = (Component, props) => {
  const { t, i18n } = useTranslation()
  return (
    <Consumer>
      {
        ({state, action}) => (
          <Component {...props}
                     state={state}
                     action={action}
                     t={t}
                     i18n={i18n} />
        )
      }
    </Consumer>
  )
}

export {
  StateProvider,
  StateConsumer
}
