import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { format, parse } from 'date-fns'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import {
  getS3Url,
  isOpen,
  secString,
  copyToClipboard,
  historyPushState
} from '../../utils/tool'

import { boardPostUrlCreate } from '../../api/community'

import UiIcon from '../../components/ui/Icon'
import UiAlert from '../../components/ui/layer/Alert'
import UiWysiwygContent from '../../components/ui/WysiwygContent'
import UiEnlargeImage from '../../components/ui/EnlargeImage'

import './MapPlace.scss'

class MapPlace extends Component {
  state = {
    loading: false,
    audio: null,
    audioPaused: true,
    audioLength: 0.0000001,
    audioPosition: 0,
    audioAnimating: false,
    audioScript: true,
    initialWidth: 0,
    initialLeft: 0,
    showAlert: {
      copied: false
    },
    enlarge: null,
    shortenUrl: null
  }

  playAudio () {
    const {
      audio
    } = this.state

    if (!audio) return

    if (audio.paused) {
      audio.play()
    } else {
      audio.pause()
    }
  }

  playNext (length) {
    const {
      audio,
      audioPosition
    } = this.state

    if (!audio) return

    audio.currentTime = audioPosition + length

    this.setState({ audioAnimating: true })
    setTimeout(() => {
      this.setState({ audioAnimating: false })
    }, 300)
  }

  playPercent (length) {
    const {
      audio,
      audioPosition
    } = this.state

    if (!audio) return

    audio.currentTime = audioPosition + length
  }

  audioDragStart (e, mouse = true) {
    if (mouse) {
      const img = new Image()
      e.dataTransfer.setDragImage(img, 0, 0)
    }

    const {
      audio,
      audioLength
    } = this.state

    const initialWidth = this.nodeRefAudio['current'].offsetWidth
    const initialLeft = this.nodeRefAudio['current'].getBoundingClientRect().left

    this.setState({
      initialWidth,
      initialLeft
    })

    if (!audio) return

    const dragTick = initialLeft - (mouse ? e.clientX : e.touches[0].pageX) + 25
    const percent = Math.max(0, Math.min((initialLeft - dragTick) / initialWidth, 0.99))
    const position = audioLength * percent

    audio.currentTime = position
    audio.pause()
  }

  audioDrag (e, mouse = true) {
    if ((mouse ? e.clientX : e.touches[0].pageX) !== 0) {
      const {
        audio,
        initialWidth,
        initialLeft,
        audioLength
      } = this.state

      const dragTick = (mouse ? e.clientX : e.touches[0].pageX) - initialLeft
      const percent = Math.max(0, Math.min(dragTick / initialWidth, 0.99))
      const position = audioLength * percent

      if (!audio) return
      audio.currentTime = position
    }
  }

  audioDragEnd () {
    const {
      audio
    } = this.state

    if (!audio) return
    audio.play()
  }

  onSharePlace = () => {
    this.setState({ loading: true })
    boardPostUrlCreate({
      href: window.location.pathname + window.location.search
    }).then(response => {
      this.setState({ loading: false })
      let host = window.location.origin
      if (window.location.hostname === 'app.nearcle.app') {
        host = 'https://nearcle.app'
      }
      this.setState({ shortenUrl: host + '/s/' + response.data.url })
      this.toggleAlert('copied')
    }).catch(error => {
      this.setState({ loading: false })
      console.error(error)
    })
  }

  copyShortenUrl = () => {
    const {
      shortenUrl
    } = this.state
    copyToClipboard(shortenUrl)
    this.toggleAlert('copied')
  }

  onSetEnlarge = (image, back = true) => {
    const {
      place,
      action
    } = this.props

    const {
      enlarge
    } = this.state

    if (enlarge !== image) {
      if (image) {
        action.setLayoutShowFooter(false)
        historyPushState(window.location.pathname + '?p=' + place.id + '#i')
      } else {
        action.setLayoutShowFooter(true)
        if (back) {
          window.history.back()
        }
      }
      this.setState({ enlarge: image })
    }
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  detectHash = () => {
    const hash = window.location.hash
    if (!hash) {
      this.onSetEnlarge(null, false)
    }
  }

  componentDidMount () {
    const {
      place
    } = this.props

    if (place.audio) {
      const audio = new Audio(getS3Url(place.audio))
      audio.loop = false
      audio.volume = 1
      audio.addEventListener('durationchange', () => {
        this.setState({ audioLength: audio.duration })
      })
      audio.addEventListener('play', () => {
        this.setState({ audioPaused: false })
      })
      audio.addEventListener('pause', () => {
        this.setState({ audioPaused: true })
      })
      audio.addEventListener('ended', () => {
        audio.currentTime = 0
        this.setState({ audioPaused: true })
      })
      audio.addEventListener('timeupdate', () => {
        this.setState({ audioPosition: audio.currentTime })
      })

      window.addEventListener('popstate', this.detectHash)
      this.setState({ audio })
    }
  }

  componentWillUnmount () {
    const {
      audio
    } = this.state
    if (audio) {
      audio.pause()
      this.setState({audio: null})
    }
    window.removeEventListener('popstate', this.detectHash)
  }

  nodeRefAudio = createRef(null)

  render () {
    const nodeRefImage = createRef(null)
    const nodeRefLoader = createRef(null)

    const {
      loading,
      audio,
      audioPaused,
      audioPosition,
      audioLength,
      audioAnimating,
      audioScript,
      showAlert,
      enlarge
    } = this.state

    const {
      t,
      i18n,
      place,
      bookmarkList,
      onSetBookmark,
      onClose
    } = this.props

    const placeOpen = isOpen(place.openTime)

    return (
      <div className="map-place">
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        <div className="place-back">
          <div className="layout-container">
            <UiIcon name="back white"
                    onClick={() => { onClose() }} />
          </div>
        </div>

        {!!place.thumbnail &&
          <div className="thumbnail"
               style={{ backgroundImage: `url(${getS3Url(place.thumbnail) })`}}>
          </div>
        }

        <div className="info place-container">
          <div className="layout-container">
            <div className="title">
              <h3>{place.title}</h3>
            </div>
            <div className="meta">
              {placeOpen === 2 &&
                <div className="item">
                  <UiIcon name="time red"/><br />
                  {t('place.open.tobe')}
                </div>
              }
              {placeOpen === 1 &&
                <div className="item">
                  <UiIcon name="time"/><br />
                  {t('place.open.ing')}
                </div>
              }
              {placeOpen === 0 &&
                <div className="item">
                  <UiIcon name="time red"/><br />
                  {t('place.open.end')}
                </div>
              }
              {placeOpen === -1 &&
                <div className="item">
                  <UiIcon name="time red"/><br />
                  {t('place.open.vacation')}
                </div>
              }
              <div className="item">
                <UiIcon name="star-point" /><br />
                <span>{place.starPointShow}</span>/5
              </div>
              {!!bookmarkList &&
                <div className="item"
                     onClick={() => onSetBookmark(place)}>
                  <UiIcon name={`bookmark ${bookmarkList.includes(place.id) ? 'active' : ''}`} /><br />
                  {t('place.bookmark')}
                </div>
              }
              <div className="item"
                   onClick={() => { this.onSharePlace() } }>
                <UiIcon name="share" /><br />
                {t('common.share')}
              </div>
            </div>
            <div className="description">
              <p>
                {place.summary}
              </p>
            </div>
          </div>
        </div>

        {!!audio &&
          <div className="information place-container">
            <div className="layout-container">
              <div className="audio-bar">
                <div className={`bar ${audioAnimating ? 'animating' : ''}`}
                     draggable
                     ref={this.nodeRefAudio}
                     onDragStart={e => this.audioDragStart(e)}
                     onDrag={e => this.audioDrag(e)}
                     onClick={e => this.audioDrag(e)}
                     onDragEnd={e => this.audioDragEnd(e)}
                     onTouchStart={e => this.audioDragStart(e, false)}
                     onTouchMove={e => this.audioDrag(e, false)}
                     onTouchEnd={e => this.audioDragEnd(e, false)}>
                  <div className="past"
                       style={{ width: (audioPosition / audioLength * 100) + '%' }} />
                  <div className="current"
                       style={{ left: (audioPosition / audioLength * 100) + '%' }} />
                </div>
                <div className="audio-time">
                  <div className="current">
                    {secString(audioPosition)}
                  </div>
                  <div className="total">
                    {secString(audioLength)}
                  </div>
                </div>
              </div>
              <div className="audio-control">
                <button className="skip"
                        onClick={() => this.playNext(-5)}>
                  <UiIcon name="play-prev" /><br />
                  {t('place.sec5')}
                </button>
                <button className="play"
                        onClick={() => this.playAudio()}>
                  <UiIcon name={audioPaused ? 'play' : 'pause'} />
                </button>
                <button className="skip"
                        onClick={() => this.playNext(5)}>
                  <UiIcon name="play-next" /><br />
                  {t('place.sec5')}
                </button>
              </div>
            </div>

            {!!place.audioScript &&
              <div className={`audio-script ${audioScript ? 'show' : ''}`}>
                <div className="script-title"
                     onClick={() => this.setState({ audioScript: !audioScript })}>
                  {audioScript ? t('place.subscript.open') : t('place.subscript.close')}
                  <UiIcon name={audioScript ? 'arrow-small-bottom' : 'arrow-small-top'} />
                </div>
                <div className="script-content">
                  <div className="layout-container">
                    <div className="script-content-wrap">
                        <UiWysiwygContent nl2br={true}
                                          content={place.audioScript} />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }

        {!!place.content &&
          <div className="information place-container">
            <div className="layout-container">
              <h5 className="info-title">
                {t('place.detail.content')}
              </h5>
              <div className="info-content">
                <UiWysiwygContent content={place.content}
                                  allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li',
                                    'img', 'p', 'br', 'strong', 'i', 'em', 's', 'span']}/>
              </div>
            </div>
          </div>
        }

        {(place.files && place.files.length > 0) &&
          <div className="information place-container">
            <div className="layout-container">
              <h5 className="info-title">
                {t('place.detail.gallery')}
              </h5>
              <div className="info-content gallery">
                {place.files.map(file => (
                  <div className="gallery-item"
                       key={file.key}
                       style={{ backgroundImage: `url(${getS3Url(file.key)})` }}
                       onClick={() => { this.onSetEnlarge(file) }} />
                ))}
              </div>
            </div>
          </div>
        }

        {!!place.address &&
          <div className="information place-container">
            <div className="layout-container">
              <h5 className="info-title">
                {t('place.detail.address')}
              </h5>
              <div className="info-content">
                {place.address}
              </div>
              <button className="info-button"
                      onClick={() => this.toClipboard(place.address)}>
                <UiIcon name="copy" />
                {t('common.copy')}
              </button>
            </div>
          </div>
        }

        {!!place.openTime &&
          <div className="information place-container">
            <div className="layout-container">
              <h5 className="info-title">
                {t('place.detail.time')}
              </h5>
              <div className="info-content">
                <ul className="open-time">
                  {place.openTime.map((range) => {
                    if (!range.d || !range.s || !range.e) {
                      return
                    }
                    return (
                      <li>
                        <h6>
                          {range.d === 'su' ? t('day.sun') : ''}
                          {range.d === 'mo' ? t('day.mon') : ''}
                          {range.d === 'tu' ? t('day.tue') : ''}
                          {range.d === 'we' ? t('day.wed') : ''}
                          {range.d === 'th' ? t('day.thu') : ''}
                          {range.d === 'fr' ? t('day.fri') : ''}
                          {range.d === 'sa' ? t('day.sat') : ''}
                          {range.d === 'ho' ? t('day.holiday') : ''}
                        </h6>
                        {i18n.language === 'en-US' &&
                          <p>
                            {format(parse(range.s, 'HH:mm', new Date()), 'hh:mm')}&nbsp;
                            <span>{t('date.' + format(parse(range.s, 'HH:mm', new Date()), 'a').toLowerCase())}</span>
                            &nbsp;&nbsp;~&nbsp;&nbsp;
                            {format(parse(range.e, 'HH:mm', new Date()), 'hh:mm')}&nbsp;
                            <span>{t('date.' + format(parse(range.e, 'HH:mm', new Date()), 'a').toLowerCase())}</span>
                          </p>
                        }
                        {i18n.language !== 'en-US' &&
                          <p>
                            <span>{t('date.' + format(parse(range.s, 'HH:mm', new Date()), 'a').toLowerCase())}</span>
                            &nbsp;{format(parse(range.s, 'HH:mm', new Date()), 'hh:mm')}
                            &nbsp;&nbsp;~&nbsp;&nbsp;
                            <span>{t('date.' + format(parse(range.e, 'HH:mm', new Date()), 'a').toLowerCase())}</span>
                            &nbsp;{format(parse(range.e, 'HH:mm', new Date()), 'hh:mm')}
                          </p>
                        }
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        }

        {!!place.notice &&
          <div className="information place-container">
            <div className="layout-container">
              <h5 className="info-title">
                {t('place.detail.notice')}
              </h5>
              <div className="info-content">
                <UiWysiwygContent content={place.notice}
                                  allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li',
                                    'img', 'p', 'br', 'strong', 'i', 'em', 's', 'span']}/>
              </div>
            </div>
          </div>
        }

        {!!place.information &&
          <div className="information place-container">
            <div className="layout-container">
              <h5 className="info-title">
                {t('place.detail.information')}
              </h5>
              <div className="info-content">
                <UiWysiwygContent content={place.information}
                                  allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li',
                                    'img', 'p', 'br', 'strong', 'i', 'em', 's', 'span']}/>
              </div>
            </div>
          </div>
        }

        {!!place.tip &&
          <div className="information place-container">
            <div className="layout-container">
              <h5 className="info-title">
                {t('place.detail.tip')}
              </h5>
              <div className="info-content">
                <UiWysiwygContent content={place.tip}
                                  allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li',
                                    'img', 'p', 'br', 'strong', 'i', 'em', 's', 'span']}/>
              </div>
            </div>
          </div>
        }

        <CSSTransition in={!!enlarge}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefImage}
                       classNames="fade">
          <div className="board-post-image"
               ref={nodeRefImage}>
            <UiEnlargeImage imageKey={enlarge ? enlarge.key : null}
                            imageRef={this.imageRef}
                            imageList={place.files}
                            onNext={(file) => {this.onSetEnlarge(file) }}
                            onClose={() => { this.onSetEnlarge(null) }} />
          </div>
        </CSSTransition>

        {showAlert.copied &&
          <UiAlert content={t('community.alert.copied')}
                   onOkay={() => {
                     this.copyShortenUrl()
                   }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(MapPlace, props)
export default withRouter(StateContainer)