import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import Swipe from 'react-easy-swipe'
import { getS3Url, timeString, copyToClipboard, historyPushState } from '../../utils/tool'
import {
  boardPostInfo,
  boardPostBookmark,
  boardPostLike,
  boardPostNotification,
  boardPostDelete,
  boardPostReport,
  boardPostCommentList,
  boardPostCommentLike,
  boardPostCommentNotification,
  boardPostCommentDelete,
  boardPostCommentReport,
  boardPostUrlCreate
} from '../../api/community'
import { blockUser } from '../../api/user'

import UiIcon from '../../components/ui/Icon'
import UiCountry from '../../components/ui/Country'
import UiEnlargeImage from '../../components/ui/EnlargeImage'
import UiContext from '../../components/ui/Context'
import UiWysiwygContent from '../../components/ui/WysiwygContent'
import UiConfirm from '../../components/ui/layer/Confirm'
import UiAlert from '../../components/ui/layer/Alert'

import BoardPostCreate from '../../pages/community/BoardPostCreate'
import BoardPostCommentCreate from '../../pages/community/BoardPostCommentCreate'
import BoardPostCommentItem from '../../pages/community/BoardPostCommentItem'
import Profile from '../mypage/Profile'

import './BoardPostInfo.scss'

class CommunityBoardPostInfo extends Component {
  wrapperRef = createRef()
  thumbnailRef = createRef()
  imageRef = createRef()

  state = {
    loading: false,
    liked: false,
    commented: false,
    notified: false,
    bookmarked: false,
    originalPost: null,
    countLike: 0,
    countComment: 0,
    showContext: false,
    showAlert: {
      confirmDelete: false,
      confirmDeleteComment: false,
      confirmReport: false,
      confirmReportComment: false,
      confirmBlock: false,
      shareCopied: false
    },
    showWrite: 0,
    fileIndex: 0,
    fileSwiping: 0,
    isFileSwiping: false,
    commentList: [],
    commentEditing: null,
    commentReplying: null,
    selectedComment: null,
    showCommentContext: false,
    selectedUser: null,
    showProfile: false,
    profile: null,
    enlarge: null,
    seeOriginal: false,
    shortenUrl: null
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  onSeeOriginal = () => {
    const {
      bookmarked,
      originalPost,
      seeOriginal
    } = this.state

    if (!originalPost) {
      this.loadOriginalLang().then(() => {
        this.setState({
          seeOriginal: !seeOriginal
        })
        this.setNavBar(bookmarked, !seeOriginal)
      })
    } else {
      this.setState({
        seeOriginal: !seeOriginal
      })
      this.setNavBar(bookmarked, !seeOriginal)
    }
  }

  onBookmarkPost = () => {
    const {
      bookmarked
    } = this.state

    const {
      action,
      post
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      const seeOriginal = !post.isOriginal

      this.setNavBar(!bookmarked, seeOriginal)

      this.setState({
        bookmarked: !bookmarked
      })

      boardPostBookmark({
        post: post.id
      }).catch(error => {
        this.setNavBar(bookmarked, seeOriginal)
        console.error(error)
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  onCommentPost = () => {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.onSetEnlarge(null)
      document.getElementById('commentInput').focus()
      this.wrapperRef.current.scrollTop = this.wrapperRef.current.scrollHeight
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  onLikePost = () => {
    const {
      liked,
      countLike
    } = this.state

    const {
      action,
      post,
      afterLike
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.setState({
        liked: !liked,
        countLike: liked ? countLike - 1 : countLike + 1
      })

      boardPostLike({
        post: post.id
      }).then(response => {
        this.setState({
          countLike: response.data || 0
        })
        if (afterLike) {
          afterLike(post, !liked, response.data || 0)
        }
      }).catch(error => {
        this.setState({
          liked,
          countLike
        })
        console.error(error)
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  onNotifyPost = (deleteAll = false) => {
    const {
      notified
    } = this.state

    const {
      action,
      post
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.setState({
        notified: !notified
      })

      boardPostNotification({
        post: post.id,
        deleteAll
      }).catch(error => {
        this.setState({
          notified
        })
        console.error(error)
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  onSharePost = () => {
    this.setState({ loading: true })
    boardPostUrlCreate({
      href: window.location.pathname + window.location.search
    }).then(response => {
      this.setState({ loading: false })
      let host = window.location.origin
      if (window.location.hostname === 'app.nearcle.app') {
        host = 'https://nearcle.app'
      }
      this.setState({ shortenUrl: host + '/s/' + response.data.url })
      this.toggleAlert('shareCopied')
    }).catch(error => {
      this.setState({ loading: false })
      console.error(error)
    })
  }

  copyShortenUrl = () => {
    const {
      shortenUrl
    } = this.state
    copyToClipboard(shortenUrl)
    this.toggleAlert('shareCopied')
  }

  onSaveImage = () => {
    const {
      enlarge
    } = this.state

    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      if (enlarge && enlarge.key) {
        if (window.nearcleAndroid) {
          window.nearcleAndroid.saveImage(enlarge.key)
        } else if (window.webkit && window.webkit.messageHandlers.nearcleIos) {
          window.webkit.messageHandlers.nearcleIos.postMessage(JSON.stringify({
            action: 'saveImage',
            data: enlarge.key
          }))
        } else {
          let a = document.createElement('a')
          a.style = 'display: none'
          a.href = getS3Url(enlarge.key)
          a.download = 'nearcle.png'

          document.body.appendChild(a)
          a.click()

          setTimeout(function () {
            document.body.removeChild(a)
          }, 100)
        }
      }
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  onLikeComment = (commentMatch) => {
    const {
      commentList
    } = this.state

    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      let end = false
      const updateLike = (like, updateLike = true) => {
        for (let i = 0; i < commentList.length; i++) {
          const comment = commentList[i]
          if (comment.id === commentMatch.id) {
            if (updateLike) {
              comment.liked = !commentMatch.liked
            }
            comment.meta.countLike = like
            break
          }
          for (let j = 0; j < comment.children.length; j++) {
            const child = comment.children[j]
            if (child.id === commentMatch.id) {
              if (updateLike) {
                child.liked = !commentMatch.liked
              }
              child.meta.countLike = like
              end = true
              break
            }
          }
          if (end) {
            break
          }
        }

        this.setState({ 'commentList': commentList })
      }

      const countLike = commentMatch.meta.countLike
      updateLike(commentMatch.liked ? countLike - 1 : countLike + 1)

      boardPostCommentLike({
        comment: commentMatch.id
      }).then(response => {
        updateLike(response.data, false)
      }).catch(error => {
        updateLike(countLike)
        console.error(error)
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  onNotifyComment = (deleteAll = false) => {
    const {
      selectedComment
    } = this.state

    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      boardPostCommentNotification({
        comment: selectedComment.id,
        deleteAll
      }).then(() => {
        this.loadCommentList()
        this.closeCommentContext()
      }).catch(error => {
        console.error(error)
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  onReplyingComment = (commentMatch = null) => {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.setState({
        commentReplying: commentMatch
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  openContext = () => {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      action.setLayoutShowHeader(false)

      this.setState({
        showContext: true
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  closeContext = () => {
    const {
      action
    } = this.props
    action.setLayoutShowHeader(true)

    this.setState({
      showContext: false
    })
  }

  openCommentContext = (comment) => {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      action.setLayoutShowHeader(false)

      this.setState({
        selectedComment: comment,
        showCommentContext: true
      })
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  closeCommentContext = () => {
    const {
      action
    } = this.props
    action.setLayoutShowHeader(true)

    this.setState({
      showCommentContext: false
    })
  }

  setNavBar = (bookmark, seeOriginal) => {
    const {
      action
    } = this.props

    action.setNavbarButtons(
      <>
        <UiIcon name={`translate ${seeOriginal ? 'active' : ''}`}
                onClick={this.onSeeOriginal} />
        <UiIcon name={`bookmark ${bookmark ? 'active' : ''}`}
                onClick={this.onBookmarkPost} />
        <UiIcon name="hamburger"
                onClick={this.openContext} />
      </>
    )
  }

  openWrite = () => {
    const {
      t,
      action
    } = this.props

    this.setState({
      showWrite: 1
    })

    action.setNavbarTitle(t('community.editPost'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.closeWrite() }} />
    )
    action.setNavbarButtons(null)
  }

  closeWrite = () => {
    const {
      afterEdit
    } = this.props

    afterEdit()
  }

  afterComment = (comment) => {
    this.onCancelEditingComment()
    this.loadCommentList(comment.id)
  }

  onEdit = () => {
    this.closeContext()
    this.openWrite()
  }

  onEditComment = () => {
    const {
      selectedComment
    } = this.state

    this.closeCommentContext()
    this.setState({
      commentEditing: selectedComment
    })
  }

  onCancelEditingComment = () => {
    this.setState({
      commentEditing: null
    })
  }

  onDelete = () => {
    this.closeContext()
    this.toggleAlert('confirmDelete')
  }

  onDeleteComment = () => {
    this.closeCommentContext()
    this.toggleAlert('confirmDeleteComment')
  }

  onConfirmDelete = () => {
    const {
      post,
      afterDelete
    } = this.props

    this.setState({ loading: true })
    this.toggleAlert('confirmDelete')
    boardPostDelete({
      post: post.id
    }).then(() => {
      if (afterDelete) {
        afterDelete()
      }
    }).catch(error => {
      this.setState({ loading: false })
      console.error(error)
    })
  }

  onConfirmDeleteComment = () => {
    const {
      selectedComment
    } = this.state

    this.setState({ loading: true })
    this.toggleAlert('confirmDeleteComment')
    boardPostCommentDelete({
      comment: selectedComment.id
    }).then(() => {
      this.loadCommentList()
    }).catch(error => {
      this.setState({ loading: false })
      console.error(error)
    })
  }

  onSetEnlarge = (image, back = true) => {
    const {
      post,
      action
    } = this.props

    const {
      enlarge,
      isFileSwiping
    } = this.state

    if (!isFileSwiping && enlarge !== image) {
      if (image) {
        action.setLayoutShowHeader(false)
        historyPushState(window.location.pathname + '?p=' + post.id + '#i')
      } else {
        action.setLayoutShowHeader(true)
        if (back) {
          window.history.back()
        }
      }
      this.setState({ enlarge: image })
    }
  }

  onReport = () => {
    this.closeContext()
    this.toggleAlert('confirmReport')
  }

  onReportComment = () => {
    this.closeCommentContext()
    this.toggleAlert('confirmReportComment')
  }

  onBlock = (userId, from = 'post') => {
    this.setState({
      selectedUser: {
        from,
        userId
      }
    })
    this.closeContext()
    this.toggleAlert('confirmBlock')
  }

  onConfirmReport = () => {
    const {
      post,
      afterDelete
    } = this.props

    this.setState({ loading: true })
    boardPostReport({
      post: post.id
    }).then(() => {
      if (afterDelete) {
        afterDelete()
      }
      this.toggleAlert('confirmBlock')
    }).catch(error => {
      this.setState({ loading: false })
      this.toggleAlert('confirmBlock')
      console.error(error)
    })
  }

  onConfirmReportComment = () => {
    const {
      selectedComment
    } = this.state

    this.setState({ loading: true })
    this.toggleAlert('confirmReportComment')
    boardPostCommentReport({
      comment: selectedComment.id
    }).then(() => {
      this.loadCommentList()
    }).catch(error => {
      this.setState({ loading: false })
      console.error(error)
    })
  }

  onConfirmBlock = () => {
    const {
      selectedUser
    } = this.state

    const {
      afterDelete,
      action
    } = this.props

    this.setState({ loading: true })
    blockUser({
      target: selectedUser.userId
    }).then(() => {
      action.fetchUserData(true)
      if (selectedUser.from === 'post') {
        if (afterDelete) {
          afterDelete()
        }
        this.onNotifyPost(true)
      } else if (selectedUser.from === 'comment') {
        this.onNotifyComment(true)
      }
      this.setState({ selectedUser: null })
    }).catch(error => {
      this.setState({ loading: false })
      console.error(error)
    })
  }

  computedContextItems = () => {
    const {
      t,
      post
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData && post.author.id === userData.id) {
      return [{
        text: t('community.edit'),
        action: () => { this.onEdit() }
      }, {
        text: t('community.delete'),
        action: () => { this.onDelete() }
      }]
    } else {
      return [{
        text: t('community.report'),
        action: () => { this.onReport() }
      }, {
        text: t('community.block'),
        action: () => { this.onBlock(post.author.id) }
      }]
    }
  }

  computedCommentContextItems = () => {
    const {
      selectedComment
    } = this.state

    const {
      t
    } = this.props

    const {
      userData
    } = this.props.state

    if (selectedComment) {
      if (userData && selectedComment.author.id === userData.id) {
        return [{
          text: t('community.edit'),
          action: () => { this.onEditComment() }
        }, {
          text: t('community.delete'),
          action: () => { this.onDeleteComment() }
        }]
      } else if (selectedComment.parent) {
        return [{
          text: t('community.report'),
          action: () => { this.onReportComment() }
        }, {
          text: t('community.block'),
          action: () => { this.onBlock(selectedComment.author.id, 'comment') }
        }]
      } else {
        return [{
          text: (selectedComment.notified ? t('community.replyNotification.off') : t('community.replyNotification.on')),
          action: () => { this.onNotifyComment() }
        }, {
          text: t('community.report'),
          action: () => { this.onReportComment() }
        }, {
          text: t('community.block'),
          action: () => { this.onBlock(selectedComment.author.id, 'comment') }
        }]
      }
    }
    return []
  }

  nextFile = (index = false) => {
    const {
      fileIndex
    } = this.state

    const {
      post
    } = this.props

    if (index === false) {
      index = (fileIndex + 1) % post.files.length
    }
    index = Math.min(Math.max(0, index), post.files.length - 1)

    this.setState({ fileIndex: index })
  }

  loadOriginalLang = () => {
    const {
      post
    } = this.props

    this.setState({ loading: true })
    return new Promise(resolve => {
      boardPostInfo({
        id: post.id,
        lang: post.originalLang,
        isOriginal: true
      }).then(response => {
        this.setState({
          originalPost: response.data,
          loading: false
        })
        resolve()
      })
    })
  }

  loadCommentList = (scrollTo = false) => {
    const {
      post,
      afterComment
    } = this.props

    const {
      userData
    } = this.props.state

    this.setState({ loading: true })
    boardPostCommentList({
      post: post.id
    }).then(response => {
      const commentList = response.data.list
      this.setState({
        commentList
      })
      let countComment = commentList.length
      let commented = false
      for (const comment of commentList) {
        if (userData && comment.author && comment.author.id === userData.id) {
          commented = true
        }
        if (comment.children && comment.children.length) {
          countComment += comment.children.length
          for (const child of comment.children) {
            if (userData && child.author && child.author.id === userData.id) {
              commented = true
            }
          }
        }
      }

      if (afterComment) {
        afterComment(post, commented, countComment)
      }

      this.setState({
        loading: false,
        countComment,
        commented
      })

      if (scrollTo) {
        const commentEl = document.getElementById(`comment-${scrollTo}`)
        const postEl = document.getElementById('post-area')
        if (commentEl && postEl) {
          this.wrapperRef.current.scrollTop = postEl.offsetHeight + commentEl.offsetTop + 2 * commentEl.offsetHeight - window.outerHeight
        }
      }
    }).catch(error => {
      console.error(error)
      this.setState({ loading: false, commentReplying: null })
    })
  }

  toggleProfile = (user) => {
    const {
      action
    } = this.props

    if (!user) {
      action.setLayoutShowHeader(true)
      this.setState({
        showProfile: false
      })
    } else {
      action.setLayoutShowHeader(false)
      this.setState({
        showProfile: true,
        profile: user
      })
    }
  }

  onThumbnailSwipeStart = () => {
    this.setState({ fileSwiping: 0 })
  }

  onThumbnailSwipeMove = (position) => {
    const abs = Math.abs(position.x) > 10
    this.setState({ fileSwiping: position.x, isFileSwiping: abs })

    return abs
  }

  onThumbnailSwipeEnd = () => {
    const {
      fileIndex,
      fileSwiping
    } = this.state

    const {
      post
    } = this.props

    const containerWidth = this.thumbnailRef.current.offsetWidth
    const swipingDirection = fileSwiping > 0 ? 1: -1
    let index = Math.min(Math.max(0, Math.round((-fileSwiping - swipingDirection * (containerWidth / 2.5)) / containerWidth) + fileIndex), post.files.length - 1)

    this.setState({
      fileIndex: index,
      fileSwiping: 0
    })

    setTimeout(() => {
      this.setState({ isFileSwiping: false })
    }, 0)
  }

  onLoading = (value) => {
    this.setState({
      loading: value
    })
  }

  detectHash = () => {
    const hash = window.location.hash
    if (!hash) {
      this.onSetEnlarge(null, false)
    }
  }

  componentDidMount () {
    const {
      action,
      post
    } = this.props

    action.setLayoutShowFooter(false)

    this.setState({
      bookmarked: post.bookmarked,
      liked: post.liked,
      commented: post.commented,
      notified: post.notified,
      countLike: post.meta.countLike || 0,
      countComment: post.meta.countComment || 0
    })

    this.setNavBar(post.bookmarked, post.isOriginal)

    this.loadCommentList()

    window.addEventListener('popstate', this.detectHash)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectHash)
  }

  render () {
    const nodeRefLoader = createRef(null)
    const nodeRefList = createRef(null)
    const nodeRefWrite = createRef(null)
    const nodeRefProfile = createRef(null)
    const nodeRefImage = createRef(null)

    const {
      loading,
      liked,
      commented,
      notified,
      originalPost,
      countLike,
      countComment,
      showAlert,
      showContext,
      showWrite,
      fileIndex,
      fileSwiping,
      commentList,
      commentEditing,
      commentReplying,
      showCommentContext,
      showProfile,
      profile,
      enlarge,
      seeOriginal
    } = this.state

    const {
      t,
      board,
      post
    } = this.props

    return(
      <div className="board-post-read"
           ref={this.wrapperRef}>
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        <div className="post-area-wrap">
          <div className="post-area"
               id="post-area">
            <div className="layout-container">
              <div className="board">
                <span className="name">
                  {board.title}
                </span>
                <span className="date">
                  {timeString(post.createdAt, t)}
                </span>
              </div>
              <div className="author">
                {post.author &&
                  <span className="author-wrap"
                        onClick={() => { this.toggleProfile(post.author) }}>
                    <UiCountry code={post.country} />
                    {post.author.nickname}
                  </span>
                }
              </div>
              <h3 className="title">
                {seeOriginal ? originalPost.title : post.title}
              </h3>
              <div className="content">
                <UiWysiwygContent content={seeOriginal ? originalPost.content : post.content} />
              </div>
            </div>
            {post.files && post.files.length > 0 &&
              <Swipe className="files"
                     onSwipeStart={this.onThumbnailSwipeStart}
                     onSwipeMove={this.onThumbnailSwipeMove}
                     onSwipeEnd={this.onThumbnailSwipeEnd}
                     tolerance={50}
                     allowMouseEvents>
                <div className="layout-container">
                  <div ref={this.thumbnailRef}
                       className={`thumbnail ${fileSwiping !== 0 ? 'swiping' : ''}`}
                       style={{ left: `calc(-${fileIndex * 100}% + ${fileSwiping}px)` }}>
                    {post.files.map((file, i) =>
                      <div className="thumb"
                           key={`file-${i}`}>
                        <div className="th"
                             style={{ backgroundImage: `url('${getS3Url(file.thumbKey || file.key)}')` }}
                             onClick={() => { this.onSetEnlarge(file) }}>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="files-navigation">
                    {post.files.map((file, i) => {
                      return (
                        <button key={`file-bullet-${i}`}
                                className={`bullet ${fileIndex === i ? 'active' : ''}`}
                                onClick={() => { this.nextFile(i) }} />
                      )
                    })}
                  </div>
                </div>
                <div className="files-arrow">
                  <UiIcon name="arrow-left"
                          onClick={() => { this.nextFile(fileIndex - 1) }} />
                  <UiIcon name="arrow-right"
                          onClick={() => { this.nextFile(fileIndex + 1) }}/>
                </div>
              </Swipe>
            }

            <div className="layout-container">
              <div className="meta">
                <div className="button">
                  <button className={liked ? 'active' : ''}
                          onClick={this.onLikePost}>
                    <UiIcon name={`post-like ${liked ? 'active' : ''}`}
                            size="medium" />
                    {countLike || ''}
                  </button>
                </div>
                <div className="gap" />
                <div className="button">
                  <button className={commented ? 'active' : ''}
                          onClick={this.onCommentPost}>
                    <UiIcon name={`post-comment ${commented ? 'active' : ''}`}
                            size="medium" />
                    {countComment || ''}
                  </button>
                </div>
                <div className="gap" />
                <div className="button">
                  <button className={notified ? 'active' : ''}
                          onClick={() => { this.onNotifyPost() } }>
                    <UiIcon name={`post-ring ${notified ? 'active' : ''}`}
                            size="medium" />
                  </button>
                </div>
                <div className="gap" />
                <div className="button">
                  <button onClick={() => { this.onSharePost() } }>
                    <UiIcon name="post-share"
                            size="medium" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <CSSTransition in={commentList && commentList.length > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefList}
                         classNames="fade">
            <div className="comment-area"
                 ref={nodeRefList}>
              <div className="layout-container">
                <h3 className="comment-title">
                  {t('community.comment')}
                </h3>
                <ul className="comment-list">
                  {commentList.map((comment, i) => (
                    <li key={`comment-list-${i}`}
                        id={`comment-${comment.id}`}>
                      <BoardPostCommentItem comment={comment}
                                            openCommentContext={this.openCommentContext}
                                            onLikeComment={this.onLikeComment}
                                            onReplyingComment={this.onReplyingComment}
                                            onToggleProfile={this.toggleProfile}
                                            loading={this.onLoading} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </CSSTransition>
        </div>

        <UiContext show={showContext}
                   items={this.computedContextItems()}
                   onTryClose={() => { this.closeContext() }} />

        <UiContext show={showCommentContext}
                   items={this.computedCommentContextItems()}
                   onTryClose={() => { this.closeCommentContext() }} />

        <BoardPostCommentCreate board={board}
                                post={post}
                                editing={commentEditing}
                                replying={commentReplying}
                                afterComment={this.afterComment}
                                onCancelEditing={this.onCancelEditingComment}
                                onEndReplying={this.onReplyingComment} />

        <CSSTransition in={showProfile}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefProfile}
                       classNames="page-slide">
          <div className={`board-post-profile ${showProfile ? '' : 'backward'}`}
               ref={nodeRefProfile}>
            <Profile user={profile}
                     goBack={() => {
                       this.toggleProfile(null)
                     }} />
          </div>
        </CSSTransition>

        <CSSTransition in={!!enlarge}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefImage}
                       classNames="fade">
          <div className="board-post-image"
               ref={nodeRefImage}>
            <UiEnlargeImage imageKey={enlarge ? enlarge.key : null}
                            imageRef={this.imageRef}
                            imageList={post.files}
                            onNext={(file) => {this.onSetEnlarge(file) }}
                            onClose={() => { this.onSetEnlarge(null) }}>
              <div className="meta">
                <div className="button">
                  <button className={liked ? 'active' : ''}
                          onClick={this.onLikePost}>
                    <UiIcon name={`post-like white ${liked ? 'active' : ''}`}
                            size="medium" />
                    {countLike || ''}
                  </button>
                </div>
                <div className="gap" />
                <div className="button">
                  <button className={commented ? 'active' : ''}
                          onClick={this.onCommentPost}>
                    <UiIcon name={`post-comment white ${commented ? 'active' : ''}`}
                            size="medium" />
                    {countComment || ''}
                  </button>
                </div>
                <div className="gap" />
                <div className="button">
                  <button onClick={() => { this.onSaveImage(enlarge) } }>
                    <UiIcon name="save"
                            size="medium" />
                  </button>
                </div>
                <div className="gap" />
                <div className="button">
                  <button onClick={() => { this.onSharePost() } }>
                    <UiIcon name="post-share white"
                            size="medium" />
                  </button>
                </div>
              </div>
            </UiEnlargeImage>
          </div>
        </CSSTransition>

        {showAlert.confirmDelete &&
          <UiConfirm content={t('community.error.delete')}
                     onOkay={() => {
                       this.onConfirmDelete()
                     }}
                     onCancel={() => {
                      this.toggleAlert('confirmDelete')
                     }} />
        }

        {showAlert.confirmDeleteComment &&
          <UiConfirm content={t('community.error.delete')}
                     onOkay={() => {
                       this.onConfirmDeleteComment()
                     }}
                     onCancel={() => {
                       this.toggleAlert('confirmDelete')
                     }} />
        }

        {showAlert.confirmReport &&
          <UiConfirm content={t('community.alert.reportPost')}
                     onOkay={() => {
                       this.onConfirmReport()
                     }}
                     onCancel={() => {
                       this.toggleAlert('confirmReport')
                     }} />
        }

        {showAlert.confirmReportComment &&
          <UiConfirm content={t('community.alert.reportComment')}
                     onOkay={() => {
                       this.onConfirmReportComment()
                     }}
                     onCancel={() => {
                       this.toggleAlert('confirmReportComment')
                     }} />
        }

        {showAlert.confirmBlock &&
          <UiConfirm content={t('community.alert.block')}
                     onOkay={() => {
                       this.onConfirmBlock()
                     }}
                     onCancel={() => {
                       this.toggleAlert('confirmBlock')
                     }} />
        }

        {showAlert.shareCopied &&
          <UiAlert content={t('community.alert.copied')}
                   onOkay={() => {
                     this.copyShortenUrl()
                   }} />
        }

        <CSSTransition in={showWrite > 0}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefWrite}
                       classNames="page-slide">
          <div className={`post-create-wrap ${showWrite < 0 ? 'backward' : ''}`}
               ref={nodeRefWrite}>
            <BoardPostCreate board={board}
                             post={post}
                             afterPost={(post) => { this.closeWrite(post) }} />
          </div>
        </CSSTransition>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(CommunityBoardPostInfo, props)
export default withRouter(StateContainer)
