import React from 'react';
import Component from '../../utils/Component';

import UiIcon from '../../components/ui/Icon';

import './SplashLoader.scss'

class SplashLoader extends Component {
  render() {
    return (
      <div className="splash-loader">
        <UiIcon name="logo-white"/>
      </div>
    );
  }
}

export default SplashLoader;
