import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import { historyPushState, bodyScroll } from '../../utils/tool'
import Component from '../../utils/Component'

import { roomRequestInfo, roomRequest, roomRequestDelete, roomKeywordList } from '../../api/talk'

import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiCheckItem from '../../components/ui/CheckItem'
import UiAlert from '../../components/ui/layer/Alert'
import UiConfirm from '../../components/ui/layer/Confirm'

import './ChatRequest.scss'

class ChatList extends Component {
  state = {
    loading: false,
    requesting: null,
    step: 0,
    keyword: [],
    searchKeyword: '',
    showAlert: {
      complete: false,
      limit: false,
      cancel: false
    },
    list: []
  }

  loadStatus = () => {
    return roomRequestInfo().then(result => {
      this.setState({
        requesting: result.data.requesting
      })
    }).catch(error => {
      console.error(error)
    })
  }

  loadList = () => {
    this.setState({ loading: true })
    roomKeywordList().then(result => {
      let list = result.data.list.sort((a, b) => {
        if (a.order < b.order) return -1
        if (a.order > b.order) return 1
        if (a.comingSoon < b.comingSoon) return -1
        if (a.comingSoon > b.comingSoon) return 1
        return a.title.localeCompare(b.title)
      })
      this.setState({
        loading: false,
        list
      })
    }).catch(error => {
      console.error(error)
    })
  }

  toStep0 = () => {
    this.setState({
      step: 0,
      keyword: []
    })
    bodyScroll(false)

    this.setStateInside('showAlert', 'complete', false)
    this.setStateInside('showAlert', 'limit', false)
    this.setStateInside('showAlert', 'cancel', false)
  }

  toStep1 = () => {
    const {
      requesting
    } = this.state

    if (requesting) {
      this.setStateInside('showAlert', 'cancel', true)
    } else {
      // const {
      //   roomCount
      // } = this.props
      //
      // if (roomCount >= 5) {
      //   this.setStateInside('showAlert', 'limit', true)
      // } else {
      //   this.setState({
      //     step: 1
      //   })
      // }
      this.setState({
        step: 1
      })
    }
  }

  toStep2 = () => {
    const {
      userData
    } = this.props.state

    if (userData) {
      this.setState({
        step: 2
      })
      historyPushState(window.location.pathname + '?q=1')
      bodyScroll(true)
      this.loadList()
    } else {
      const {
        action
      } = this.props
      action.setLayoutShowLogin(true)
    }
  }

  confirmCancel = () => {
    this.setState({
      requesting: false
    })
    roomRequestDelete().catch(error => {
      console.error(error)
    })
    this.setStateInside('showAlert', 'cancel', false)
  }

  selectKeyword = (keyword) => {
    this.setState({
      keyword: [keyword]
    })
  }

  keywordOnChange = (searchKeyword) => {
    this.setState({ searchKeyword })
  }

  sendRequest = () => {
    const {
      keyword
    } = this.state

    const {
      afterRequest
    } = this.props

    this.setState({
      loading: true
    })
    roomRequest({ keyword }).then(result => {
      this.setState({
        requesting: result.data.requesting,
        loading: false
      })
      this.setStateInside('showAlert', 'complete', true)
      if (afterRequest) {
        setTimeout(() => {
          afterRequest()
        }, 1000)
      }
    }).catch(error => {
      console.error(error)
    })
  }

  closeLayer = (reset = true) => {
    this.setStateInside('showAlert', 'complete', false)
    historyPushState(window.location.pathname)
    this.toStep0()
  }

  detectLayer = () => {
    if (!window.location.search) {
      this.toStep0()
    }
  }

  componentDidMount () {
    const {
      location
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      this.loadStatus().then(() => {
        if (location.state && location.state.action === 'request') {
          setTimeout(() => {
            this.toStep1()
          }, 100)
        }
      }).catch(error => {
        console.error(error)
      })

      window.addEventListener('popstate', this.detectLayer)
    } else {
      this.setState({ requesting: false })
      if (location.state && location.state.action === 'request') {
        setTimeout(() => {
          this.toStep1()
        }, 100)
      }
    }
  }

  componentWillUnmount () {
    const {
      userData
    } = this.props.state

    if (userData) {
      window.removeEventListener('popstate', this.detectLayer)
    }
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefBeforeRequestDim = createRef(null)
    const nodeRefBeforeRequest = createRef(null)
    const nodeRefRequesting = createRef(null)

    const {
      loading,
      requesting,
      step,
      keyword,
      searchKeyword,
      showAlert,
      list
    } = this.state

    const {
      t
    } = this.props

    const currentSearchKeyword = searchKeyword.toLowerCase()
    const listWithKeyword = list.filter(artist => {
      return !searchKeyword || artist.title.toLowerCase().includes(currentSearchKeyword)
    })

    return(
      <div className="talk-room-request">
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        <div className="group-matching-button">
          {requesting === false &&
            <button onClick={this.toStep1}>{t('talk.request.button.request')}</button>
          }
          {requesting === true &&
            <button onClick={this.toStep1} className="ing">{t('talk.request.button.requesting')}</button>
          }
        </div>

        <div className="request-layers">
          <CSSTransition in={step === 1}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefBeforeRequestDim}
                         classNames="fade">
            <div className="request-dim"
                 ref={nodeRefBeforeRequestDim}
                 onClick={this.toStep0}> </div>
          </CSSTransition>

          <CSSTransition in={step === 1}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefBeforeRequest}
                         classNames="select">
            <div className="requesting-description"
                 ref={nodeRefBeforeRequest}>
              <div className="desc-back">
                <UiIcon name="back"
                        onClick={this.toStep0} />
              </div>
              <h2 className="desc-title">
                {t('talk.request.description.title')}
              </h2>
              <div className="desc-description">
                <div dangerouslySetInnerHTML={{ __html: t('talk.request.description.description') }} />
              </div>
              <div className="desc-bubble">
                <div dangerouslySetInnerHTML={{ __html: t('talk.request.description.bubble') }} />
              </div>
              <div className="desc-image" />
              <div className="desc-button">
                <UiButton text={t('talk.request.request')}
                          onClick={this.toStep2} />
              </div>
            </div>
          </CSSTransition>

          <CSSTransition in={step === 2}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefRequesting}
                         classNames="page-slide">
            <div className="requesting-list"
                 ref={nodeRefRequesting}>
              <div className="layout-container">
                <div className="list-header">
                  <UiIcon name="back"
                          onClick={() => { window.history.back() }} />
                </div>

                <h2 className="list-title">
                  {t('talk.requesting.title')}
                </h2>
                <div className="list-description">
                  <div dangerouslySetInnerHTML={{ __html: t('talk.requesting.description') }} />
                </div>
                <div className="list-search">
                  <UiInput type="text"
                           color="fill"
                           placeholder={t('common.search')}
                           icon="search"
                           value={searchKeyword}
                           onChange={value => this.keywordOnChange(value)} />
                </div>
                <div className="list-items">
                  {listWithKeyword.map((value, i) => {
                    return (
                      <div className={`list-item ${value.comingSoon ? 'coming' : ''}`}
                           key={`list-item-${value}-${i}`}>
                        {!value.comingSoon &&
                          <UiCheckItem thisValue={value.id}
                                       value={keyword}
                                       title={value.title}
                                       onClick={this.selectKeyword}/>
                        }
                        {value.comingSoon &&
                          <div className="check-item">
                            <div className="check-text">
                              <div className="item-title">
                                {value.title}
                                <span className="coming">Coming Soon</span>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    )
                  })}
                </div>
                <div className="list-button">
                  <UiButton text={t('talk.requesting.submit')}
                            isDisabled={keyword.length < 1}
                            onClick={this.sendRequest} />
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>

        {showAlert.complete &&
          <UiAlert content={t('talk.requesting.complete')}
                        onOkay={this.closeLayer} />
        }
        {showAlert.limit &&
          <UiAlert content={t('talk.request.limit')}
                        onOkay={this.toStep0} />
        }
        {showAlert.cancel &&
          <UiConfirm content={t('talk.cancelRequest.description')}
                     okayText={t('talk.cancelRequest.title')}
                     onOkay={this.confirmCancel}
                     cancelText={t('common.no')}
                     onCancel={this.toStep0} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ChatList, props)
export default withRouter(StateContainer)
