import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '../utils/router'
import Component from '../utils/Component'

import LayoutContainer from '../layouts/LayoutContainer'
import BlankLayout from '../layouts/BlankLayout'
import DefaultLayout from '../layouts/DefaultLayout'

import Home from '../pages/home/Main'
import Shared from '../pages/Shared'

import SignIn from '../pages/signin/Signin'
import SignUp from '../pages/signin/SignUp'
import ForgotPassword from '../pages/signin/ForgotPassword'

import CommunityBoardList from '../pages/community/BoardList'
import CommunityBoardPostList from '../pages/community/BoardPostList'

import ChatList from '../pages/talk/ChatList'

import PlaceMap from '../pages/place/Map'

import MyPage from '../pages/mypage/My'
import MyProfile from '../pages/mypage/MyProfile'
import MyBoardPost from '../pages/mypage/BoardPost'
import MyBoardPostBookmark from '../pages/mypage/BoardPostBookmark'
import MySetting from '../pages/mypage/Setting'
import MyNotification from '../pages/mypage/Notification'

import Service from '../pages/service/Center'
import ServiceNotice from '../pages/service/Notice'
import ServiceFaq from '../pages/service/Faq'
import ServiceTerm from '../pages/service/Term'
import ServiceVersion from '../pages/service/Version'

class Router extends Component {
  render() {
    return (
      <LayoutContainer>
        <Switch>
          <Route exact path="/h" content={<DefaultLayout page={<Home />} />} />
          <Route exact path="/s/:url" content={<Shared />} />

          <Route exact path="/signin" content={<BlankLayout page={<SignIn />} />} privilege="guestOnly" />
          <Route exact path="/signup" content={<DefaultLayout page={<SignUp />} />} privilege="guestOnly" />
          <Route exact path="/sign/forgot" content={<DefaultLayout page={<ForgotPassword />} />} privilege="guestOnly" />

          <Route exact path="/c/b" content={<DefaultLayout page={<CommunityBoardList />} />} />
          <Route exact path="/c/:id" content={<DefaultLayout page={<CommunityBoardPostList />} />} />

          <Route exact path="/t/r" content={<DefaultLayout page={<ChatList />} />} />

          <Route exact path="/" content={<DefaultLayout page={<PlaceMap />} />} />
          <Route exact path="/p" content={<DefaultLayout page={<PlaceMap />} />} />

          <Route exact path="/my" content={<DefaultLayout page={<MyPage />} />} />
          <Route exact path="/my/post" content={<DefaultLayout page={<MyBoardPost />} />} />
          <Route exact path="/my/bookmark" content={<DefaultLayout page={<MyBoardPostBookmark />} />} />
          <Route exact path="/my/profile" content={<DefaultLayout page={<MyProfile />} />} privilege="memberOnly" />
          <Route exact path="/my/setting" content={<DefaultLayout page={<MySetting />} />} privilege="memberOnly" />
          <Route exact path="/noti" content={<DefaultLayout page={<MyNotification />} />} privilege="memberOnly" />

          <Route exact path="/service" content={<DefaultLayout page={<Service />} />} />
          <Route exact path="/service/notice" content={<DefaultLayout page={<ServiceNotice />} />} />
          <Route exact path="/service/faq" content={<DefaultLayout page={<ServiceFaq />} />} />
          <Route exact path="/service/term" content={<DefaultLayout page={<ServiceTerm />} />} />
          <Route exact path="/service/term/privacy" content={<DefaultLayout page={<ServiceTerm term="privacy" />} />} />
          <Route exact path="/service/version" content={<DefaultLayout page={<ServiceVersion />} />} />
        </Switch>
      </LayoutContainer>
    )
  }
}

export default Router
