import dotenv from 'dotenv';

dotenv.config();

const config = {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  BCRYPT_SALT_ROUNDS: process.env.REACT_APP_BCRYPT_SALT_ROUNDS || 10,
  REACT_APP_GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''
};

export const { API_URL, BCRYPT_SALT_ROUNDS, REACT_APP_GOOGLE_MAP_API_KEY } = config;
export default config;