import io from 'socket.io-client'
import { API_URL } from '../config'
import httpClient from '../utils/http'

export function talkSocket (query) {
  const options = {
    path: `/api/talk`,
    query
  }
  if (navigator.userAgent.match(/WebView\/Nearcle/i)) {
    options.transports = ['polling']
  }
  return io(API_URL.replace('/api', ''), options)
}

export function roomList (params) {
  const url = '/t/r'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomInfo (params) {
  const url = '/t/r/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomUpdate (params) {
  const url = '/t/r/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomLeave (params) {
  const url = '/t/r/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomReport (params) {
  const url = '/t/r/r'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomDirectRequest (params) {
  const url = '/t/r/dr'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomRequestInfo (params) {
  const url = '/t/r/r/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomRequest (params) {
  const url = '/t/r/r/c'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomRequestDelete (params) {
  const url = '/t/r/r/c/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomKeywordList (params) {
  const url = '/t/r/k/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
