import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { format } from 'date-fns'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import { getS3Url, getS3Key, historyPushState } from '../../utils/tool'
import Component from '../../utils/Component'

import { roomInfo, roomUpdate, roomLeave, roomReport } from '../../api/talk'

import defaultBlankImage from '../../assets/images/asset--no-image.svg'
import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiCountry from '../../components/ui/Country'
import UiEnlargeImage from '../../components/ui/EnlargeImage'
import UiConfirm from '../../components/ui/layer/Confirm'
import UiAlert from '../../components/ui/layer/Alert'
import UiWysiwygContent from '../../components/ui/WysiwygContent'

import ChatCreate from '../talk/ChatCreate'
import Profile from '../mypage/Profile'

import './ChatRoom.scss'

class ChatRoom extends Component {
  wrapperRef = createRef()
  imageRef = createRef()
  loading = false

  state = {
    loading: false,
    replying: null,
    list: [],
    userMap: {},
    showSide: false,
    showSetting: false,
    showProfile: false,
    profile: null,
    showAlert: {
      leave: false,
      report: false,
      reportComplete: false,
      disconnected: false
    },
    lastId: null,
    showRenaming: false,
    renameValue: '',
    renameLimit: 30,
    hasDummy: false,
    enlarge: false
  }

  renameOnChange = (renameValue) => {
    this.setState({ renameValue })
  }

  loadList = (loading = true, callback = () => {}) => {
    const {
      list,
      lastId
    } = this.state

    const {
      room
    } = this.props

    if (loading) {
      this.setState({ loading: true })
    }

    if (lastId !== false) {
      roomInfo({
        id: room.id,
        lastId
      }).then(result => {
        const newList = []
        const messages = result.data.list
        this.setState({ lastId: messages.length > 0 ? messages[messages.length - 1].id : false })

        for (const message of messages) {
          newList.push(this.setMessage(message))
        }

        this.setState({
          loading: false,
          list: newList.reverse().concat(list)
        })
        this.toBottom(10)
        setTimeout(() => {
          callback()
        }, 10)
      }).catch(error => {
        console.error(error)
        callback()
      })
    } else {
      callback()
    }
  }

  onScroll = () => {
    if (this.state.lastId !== false && this.loading !== this.state.lastId && this.wrapperRef.current.scrollTop < 300) {
      this.loading = this.state.lastId
      this.loadList(false, () => {
        this.loading = false
      })
    }
  }

  mapUser = () => {
    const {
      userMap
    } = this.state

    const {
      room
    } = this.props

    for (const user of room.members) {
      userMap[user.id] = user
    }
  }

  joinRoom = () => {
    const {
      room
    } = this.props

    const {
      ws,
      userData
    } = this.props.state

    ws.emit('enterRoom', { room: room.id })
    ws.off('receiveChatRoom')
    ws.on('receiveChatRoom', message => {
      if (message.room === room.id) {
        this.addMessage(message.message)
      }
    })
    ws.off('translateChatRoom')
    ws.on('translateChatRoom', message => {
      if (message.user === userData.id) {
        this.addTranslateMessage(message)
      }
    })
    ws.on('disconnect', () => {
      window.location.reload(false)
      // this.setStateInside('showAlert', 'disconnected', true)
    })
  }

  setMessage = (message) => {
    const {
      userData
    } = this.props.state

    const msg = {
      ...message,
    }

    if (!msg.type) {
      msg.text = typeof msg.text !== 'string' ? msg.text[userData.lang] : msg.text
    } else if (msg.type === 'image') {
      if (msg.message.image) {
        if (msg.message.image.ratio) {
          msg.message.ratio = msg.message.image.ratio
        }
        msg.message.image = typeof msg.message.image !== 'string' ? getS3Url(msg.message.image.key) : msg.message.image
      }
      if (msg.message.thumbnail) {
        msg.message.thumbnail = typeof msg.message.thumbnail !== 'string' ? getS3Url(msg.message.thumbnail.key) : msg.message.thumbnail
      }
    }

    return msg
  }

  addMessage = (message, toBottom = true) => {
    const {
      list
    } = this.state

    list.push(this.setMessage(message))
    this.setState({
      list
    })

    if (!message.dummyId && !message.localId && message.tempId) {
      this.removeDummy(message)
    } else if (message.localId) {
      this.deleteMessage(message)
    } else {
      if (message.dummyId) {
        this.setState({ hasDummy: true })
      }
    }
    if (toBottom) {
      this.toBottom()
    }
  }

  showMessageButton = (tempId) => {
    const {
      list
    } = this.state

    for (const msg of list) {
      if (msg.tempId && msg.tempId === tempId) {
        msg.longAgo = true
      }
    }

    this.setState({ list })
  }

  toBottom = (timeout = 0) => {

    setTimeout(() => {
      if (this.wrapperRef.current) {
        this.wrapperRef.current.scrollTop = this.wrapperRef.current.scrollHeight
      }
    }, timeout)
  }

  sendChat = (chat) => {
    const {
      room
    } = this.props

    const {
      ws,
      userData
    } = this.props.state

    const now = new Date()
    const tempId = chat.tempId || now.toString()

    if (!chat.type && chat.text) {
      const text = chat.text.trim()

      if (text) {
        this.addMessage({
          tempId,
          lang: userData.lang,
          user: userData.id,
          originalLang: userData.lang,
          message: {},
          text,
          createdAt: now
        })

        setTimeout(() => {
          this.showMessageButton(tempId)
        }, 200)

        ws.emit('chatRoom', {
          room: room.id,
          chat: {
            tempId,
            ...chat
          }
        })
      }
    } else if (chat.type === 'image') {
      const m = {
        tempId,
        type: chat.type,
        lang: userData.lang,
        user: userData.id,
        originalLang: userData.lang,
        message: {
          image: chat.originalUrl,
          thumbnail: chat.thumbnailUrl
        },
        ratio: chat.ratio,
        createdAt: now
      }
      if (chat.dummyId) {
        m.dummyId = chat.dummyId
      }
      this.addMessage(m)

      if (chat.originalFile && chat.resizedFile) {
        setTimeout(() => {
          this.showMessageButton(tempId)
        }, 200)

        ws.emit('chatRoom', {
          room: room.id,
          chat: {
            tempId,
            type: 'image',
            originalFile: chat.originalFile,
            resizedFile: chat.resizedFile,
            ratio: chat.ratio
          }
        })
      }
    }
  }

  resendMessage = (message) => {
    this.sendChat(message)
    this.deleteMessage(message)
  }

  removeDummy = (message) => {
    const {
      list
    } = this.state

    if (message.tempId) {
      const newList = list.filter(msg => !msg.dummyId || msg.dummyId !== message.tempId)
      this.setState({ list: newList })
      const dummyCount = newList.reduce((prev, next) => prev + (next.dummyId ? 1 : 0), 0)
      if (dummyCount < 1) {
        this.setState({hasDummy: false})
      }
    }
  }

  deleteMessage = (message) => {
    const {
      list
    } = this.state

    if (message.localId) {
      this.setState({ list: list.filter(msg => msg.tempId !== message.localId) })
    } else if (message.tempId) {
      this.setState({ list: list.filter(msg => msg.tempId !== message.tempId) })
    }
  }

  translateMessage = (msg) => {
    const {
      room
    } = this.props

    const {
      ws
    } = this.props.state

    ws.emit('translateChat', {
      room: room.id,
      message: msg.id,
      original: msg.originalId
    })
  }

  addTranslateMessage = (message) => {
    const {
      list
    } = this.state

    for (const msg of list) {
      if (message.id === msg.id) {
        msg.translation = message.content
      }
    }

    setTimeout(() => {
      this.wrapperRef.current.scrollTop += 31
    }, 0)

    this.setState({ list })
  }

  showBlockedMessage = (message) => {
    const {
      list
    } = this.state

    for (const msg of list) {
      if (message.id === msg.id) {
        msg.showBlocked = !message.showBlocked
      }
    }

    setTimeout(() => {
      this.wrapperRef.current.scrollTop += message.showBlocked ? 31 : -31
    }, 0)

    this.setState({ list })
  }

  exitRoom = () => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', 'leave', !showAlert.leave)
  }

  confirmExitRoom = (loading = true) => {
    const {
      room,
      onClose
    } = this.props

    const {
      ws
    } = this.props.state

    if (loading) {
      this.setState({ loading: true })
    }

    roomLeave({
      id: room.id
    }).then(() => {
      this.setState({
        loading: false,
      })
      onClose()
      ws.emit('leaveRoom', { room: room.id })
    }).catch(error => {
      console.error(error)
    })
  }

  onReport = () => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', 'report', !showAlert.report)
  }

  onRename = () => {
    const {
      showRenaming
    } = this.state

    this.setState({ showRenaming: !showRenaming })
  }

  onConfirmRename = (loading = true) => {
    const {
      room,
      afterRename
    } = this.props

    const {
      renameValue
    } = this.state

    if (loading) {
      this.setState({ loading: true })
    }

    roomUpdate({
      id: room.id,
      rename: renameValue
    }).then(() => {
      this.setState({
        loading: false,
      })
      this.onRename()
      if (afterRename) {
        afterRename(room.id, renameValue)
      }
    }).catch(error => {
      console.error(error)
    })
  }

  confirmReport = (loading = true) => {
    const {
      room
    } = this.props

    if (loading) {
      this.setState({ loading: true })
    }

    roomReport({
      id: room.id
    }).then(() => {
      this.setState({
        loading: false,
      })
      this.onReport()
      this.setStateInside('showAlert', 'reportComplete', true)
    }).catch(error => {
      console.error(error)
    })
  }

  endReport = () => {
    this.setStateInside('showAlert', 'reportComplete', false)
  }

  toggleSide = () => {
    const {
      showSide
    } = this.state

    this.setState({
      showSide: !showSide
    })
  }

  toggleSetting = () => {
    const {
      showSetting
    } = this.state

    this.setState({
      showSetting: !showSetting
    })
  }

  toggleProfile = (user, back = true) => {
    if (user) {
      let url = window.location.pathname
      if (window.location.search) {
        url += window.location.search
      }
      const hash = window.location.hash
      if (hash && !hash.includes('p')) {
        url += hash + 'p'
      } else {
        url += '#rp'
      }
      historyPushState(url)
      this.setState({
        showProfile: true,
        profile: user
      })
    } else {
      if (back) {
        window.history.back()
      }
    }
  }

  onSetEnlarge = (image, back = true) => {
    if (image) {
      let url = window.location.pathname
      if (window.location.search) {
        url += window.location.search
      }
      const hash = window.location.hash
      if (hash && !hash.includes('i')) {
        url += hash + 'i'
      } else {
        url += '#ri'
      }
      historyPushState(url)
    } else {
      if (back) {
        window.history.back()
      }
    }
    this.setState({ enlarge: image })
  }

  onSaveImage = () => {
    const {
      enlarge
    } = this.state

    if (enlarge) {
      const key = getS3Key(enlarge)

      if (window.nearcleAndroid) {
        window.nearcleAndroid.saveImage(key)
      } else if (window.webkit && window.webkit.messageHandlers.nearcleIos) {
        window.webkit.messageHandlers.nearcleIos.postMessage(JSON.stringify({
          action: 'saveImage',
          data: key
        }))
      } else {
        let a = document.createElement('a')
        a.style = 'display: none'
        a.href = getS3Url(key)
        a.download = 'nearcle.png'

        document.body.appendChild(a)
        a.click()

        setTimeout(function () {
          document.body.removeChild(a)
        }, 100)
      }
    }
  }

  detectHash = () => {
    const hash = window.location.hash
    if (!hash || !hash.includes('p')) {
      this.setState({
        showProfile: false
      })
    }
    if (!hash || !hash.includes('i')) {
      this.onSetEnlarge(null, false)
    }
  }

  componentDidMount () {
    this.joinRoom()
    this.mapUser()
    this.loadList()

    window.addEventListener('popstate', this.detectHash)
  }

  componentWillUnmount() {
    const {
      ws
    } = this.props.state

    ws.off('receiveChatRoom')
    ws.off('translateChatRoom')

    window.removeEventListener('popstate', this.detectHash)
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefSideDim = createRef(null)
    const nodeRefSide = createRef(null)
    const nodeRefSetting = createRef(null)
    const nodeRefSettingRename = createRef(null)
    const nodeRefProfile = createRef(null)
    const nodeRefImage = createRef(null)

    const {
      loading,
      replying,
      list,
      userMap,
      showSide,
      showSetting,
      showAlert,
      showRenaming,
      showProfile,
      profile,
      renameValue,
      renameLimit,
      hasDummy,
      enlarge
    } = this.state

    const {
      t,
      room,
      onClose
    } = this.props

    const {
      userData
    } = this.props.state

    let currentDate = null
    const chatItem = (chat, i) => {
      const user = userMap[chat.user]
      const chatDate = format(new Date(chat.createdAt), 'yyyy.MM.dd')
      const showDate = chatDate !== currentDate
      currentDate = chatDate
      const me = userData.id === chat.user
      const blocked = userData ? (userData.blockList && user && userData.blockList.includes(user.id)) : false
      return (
        <div key={`chat-item-${i}`}>
          {!chat.dummyId &&
            <>
              {showDate &&
                <div className="chat-system">
                  <div className="msg">
                    {chatDate}
                  </div>
                </div>
              }
              {chat.type === 'system' &&
                <>
                  {chat.message.type === 'newUser' &&
                    <div className="chat-system">
                      <div className="msg">
                        {t('talk.message.entered', { user: chat.message.userName })}
                      </div>
                    </div>
                  }
                  {chat.message.type === 'leaveUser' &&
                    <div className="chat-system">
                      <div className="msg">
                        {t('talk.message.leaved', { user: chat.message.userName })}
                      </div>
                    </div>
                  }
                  {chat.message.type === 'warnDisabledDueToDate' &&
                    <div className="chat-system">
                      <div className="msg">
                        <span dangerouslySetInnerHTML={{ __html: t('talk.message.warnDisabledDueToDate') }} />
                      </div>
                    </div>
                  }
                  {chat.message.type === 'disabledDueToDate' &&
                    <div className="chat-system">
                      <div className="msg">
                        <span dangerouslySetInnerHTML={{ __html: t('talk.message.disabledDueToDate') }} />
                      </div>
                    </div>
                  }
                </>
              }
              {chat.type !== 'system' &&
                <div className={`chat-item ${me ? 'me' : ''}`}>
                  {!me &&
                    <div className="chat-profile"
                         onClick={() => { this.toggleProfile(user) }}>
                      <span className="user-profile"
                            style={{backgroundImage: `url('${(!blocked && user.profileImage) ? getS3Url(user.profileImage) : defaultBlankImage}')`}}/>
                      {userData.country !== user.country &&
                      <UiCountry code={user.country}
                                 onlyFlag={true}/>
                      }
                    </div>
                  }
                  <div className="chat-info">
                    {!me &&
                      <h6>{user.nickname}</h6>
                    }
                    <div className="chat-message">
                      {me &&
                        <em>
                          {chat.longAgo &&
                            <span className="chat-uploading">
                              <UiIcon name="chat-re"
                                      onClick={() => { this.resendMessage(chat) }} />
                              <UiIcon name="chat-delete"
                                      onClick={() => { this.deleteMessage(chat) }} />
                            </span>
                          }
                          <span>{format(new Date(chat.createdAt), 'HH:mm')}</span>
                        </em>
                      }
                      {!chat.type &&
                        <div className="msg">
                          {blocked &&
                            <div className={`msg-blocked ${chat.showBlocked ? 'show' : ''}`}
                                 onClick={() => { this.showBlockedMessage(chat) }}>
                              {t('community.blocked')}
                            </div>
                          }
                          {(!blocked || chat.showBlocked) &&
                            <>
                              {!chat.translation &&
                                <UiWysiwygContent elementType="p"
                                                  nl2br={true}
                                                  content={chat.text} />
                              }
                              {chat.translation &&
                                <>
                                  <div className="msg-original">
                                    <UiWysiwygContent elementType="p"
                                                      nl2br={true}
                                                      content={chat.text} />
                                  </div>
                                  <UiWysiwygContent elementType="p"
                                                    nl2br={true}
                                                    content={chat.translation} />
                                </>
                              }
                            </>
                          }
                        </div>
                      }
                      {chat.type === 'image' &&
                        <>
                          {blocked &&
                            <div className="msg">
                              <div className="msg-blocked"
                                   onClick={() => { this.showBlockedMessage(chat) }}>
                                {t('community.blocked')}
                              </div>
                            </div>
                          }
                          {(!blocked || chat.showBlocked) &&
                            <div className="msg image">
                              {chat.longAgo &&
                                <div className="image-loader">
                                  <UiIcon name="loader" />
                                </div>
                              }
                              {chat.message.ratio &&
                                <div className="image-wrap"
                                     style={{ paddingBottom: 1 / chat.message.ratio * 100 + '%' }}
                                     onClick={() => { this.onSetEnlarge(chat.message.image) }}>
                                  <img src={chat.message.thumbnail}
                                       style={{ marginBottom: -1 / chat.message.ratio * 100 + '%' }}
                                       alt="" />
                                </div>
                              }
                              {!chat.message.ratio &&
                                <img src={chat.message.thumbnail}
                                     onClick={() => { this.onSetEnlarge(chat.message.image) }}
                                     alt="" />
                              }
                            </div>
                          }
                        </>
                      }
                      {!me &&
                        <>
                          {(!chat.type && !chat.translation && (!blocked || chat.showBlocked)) &&
                            <button className="msg-translation"
                                    onClick={() => { this.translateMessage(chat) }}>
                              <UiIcon name="translation"/>
                            </button>
                          }
                          <em>{format(new Date(chat.createdAt), 'HH:mm')}</em>
                        </>
                      }
                    </div>
                  </div>
                </div>
              }
            </>
          }
        </div>
      )
    }

    return(
      <div className="talk-room"
           ref={this.wrapperRef}
           onScroll={this.onScroll}>
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        <div className="room-header">
          <div className="layout-container">
            <div className="room-header-wrap">
              <div className="room-back">
                <UiIcon name="back"
                        onClick={() => { onClose(room) }} />
              </div>
              <div className="room-title">
                {room.type === 'group' &&
                  <h1>
                    {room.name}
                    {room.memberCount > 1 &&
                      <em>{room.memberCount}</em>
                    }
                  </h1>
                }
                {room.type === 'direct' &&
                  <h1>
                    {room.members[0].nickname}
                  </h1>
                }
              </div>
              <div className="room-icons">
                <UiIcon name="hamburger-line"
                        onClick={() => { this.toggleSide() }} />
              </div>
            </div>
          </div>
        </div>

        <div className="room-content">
          <div className="layout-container">
            {list.map((chat, i) => {
              return chatItem(chat, i)
            })}
            {hasDummy &&
              <div className="chat-loader">
                <UiIcon name="loader" />
              </div>
            }
          </div>
        </div>

        {!room.disabledAt &&
          <div className="room-footer">
            <ChatCreate room={room}
                        replying={replying}
                        onFocus={() => { this.toBottom(250) }}
                        onEndReplying={null}
                        onSubmit={this.sendChat}/>
          </div>
        }

        <CSSTransition in={showSide}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefSideDim}
                       classNames="fade">
          <div className="room-side-dim"
               ref={nodeRefSideDim}
               onClick={() => { this.toggleSide() }} />
        </CSSTransition>

        <CSSTransition in={showSide}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefSide}
                       classNames="side">
          <div className="room-side"
               ref={nodeRefSide}>
            <div className="room-side-wrap">
              <div className="side-title">
                {room.name}
              </div>
              <div className="side-members">
                <h5 className="members-title">
                  {t('talk.members')}
                </h5>
                <div className="user-profiles">
                  <button className="user-profile"
                          onClick={() => { this.toggleProfile(userData) }}>
                    <div className="profile-image"
                         style={{ backgroundImage: `url('${userData.profileImage ? getS3Url(userData.profileImage) : defaultBlankImage}')` }}>
                      <em>{t('talk.me')}</em>
                    </div>
                    <div className="profile-name">
                      {userData.nickname}
                    </div>
                    <div className="profile-country">
                      <UiCountry code={userData.country} />
                    </div>
                  </button>
                  {room.members.map(user => {
                    if (user.userId === userData.userId || user.deleted) {
                      return <span key={`room-user-${user.userId}`} />
                    }
                    const blocked = userData ? (userData.blockList && user && userData.blockList.includes(user.id)) : false
                    return (
                      <button key={`room-user-${user.userId}`}
                              onClick={() => { this.toggleProfile(user) }}
                              className="user-profile button">
                        <div className="profile-image"
                             style={{ backgroundImage: `url('${!blocked && user.profileImage ? getS3Url(user.profileImage) : defaultBlankImage}')` }} />
                        <div className="profile-name">
                          {user.nickname}
                        </div>
                        <div className="profile-country">
                          <UiCountry code={user.country} />
                        </div>
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className="side-buttons">
                <button onClick={() => { this.exitRoom() }}>
                  <UiIcon name="exit" />
                </button>
                <button onClick={() => { this.toggleSetting() }}>
                  <UiIcon name="setting" />
                </button>
              </div>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={showSetting}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefSetting}
                       classNames="page-slide">
          <div className={`room-setting ${showSetting ? '' : 'backward'}`}
               ref={nodeRefSetting}>
            <div className="layout-container">
              <div className="setting-header">
                <div className="setting-back">
                  <UiIcon name="back"
                          onClick={() => { this.toggleSetting() }} />
                </div>
                <div className="setting-title">
                  <h1>
                    {t('talk.setting.title')}
                  </h1>
                </div>
              </div>
              <div className="setting-body">
                <ul>
                  <li>
                    <button onClick={() => this.onRename() }>{t('talk.setting.rename')}</button>
                  </li>
                  <li>
                    <button onClick={() => this.onReport() }>{t('talk.setting.report')}</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={showRenaming}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefSettingRename}
                       classNames="page-slide">
          <div className={`room-setting ${showRenaming ? '' : 'backward'}`}
               ref={nodeRefSettingRename}>
            <div className="layout-container">
              <div className="setting-header">
                <div className="setting-back">
                  <UiIcon name="back"
                          onClick={() => { this.onRename() }} />
                </div>
                <div className="setting-title">
                  <h1>
                    {t('talk.setting.rename')}
                  </h1>
                </div>
              </div>
              <div className="setting-body">
                <div className="rename-setting">
                  <UiInput type="text"
                           color="fill"
                           placeholder={room.name}
                           max={renameLimit}
                           value={renameValue}
                           onChange={value => this.renameOnChange(value)} />

                  <UiButton text={t('common.confirm')}
                            isDisabled={renameValue.length < 1}
                            isFixed={true}
                            onClick={this.onConfirmRename} />
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={showProfile}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefProfile}
                       classNames="page-slide">
          <div className={`room-profile ${showProfile ? '' : 'backward'}`}
               ref={nodeRefProfile}>
            <Profile user={profile}
                     goBack={() => {
                       this.toggleProfile(null)
                     }} />
          </div>
        </CSSTransition>

        <CSSTransition in={!!enlarge}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefImage}
                       classNames="fade">
          <div className="room-image"
               ref={nodeRefImage}>
            <UiEnlargeImage imageKey={enlarge}
                            imageRef={this.imageRef}
                            onClose={() => { this.onSetEnlarge(null) }}>
              <div className="button">
                <button onClick={() => { this.onSaveImage(enlarge) } }>
                  <UiIcon name="save"
                          size="medium" />
                  {t('common.save')}
                </button>
              </div>
            </UiEnlargeImage>
          </div>
        </CSSTransition>

        {showAlert.leave &&
          <UiConfirm content={t('talk.alert.leave')}
                     okayText={t('common.yes')}
                     onOkay={() => { this.confirmExitRoom() }}
                     cancelText={t('common.no')}
                     onCancel={() => { this.exitRoom() }} />
        }
        {showAlert.report &&
          <UiConfirm content={t('talk.alert.report')}
                     okayText={t('common.yes')}
                     onOkay={() => { this.confirmReport() }}
                     cancelText={t('common.no')}
                     onCancel={() => { this.onReport() }} />
        }
        {showAlert.reportComplete &&
          <UiAlert content={t('talk.alert.reportComplete')}
                   onOkay={() => { this.endReport() }} />
        }
        {showAlert.disconnected &&
          <UiConfirm content={t('talk.alert.disconnected')}
                     okayText={t('common.yes')}
                     onOkay={() => { window.location.reload(false) }}
                     cancelText={t('common.no')}
                     onCancel={() => { this.setStateInside('showAlert', 'disconnected', false) }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ChatRoom, props)
export default withRouter(StateContainer)
