import React from 'react'
import Component from '../../../utils/Component'

import './Dropdown.scss'

class UiDropdown extends Component {
  state = {
    transition: -1
  }
  
  onOpen = () => {
    this.setState({
      transition: 1
    })
    setTimeout(() => {
      this.setState({
        transition: 2
      })
    }, 1)
  }

  onTryClose = () => {
    const {
      onTryClose
    } = this.props
    if (onTryClose) {
      onTryClose()
    }
  }

  onClose = () => {
    this.setState({
      transition: 0
    })
    setTimeout(() => {
      this.setState({
        transition: -1
      })
    }, 300)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        this.onOpen()
      } else {
        this.onClose()
      }
    }
  }

  componentDidMount () {
    if (this.props.show) {
      this.onOpen()
    } else {
      this.onClose()
    }
  }

  render() {
    const {
      transition
    } = this.state

    const {
      children
    } = this.props

    return (
      <div className="ui-dropdown">
        {transition > -1 &&
          <div className={"context-wrap" + (transition < 2 ? " context-transition" : '')}>
            <div className="context-dim"
                 onClick={() => this.onTryClose()}> </div>
            <div className="context-layer">
              {children}
            </div>
          </div>
        }
      </div>
    )
  }
}

UiDropdown.defaultProps = {
  show: false,
  onTryClose: null
}

export default UiDropdown
