import httpClient from '../utils/http'

export function signIn (params) {
  const url = '/auth/login'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function sendVerifyEmail (params) {
  const url = '/verify/email/send'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function confirmVerifyEmail (params) {
  const url = '/verify/email/confirm'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function forgotPassword (params) {
  const url = '/auth/forgot_password'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

