import React from 'react'
import Component from '../../utils/Component'

import './Context.scss'

class UiContext extends Component {
  state = {
    transition: -1
  }

  onClick = (item) => {
    if (item.action) {
      item.action()
    }
  }
  
  onOpen = () => {
    this.setState({
      transition: 1
    })
    setTimeout(() => {
      this.setState({
        transition: 2
      })
    }, 1)
  }

  onTryClose = () => {
    const {
      onTryClose
    } = this.props
    if (onTryClose) {
      onTryClose()
    }
  }

  onClose = () => {
    this.setState({
      transition: 0
    })
    setTimeout(() => {
      this.setState({
        transition: -1
      })
    }, 300)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        this.onOpen()
      } else {
        this.onClose()
      }
    }
  }

  componentDidMount () {
    if (this.props.show) {
      this.onOpen()
    } else {
      this.onClose()
    }
  }

  render() {
    const {
      transition
    } = this.state

    const {
      title,
      items
    } = this.props

    const itemsStyle = {
      height: (items.length + (title ? 1 : 0)) * 52 + 'px',
      maxHeight: Math.floor((window.innerHeight * 0.8) / 52) * 52 + 'px'
    }

    return (
      <div className="ui-context">
        {transition > -1 &&
          <div className={"context-wrap" + (transition < 2 ? " context-transition" : '')}>
            <div className="context-dim"
                 onClick={() => this.onTryClose()}> </div>
            <div className="context-layer">
              <ul className="context-items"
                  style={itemsStyle}>
                {title &&
                  <li className="context-title">
                    {title}
                  </li>
                }
                {items.map((item, i) => {
                  return (
                    <li className="context-item"
                        key={`context-items-${i}-${item.text}`}>
                      <button onClick={() => this.onClick(item)}>
                        {item.text}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        }
      </div>
    )
  }
}

UiContext.defaultProps = {
  show: false,
  title: null,
  items: [],
  onTryClose: null
}

export default UiContext
