import React from 'react'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import UiInput from '../../components/ui/Input'
import UiIcon from '../../components/ui/Icon'

import './Select.scss'

class UiSelect extends Component {
  state = {
    keyword: '',
    value: this.props.value,
    transition: -1,
    maxHeight: 0
  }

  onClick = () => {
    const {
      isDisabled,
      onClick
    } = this.props

    if (!isDisabled) {
      this.setState({
        transition: 1
      })
      setTimeout(() => {
        this.setState({
          transition: 2
        })
      }, 1)
      if (onClick) {
        onClick()
      }
    }
  }

  onClose = () => {
    this.setState({
      transition: 0
    })
    setTimeout(() => {
      this.setState({
        transition: -1
      })
    }, 300)
  }

  onChange = (value) => {
    const {
      onChange
    } = this.props

    this.onClose()
    if (onChange) {
      if (value !== this.state.value) {
        onChange(value)
      }
    }
  }

  keywordOnChange = (keyword) => {
    this.setState({
      keyword
    })
  }

  handleResize = () => {
    this.setState({
      maxHeight: Math.max(Math.floor(((window.innerHeight * 0.8) / 52) - 2), 2) * 52
    })
  }

  componentDidMount () {
    if (this.props.showNow) {
      this.onClick()
    }
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate () {
    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
    window.removeEventListener('resize', this.handleResize)
    setTimeout(() => {
      window.addEventListener('resize', this.handleResize)
    }, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const {
      keyword,
      value,
      transition,
      maxHeight
    } = this.state

    const {
      t,
      isDisabled,
      label,
      placeholder,
      search,
      searchValue,
      resize,
      items
    } = this.props

    let className = `ui-select`
    if (isDisabled) {
      className += ` disabled`
    }

    const selected = items.find(item => item.value === value)
    const text = selected ? selected.text : value
    const itemsStyle = {
      height: items.length * 52 + 'px',
      maxHeight: maxHeight + 'px'
    }

    return (
      <>
        <div className={className}
             onClick={() => this.onClick()}>
          {label !== null &&
            <label className="select-label">
              {label}
            </label>
          }

          <div className={`select-wrap
                           ${selected && selected.icon ? ' has-icon' : ''}
                           ${transition > 0 ? ' active' : ''}`}>
            {selected && selected.icon &&
              <span className="selected-icon">
                <UiIcon name={selected.icon} />
              </span>
            }

            <input placeholder={placeholder}
                   value={text}
                   readOnly={true} />

            <UiIcon name="dropdown-arrow" />
          </div>
        </div>

        {transition > -1 &&
          <div className={"select-wrap" + (transition < 2 ? " select-transition" : '')}>
            <div className="select-dim"
                 onClick={() => this.onClose()}> </div>
            <div className="select-layer">
              {search &&
                <div className="select-search">
                  <UiInput type="text"
                           color="fill"
                           placeholder={t('common.search')}
                           icon="search"
                           value={keyword}
                           onChange={value => this.keywordOnChange(value)} />
                </div>
              }
              <ul className="select-items"
                  style={itemsStyle}>
                {items.map((item, i) => {
                  const show = !keyword ||
                    (searchValue && item.value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
                    item.text.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
                    (item.description && item.description.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)

                  if (show) {
                    const hasDescription = !!item.description
                    return (
                      <li className={`select-item
                                    ${selected && selected.value === item.value ? ' selected' : ''}
                                    ${!search && !resize && i === 0 ? ' first-child' : ''}`}
                          key={`select-items-${i}-${item.text}-${item.value}`}>
                        <button className={hasDescription ? 'has-desc' : ''}
                                onClick={() => this.onChange(item.value)}>
                          {item.icon &&
                            <span className="select-item-icon">
                              <UiIcon name={item.icon}></UiIcon>
                            </span>
                          }
                          <span className="select-item-name-desc">
                            <span className="select-item-name">
                              {item.text}
                            </span>
                            {hasDescription &&
                              <span className="select-item-desc">
                                {item.description}
                              </span>
                            }
                          </span>
                        </button>
                      </li>
                    )
                  } else {
                    return (<> </>)
                  }
                })}
              </ul>
            </div>
          </div>
        }
      </>
    )
  }
}

UiSelect.defaultProps = {
  isDisabled: false,
  label: null,
  placeholder: '',
  search: false,
  searchValue: false,
  resize: false,
  value: '',
  items: [],
  onClick: null,
  onChange: null
}

const StateContainer = (props) => StateConsumer(UiSelect, props)
export default StateContainer
