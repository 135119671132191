import { withRouter } from 'react-router-dom'
import Component from '../../utils/Component'
import { StateConsumer } from '../../context/StateProvider'
import { isPassword } from '../../utils/tool'
import { getVerifyToken, hashPassword, removeVerifyToken } from '../../utils/auth'
import { forgotPassword } from '../../api/auth'

import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiIcon from '../../components/ui/Icon'
import UiAlert from '../../components/ui/layer/Alert'

import EmailVerification from './EmailVerification'

import './ForgotPassword.scss'

class ForgotPassword extends Component {
  state = {
    step: 1,
    showAlert: {
      success: false
    },
    value: {
      email: '',
      password: '',
      passwordConfirm: ''
    },
    error: {
      email: null,
      password: null,
      passwordConfirm: null
    },
    description: {
      password: null,
      passwordConfirm: null
    }
  }

  step1OnSubmit = (value) => {
    const {
      action
    } = this.props

    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => {
                this.setStateInside('value', 'email', '')
                this.setState({ step: 1 })
              }} />
    )
    this.setStateInside('value', 'email', value)
    this.setState({ step: 2 })
  }

  step2OnSubmit = () => {
    const {
      password
    } = this.state.value

    forgotPassword({
      token: getVerifyToken(),
      password: hashPassword(password)
    }).then(response => {
      if(response.success) {
        removeVerifyToken()
        this.toggleAlert('success')
      }
    }).catch(() => {
      this.toggleAlert('unknown')
    })
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  inputPasswordOnChange = (value) => {
    const {
      t
    } = this.props

    const {
      passwordConfirm
    } = this.state.value

    this.inputOnChange('password', value)
    if (value) {
      if (!isPassword(value)) {
        this.setStateInside('error', 'password', t('signUp.error.passwordLimit'))
      } else {
        this.setStateInside('description', 'password', t('signUp.error.canUsePassword'))
      }
    } else {
      this.setStateInside('description', 'password', null)
    }
    this.inputPasswordConfirmOnChange(passwordConfirm, value)
  }

  inputPasswordConfirmOnChange = (value, pw) => {
    const {
      t
    } = this.props

    const {
      password
    } = this.state.value

    const currentPassword = pw || password
    this.inputOnChange('passwordConfirm', value)
    if (value && value === currentPassword) {
      this.setStateInside('description', 'passwordConfirm', t('signUp.error.passwordMatch'))
    } else {
      this.setStateInside('description', 'passwordConfirm', null)
    }
  }

  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
  }

  componentDidMount() {
    const {
      t,
      action,
      history
    } = this.props

    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)
    action.setNavbarTitle(t('signUp.header.email'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/signin') }} />
    )
    action.setNavbarButtons(null)
  }

  render() {
    const {
      step,
      showAlert,
      value,
      error,
      description
    } = this.state

    const {
      t,
      history
    } = this.props

    const isStep2SubmitDisabled = () => {
      return !description.password || !description.passwordConfirm
    }

    return (
      <div className="forgot-password">
        {step === 1 &&
          <EmailVerification isSignUp={false}
                             onSuccess={val => this.step1OnSubmit(val)} />
        }

        {step === 2 &&
          <div className="step-2">
            <UiInput type="password"
                     placeholder={t('forgetPassword.placeholder.password')}
                     label={t('common.newPassword')}
                     value={value.password}
                     error={error.password}
                     description={description.password}
                     onChange={val => this.inputPasswordOnChange(val)} />

            <UiInput type="password"
                     placeholder={t('forgetPassword.placeholder.password')}
                     value={value.passwordConfirm}
                     error={error.passwordConfirm}
                     description={description.passwordConfirm}
                     onChange={val => this.inputPasswordConfirmOnChange(val)} />

            <UiButton text={t('common.complete')}
                      isDisabled={isStep2SubmitDisabled()}
                      isFixed={true}
                      onClick={this.step2OnSubmit} />
          </div>
        }

        {showAlert.success &&
          <UiAlert content={t('common.complete')}
                        preventClose={true}
                        onOkay={() => { history.push('/signin') }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ForgotPassword, props)
export default withRouter(StateContainer)
