import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import { getS3Url, timeString, historyPushState, bodyScroll } from '../../utils/tool'
import Component from '../../utils/Component'

import { roomList, roomDirectRequest } from '../../api/talk'

import ChatRoom from '../talk/ChatRoom'
import ChatRequest from '../talk/ChatRequest'

import defaultBlankImage from '../../assets/images/asset--no-image.svg'
import UiIcon from '../../components/ui/Icon'
import UiButton from '../../components/ui/Button'
import UiCountry from '../../components/ui/Country'
import UiAlert from '../../components/ui/layer/Alert'

import './ChatList.scss'

class ChatList extends Component {
  state = {
    loading: false,
    groupList: [],
    directList: [],
    room: null,
    roomIdList: [],
    showRoom: 0,
    newRoom: null,
    showAlert: {
      direct: false
    }
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  loadList = (loading = true) => {
    if (loading) {
      this.setState({ loading: true })
    }
    return new Promise((resolve, reject) => {
      this.leaveList()
      roomList().then(result => {
        const groupList = [...result.data.groupList]
        const directList = [...result.data.directList]
        const roomIdList = groupList.map(room => room.id).concat(directList.map(room => room.id))

        this.setState({
          loading: false,
          groupList,
          directList,
          roomIdList
        })
        this.enterList()
        resolve()
      }).catch(error => {
        console.error(error)
        reject()
      })
    })
  }

  onClickRoom = (room, detect = false) => {
    if (room.type !== 'group' || room.enteredAt) {
      this.openRoom(room, detect)
    } else {
      this.setState({
        newRoom: room
      })
    }
  }

  onCloseNewRoom = () => {
    this.setState({
      newRoom: null
    })
  }

  openRoom = (room, detect = false) => {
    this.onCloseNewRoom()
    this.setRoomTitle()
    this.setState({
      room: room,
      showRoom: 1
    })
    let url = window.location.pathname + '?r=' + room.id
    if (!detect) {
      url += '&d=1'
    }
    url += '#r'
    if (window.history.length < 2) {
      historyPushState(window.location.pathname)
      setTimeout(() => {
        historyPushState(url, { room: room.id })
      }, 500)
    } else {
      historyPushState(url, { room: room.id })
    }

    bodyScroll(true)
  }

  afterRename = (roomId, renameValue) => {
    const {
      room
    } = this.state

    room.name = renameValue

    this.setState({
      room
    })
  }

  closeRoom = (room) => {
    const {
      ws
    } = this.props.state

    if (ws && room) {
      ws.emit('outRoom', { room: room.id })
    }

    this.setInitialTitle()
    this.setState({
      room: null,
      showRoom: -1
    })
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search)
      if (params.get('d')) {
        window.history.back()
      } else {
        historyPushState(window.location.pathname)
      }
    }

    bodyScroll(false)

    return this.loadList()
  }

  detectRoom = () => {
    const {
      showRoom
    } = this.state

    const params = new URLSearchParams(window.location.search)
    if (params) {
      const roomId = params.get('r')
      if (roomId) {
        if (showRoom <= 0) {
          return roomId
        } else {
          return false
        }
      }
    }
  }

  detectMoveRoom = (roomId) => {
    const {
      groupList,
      directList
    } = this.state

    for (const room of groupList.concat(directList)) {
      if (room.id === roomId) {
        this.onClickRoom(room, true)
      }
    }
  }

  setInitialTitle = () => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('talk.title'))
    action.setNavbarBackbutton(null)
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(true)
  }

  setRoomTitle = () => {
    const {
      action
    } = this.props

    action.setLayoutShowHeader(false)
    action.setLayoutShowFooter(false)
  }

  enterList = () => {
    const {
      roomIdList,
      groupList,
      directList
    } = this.state

    const {
      ws
    } = this.props.state

    const {
      t
    } = this.props

    const renewLastMessage = (room, message) => {
      if (room.id === message.room) {
        if (message.type === 'system') {
          if (message.message.type === 'newUser') {
            room.lastMessage = t('talk.message.entered', { user: message.message.userName })
          } else if (message.message.type === 'leaveUser') {
            room.lastMessage = t('talk.message.leaved', { user: message.message.userName })
          } else if (message.message.type === 'warnDisabledDueToDate') {
            room.lastMessage = t('talk.message.warnDisabledDueToDate')
          } else if (message.message.type === 'disabledDueToDate') {
            room.lastMessage = t('talk.message.disabledDueToDate')
          }
        } else if (message.type === 'image') {
          room.lastMessage = t('talk.message.image')
          room.unreadCount++
        } else {
          room.lastMessage = message.summary
          room.unreadCount++
        }
        room.lastMessageAt = new Date()
      }
    }

    ws.emit('enterList', { rooms: roomIdList })
    ws.on('receiveChatRoomList', message => {
      for (const room of groupList) {
        renewLastMessage(room, message)
      }
      for (const room of directList) {
        renewLastMessage(room, message)
      }
      this.setState({
        groupList,
        directList
      })
    })
    ws.on('chatNotification', message => {
      this.checkNotification(message)
    })
  }

  checkNotiNow () {
    const {
      ws
    } = this.props.state

    ws.emit('checkNoti', {})
  }

  checkNotification (noti) {
    const {
      groupList,
      directList
    } = this.state

    const countRoom = groupList.length + directList.length

    if (countRoom !== noti.countRoom) {
      this.loadList()
    }
  }

  openDirect (id, retry = false) {
    this.closeRoom().then(() => {
      const {
        directList
      } = this.state

      let directRoom = null
      for (const room of directList) {
        for (const member of room.members) {
          if (!member.deleted && member.id === id) {
            directRoom = room
          }
        }
      }

      if (directRoom) {
        this.openRoom(directRoom)
      } else {
        if (!retry) {
          roomDirectRequest({id}).then(response => {
            if (response.data.success) {
              this.openDirect(id, true)
            } else {
              this.toggleAlert('direct')
            }
          }).catch(error => {
            console.error(error)
          })
        }
      }
    }).catch(error => {
      console.error(error)
    })
  }

  leaveList () {
    const {
      roomIdList
    } = this.state

    const {
      ws
    } = this.props.state

    if (ws) {
      ws.emit('leaveList', { rooms: roomIdList })
      ws.off('receiveChatRoomList')
      ws.off('chatNotification')
    }
  }

  componentDidUpdate (prevProps) {
    const {
      location,
      history
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      if (location.state && location.state.action === 'direct') {
        if (!prevProps.location.state || location.state.id !== prevProps.location.state.id) {
          this.openDirect(location.state.id)
          history.push({
            pathname: location.pathname
          })
          delete location.state
        }
      }
    }
  }

  componentDidMount () {
    const {
      location,
      history
    } = this.props

    const {
      userData
    } = this.props.state

    this.setInitialTitle()

    if (userData) {
      const roomId = this.detectRoom()

      if (roomId) {
        this.loadList().then(() => {
          this.detectMoveRoom(roomId)
        }).catch(err => console.error(err))
      } else {
        this.loadList()

        if (location.state && location.state.action === 'direct') {
          if (location.state.id) {
            this.openDirect(location.state.id)
            history.push({
              pathname: location.pathname
            })
          }
        }

        if (!location.state) {
          history.push({
            pathname: location.pathname
          })
        }
      }

      window.onpopstate = (event) => {
        if (!window.location.hash) {
          const {
            showRoom
          } = this.state

          const roomId = this.detectRoom()

          if (roomId) {
            this.detectMoveRoom(roomId)
          } else {
            if (showRoom > 0) {
              this.closeRoom()
            }
          }
        }
      }
    }
  }

  componentWillUnmount () {
    const {
      userData
    } = this.props.state

    if (userData) {
      this.leaveList()
    }
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefGroupList = createRef(null)
    const nodeRefDirectList = createRef(null)
    const nodeRefRoom = createRef(null)
    const nodeRefNewRoomDim = createRef(null)
    const nodeRefNewRoom = createRef(null)

    const {
      loading,
      groupList,
      directList,
      room,
      showRoom,
      newRoom,
      showAlert
    } = this.state

    const {
      t
    } = this.props

    const {
      userData
    } = this.props.state

    const sortedGroupList = groupList.sort((a, b) => new Date(a.lastMessageAt || a.createdAt) < new Date(b.lastMessageAt || b.createdAt) ? 1 : -1)
    const sortedDirectList = directList.sort((a, b) => new Date(a.lastMessageAt || a.createdAt) < new Date(b.lastMessageAt || b.createdAt) ? 1 : -1)

    const roomItem = (room, i, type) => {
      let idx = 0

      const blocked = userData ? (type === 'direct' && userData.blockList && room.members[0] && userData.blockList.includes(room.members[0].id)) : false

      return (
        <li className="room-item"
            key={`toChat${room.id}-${i}`}>
          <button onClick={() => { this.onClickRoom(room) }}>
            <span className="room-button">
              <span className={`room-members count${Math.min(4, room.memberCount)}`}>
                {room.members.map((user) => {
                  if (idx > 4 || user.deleted) {
                    return <span key={`room-${room.id}-user-${user.userId}`} />
                  }
                  idx++

                  const blocked = userData ? (userData.blockList && user && userData.blockList.includes(user.id)) : false
                  return (
                    <span key={`room-${room.id}-user-${user.userId}`}
                          className="user-profile"
                          style={{ backgroundImage: `url('${!blocked && user.profileImage ? getS3Url(user.profileImage) : defaultBlankImage}')` }} />
                  )
                })}
                {idx < 1 &&
                  <span key={`room-${room.id}-user`}
                        className="user-profile"
                        style={{ backgroundImage: `url('${defaultBlankImage}')` }} />
                }
              </span>
              <span className="room-info">
                {type === 'direct' &&
                    <h4>
                      {room.members[0].nickname}
                    </h4>
                }
                {type === 'group' &&
                  <h4>
                    {room.name}
                    {room.memberCount > 1 &&
                      <em>{room.memberCount}</em>
                    }
                  </h4>
                }
                {blocked &&
                  <p className="blocked">
                    {t('community.blocked')}
                  </p>
                }
                {(!blocked && !room.enteredAt) &&
                  <p>
                    <span className="new">{t('talk.new')}</span>
                  </p>
                }
                {(!blocked && room.enteredAt) &&
                  <>
                    {typeof room.lastMessage === 'object' &&
                      <>
                        {room.lastMessage.type === 'newUser' &&
                          <p>{t('talk.message.entered', { user: room.lastMessage.userName })}</p>
                        }
                        {room.lastMessage.type === 'leaveUser' &&
                          <p>{t('talk.message.leaved', { user: room.lastMessage.userName })}</p>
                        }
                        {room.lastMessage.type === 'warnDisabledDueToDate' &&
                          <p>{t('talk.message.warnDisabledDueToDate').replace('<br />', ' ')}</p>
                        }
                        {room.lastMessage.type === 'disabledDueToDate' &&
                          <p>{t('talk.message.disabledDueToDate').replace('<br />', ' ')}</p>
                        }
                        {room.lastMessage.type === 'image' &&
                          <p>{t('talk.message.image')}</p>
                        }
                      </>
                    }
                    {typeof room.lastMessage !== 'object' &&
                      <p>{room.lastMessage}</p>
                    }
                  </>
                }
              </span>
              <span className="room-meta">
                <span className="time">
                  {timeString(room.lastMessageAt || room.createdAt, t)}
                </span>
                {room.unreadCount > 0 &&
                  <span className="unread">
                    {room.unreadCount}
                  </span>
                }
              </span>
            </span>
          </button>
        </li>
      )
    }

    return(
      <div className="layout-container">
        <div className="talk-room-list">
          <CSSTransition in={loading}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefLoader}
                         classNames="fade">
            <div className="data-loader"
                 ref={nodeRefLoader}>
              <UiIcon name="loader" />
            </div>
          </CSSTransition>

          <div className="chat-list chat-list-group">
            <h3>{t('talk.groupChatting')}</h3>

            <ChatRequest key={groupList.length}
                         roomCount={groupList.length}
                         afterRequest={() => { this.checkNotiNow() }} />

            <CSSTransition in={groupList.length > 0}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefGroupList}
                           classNames="fade">
              <ul ref={nodeRefGroupList}>
                {sortedGroupList.map((room, i) => {
                  return roomItem(room, i, 'group')
                })}
              </ul>
            </CSSTransition>
          </div>

          <CSSTransition in={directList.length > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefDirectList}
                         classNames="fade">
            <div className="chat-list chat-list-direct"
                 ref={nodeRefDirectList}>
              <h3>{t('talk.directChatting')}</h3>
              <ul>
                {sortedDirectList.map((room, i) => {
                  return roomItem(room, i, 'direct')
                })}
              </ul>
            </div>
          </CSSTransition>

          <CSSTransition in={showRoom > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefRoom}
                         classNames="page-slide">
            <div className={`talk-room-wrap ${showRoom < 0 ? 'backward' : ''}`}
                 ref={nodeRefRoom}>
              {room &&
                <ChatRoom room={room}
                          afterRename={this.afterRename}
                          onClose={this.closeRoom} />
              }
            </div>
          </CSSTransition>
        </div>

        <CSSTransition in={!!newRoom}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefNewRoomDim}
                       classNames="fade">
          <div className="new-room-dim"
               ref={nodeRefNewRoomDim}
               onClick={this.onCloseNewRoom}> </div>
        </CSSTransition>

        <CSSTransition in={!!newRoom}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefNewRoom}
                       classNames="select">
          <div className="new-room-description"
               ref={nodeRefNewRoom}>
            {!!newRoom &&
              <>
                <h2 className="desc-title">
                  {newRoom.name}
                </h2>
                <div className="desc-description">
                  <div dangerouslySetInnerHTML={{ __html: t('talk.newRoom.description') }} />
                </div>
                <div className="desc-members">
                  {newRoom.members.map(user => {
                    return (
                      <div key={`new-room-${newRoom.id}-user-${user.userId}`}
                           className="user-profile">
                        <div className="profile-image"
                              style={{ backgroundImage: `url('${user.profileImage ? getS3Url(user.profileImage) : defaultBlankImage}')` }} />
                        <UiCountry code={user.country}
                                   onlyFlag={true}  />
                        <div className="profile-name">
                          {user.nickname}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="desc-button">
                  <UiButton text={t('talk.newRoom.enter')}
                            onClick={() => { this.openRoom(newRoom) }} />
                </div>
              </>
              }
          </div>
        </CSSTransition>

        {showAlert.direct &&
          <UiAlert content={t('talk.alert.noDirect')}
                   onOkay={() => { this.toggleAlert('direct') }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ChatList, props)
export default withRouter(StateContainer)
