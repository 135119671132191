const language = {
  // 'ar': { name: 'اللغة العربية', description: 'Arabic' },
  'zh-CN': { name: '中文 (简体)', description: 'Chinese (Simplified)' },
  'en-US': { name: 'English', description: 'English' },
  // 'hi-IN': { name: 'हिन्दी', description: 'Hindi' },
  // 'id-ID': { name: 'Bahasa Indonesia', description: 'Indonesian' },
  'ja-JP': { name: '日本語', description: 'Japanese' },
  'ko-KR': { name: '한국어', description: 'Korean' },
  // 'pl-PL': { name: 'język polski', description: 'Polish' },
  // 'pt-PT': { name: 'português', description: 'Portuguese' },
  // 'ru-RU': { name: 'ру́сский язы́к', description: 'Russian' },
  // 'es-ES': { name: 'Idioma español', description: 'Spanish' },
  // 'th-TH': { name: 'ภาษาไทย', description: 'Thai' },
  // 'tr-TR': { name: 'Türkçe', description: 'Turkish' },
  // 'vi-VN': { name: 'tiếng Việt', description: 'Vietnamese' }
}

export default language
