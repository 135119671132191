import Component from '../../utils/Component';

import UiIcon from './Icon';

import './Country.scss';

class Country extends Component {
  render() {
    const {
      code,
      onlyFlag
    } = this.props;

    return (
      <div className={`ui-country ${onlyFlag ? 'only-flag' : ''}`}>
        <UiIcon name={`flag ${code}`} />
        {!onlyFlag &&
          <>{code ? code.toUpperCase() : 'N/A'}</>
        }
      </div>
    )
  }
}

export default Country;