import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import Swipe from 'react-easy-swipe'
import { getS3Url, timeString, addListener, removeListener, historyPushState, bodyScroll } from '../../utils/tool'

import { bannerList, communityArticle } from '../../api/home'
import { boardPostListAll, boardPostInfo, boardPostPickList, boardPostPopularList } from '../../api/community'
import { roomRequestInfo } from '../../api/talk'

import BoardPostInfo from '../../pages/community/BoardPostInfo'

import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiCountry from '../../components/ui/Country'

import './Main.scss'
import {getAuthToken} from "../../utils/auth";

class Home extends Component {
  nodeRefBanner = createRef()

  state = {
    loading: false,
    post: null,
    showSearch: 0,
    showRead: 0,
    error: {
      title: null,
      content: null,
      files: null
    },
    searchList: [],
    keyword: '',
    searchListOption: {},
    initialListOption: {
      lastDate: null,
      page: 0,
      count: 10
    },
    noMoreSearchList: false,
    bannerList: [],
    communityList: [],
    pickList: [],
    popularList: [],
    bannerIndex: 0,
    talkRequesting: false
  }

  loadBanner = () => {
    this.setState({ loading: true })
    bannerList({
      type: 'home'
    }).then(response => {
      this.setState({
        bannerList: response.data.list
      })
      this.bannerTimer = setInterval(() => {
        this.nextBanner()
      }, 10000)
    }).catch(error => {
      console.error(error)
    })
  }

  loadCommunity = () => {
    this.setState({ loading: true })
    communityArticle().then(response => {
      this.setState({
        communityList: response.data
      })
      this.setState({ loading: false })
    }).catch(error => {
      console.error(error)
      this.setState({ loading: false })
    })
  }

  loadPick = () => {
    boardPostPickList().then(response => {
      this.setState({
        pickList: response.data.list
      })
    }).catch(error => {
      console.error(error)
    })
  }

  loadPopular = () => {
    boardPostPopularList().then(response => {
      this.setState({
        popularList: response.data.list
      })
    }).catch(error => {
      console.error(error)
    })
  }

  loadTalkStatus = () => {
    const {
      userData
    } = this.props.state

    if (userData) {
      roomRequestInfo().then(result => {
        this.setState({
          talkRequesting: result.data.requesting
        })
      }).catch(error => {
        console.error(error)
      })
    }
  }

  nextBanner = (index) => {
    const {
      bannerList,
      bannerIndex
    } = this.state

    if (!index) {
      index = (bannerIndex + 1) % bannerList.length
    }

    this.setState({ bannerIndex: index })
  }

  onBannerSwipeStart = () => {
    this.setState({ bannerSwiping: 0 })
  }

  onBannerSwipeMove = (position) => {
    const abs = Math.abs(position.x) > 10
    this.setState({ bannerSwiping: position.x, isBannerSwiping: abs })

    return abs
  }

  onBannerSwipeEnd = () => {
    const {
      bannerList,
      bannerIndex,
      bannerSwiping
    } = this.state

    const containerWidth = this.nodeRefBanner.current.offsetWidth
    const swipingDirection = bannerSwiping > 0 ? 1: -1
    let index = Math.min(Math.max(0, Math.round((-bannerSwiping - swipingDirection * (containerWidth / 2.5)) / containerWidth) + bannerIndex), bannerList.length - 1)

    this.setState({
      bannerIndex: index,
      bannerSwiping: 0
    })

    setTimeout(() => {
      this.setState({ isBannerSwiping: false })
    }, 0)
  }

  onClickBanner = (banner) => {
    const {
      history
    } = this.props

    const {
      isBannerSwiping
    } = this.state

    if (!isBannerSwiping) {
      if (banner.meta.link && banner.meta.link.indexOf('http') !== 0) {
        history.push(banner.meta.link)
      }
    }

    return false
  }

  onClickBoard = (board) => {
    const {
      action,
      history
    } = this.props

    action.setNavbarTitle(board.title)
    history.push('/c/' + board.id)
  }

  keywordOnChange = (keyword) => {
    const { keywordTimer } = this.state
    clearTimeout(keywordTimer)
    this.setState({
      loading: true,
      keyword,
      keywordTimer: setTimeout(() => {
        this.onSearch()
      }, 1500)
    })
  }

  onSearch = () => {
    const {
      keyword,
      initialListOption
    } = this.state

    if (keyword.trim()) {
      this.setState({
        searchListOption: initialListOption,
        noMoreSearchList: false
      })

      removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)

      boardPostListAll({
        keyword,
        ...initialListOption
      }).then(result => {
        const list = result.data.list
        this.setState({
          loading: false,
          searchList: list,
          searchListOption: {
            ...initialListOption,
            lastDate: list.length ? list[list.length - 1].createdAt : false
          },
          noMoreSearchList: list.length < initialListOption.count
        })

        addListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)
      }).catch(error => {
        console.error(error)
      })
    } else {
      this.setState({
        loading: false,
        searchList: []
      })
    }
  }

  loadNextSearchPage = (loading = true) => {
    const {
      keyword,
      searchList,
      searchListOption
    } = this.state

    const newListOption = {
      ...searchListOption,
      page: searchListOption.page + 1
    }

    this.setState({
      searchListOption: newListOption
    })

    if (loading) {
      this.setState({ loading: true })
    }
    boardPostListAll({
      keyword,
      ...newListOption
    }).then(result => {
      const nextList = result.data.list
      const newList = searchList.concat(nextList)
      this.setState({
        loading: false,
        searchList: newList,
        searchListOption: {
          ...newListOption,
          lastDate: newList.length ? newList[newList.length - 1].createdAt : false
        },
        noMoreSearchList: nextList.length < searchListOption.count
      })
    }).catch(error => {
      console.error(error)
    })
  }

  onClickPost = (post) => {
    if (!post.reported) {
      this.openRead(post)
    }
  }

  toTalk = () => {
    const {
      history
    } = this.props

    history.push({
      pathname: '/t/r',
      state: { action: 'request' }
    })
  }

  openNotification = () => {
    const {
      action,
      history
    } = this.props

    action.setNotificationCount(0)

    const authToken = getAuthToken()
    if (authToken) {
      history.push('/noti')
    } else {
      this.toggleLogin()
    }
  }

  openSearch = () => {
    this.setState({
      showSearch: 1,
      searchList: [],
      keyword: ''
    })

    this.setSearchTitle()

    bodyScroll(true)
  }

  closeSearch = () => {
    this.setInitialTitle(true)
    this.setState({
      showSearch: -1
    })

    bodyScroll(false)
  }

  openRead = (post) => {
    const {
      action
    } = this.props

    this.setState({
      post: null,
      loading: true
    })

    setTimeout(() => {
      action.setNavbarBackbutton(
        <UiIcon name="back"
                onClick={() => { this.closeRead() }}/>
      )
    }, 0)

    boardPostInfo({
      id: post.id
    }).then(response => {
      this.setState({
        post: {
          ...response.data,
          board: post.board
        },
        loading: false,
        showRead: 1
      })

      historyPushState(window.location.pathname + '?p=' + post.id, { post: post.id })
      action.setNavbarTitle('')

      bodyScroll(true)
    }).catch(error => {
      console.error(error)
    })
  }

  closeRead = () => {
    const {
      showSearch
    } = this.state

    if (showSearch > 0) {
      this.setSearchTitle(true)
    } else {
      this.setInitialTitle(true)
    }
    this.setState({
      showRead: -1
    })
    if (window.location.search) {
      historyPushState()
    }

    bodyScroll(false)
  }

  detectPost = () => {
    const {
      showRead
    } = this.state

    const params = new URLSearchParams(window.location.search)
    if (params) {
      const postId = params.get('p')
      if (postId) {
        if (showRead <= 0) {
          this.openRead({ id: postId })
        }
        return true
      } else {
        if (showRead > 0) {
          this.closeRead()
        }
        return false
      }
    }
  }

  updatePostLike = (postMatch, liked, countLike) => {
    const {
      searchList,
      pickList,
      popularList
    } = this.state

    const list = searchList.concat(pickList).concat(popularList)

    for (let i = 0; i < list.length; i ++) {
      const post = list[i]
      if (post.id === postMatch.id) {
        post.liked = liked
        post.countLike = countLike
        break
      }
    }
  }

  updatePostInfo = (post) => {
    this.openRead(post)
    this.updatePostList()
  }

  updatePostList = () => {
    const {
      showSearch
    } = this.state

    this.closeRead()

    if (showSearch) {
      this.onSearch()
    } else {
      this.loadPick()
      this.loadPopular()
    }
  }

  setSearchTitle = (backward = false) => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('common.search'), backward)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.closeSearch() }} />
    )
    action.setNavbarButtons(null)
    action.setLayoutShowFooter(false)
  }

  setInitialTitle = () => {
    const {
      action
    } = this.props

    action.setNavbarTitle(
      <UiIcon name="logo-type-blue" />
    )
    action.setNavbarBackbutton(null)
    action.setNavbarButtons()
    setTimeout(() => {
      const {
        notificationCount
      } = this.props.state

      action.setNavbarButtons(
        <>
          <UiIcon name="search"
                  onClick={() => { this.openSearch() }} />
          <UiIcon name="ring gray"
                  badge={notificationCount}
                  onClick={() => { this.openNotification() }} />
        </>
      )
    }, 500)
    action.setLayoutShowFooter(true)
  }

  trackSearchListScrolling = () => {
    const {
      loading,
      noMoreSearchList
    } = this.state
    if (noMoreSearchList) {
      document.getElementById('searchContainer').removeEventListener('scroll', this.trackSearchListScrolling)
    }
    const el = document.getElementById('boardSearchList');
    if (!noMoreSearchList && !loading) {
      if (el.getBoundingClientRect().bottom < window.innerHeight + 500) {
        this.setState({ loading: true })
        this.loadNextSearchPage()
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.state.userData !== this.props.state.userData) {
      this.loadTalkStatus()
    }
  }

  componentDidMount () {
    const {
      action
    } = this.props

    this.setInitialTitle()
    this.loadBanner()
    this.loadCommunity()
    this.loadPick()
    this.loadPopular()
    this.loadTalkStatus()

    action.setLayoutShowHeader(true)

    window.addEventListener('popstate', this.detectPost)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectPost)
    removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)

    clearInterval(this.bannerTimer)
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefCommunity = createRef(null)
    const nodeRefPick = createRef(null)
    const nodeRefPopular = createRef(null)
    const nodeRefRead = createRef(null)
    const nodeRefSearchList = createRef(null)
    const nodeRefSearchListLoading = createRef(null)
    const nodeRefSearch = createRef(null)

    const {
      loading,
      post,
      showRead,
      showSearch,
      searchList,
      keyword,
      bannerList,
      communityList,
      pickList,
      popularList,
      bannerIndex,
      talkRequesting
    } = this.state

    const {
      userData
    } = this.props.state

    const {
      t
    } = this.props

    const postItem = (post, i) => {
      const blocked = userData ? (userData.blockList && userData.blockList.includes(post.author.id)) : false

      return (
        <li key={`list-button-${i}`}>
          {blocked &&
            <button>
                <span className="author blocked">
                  {t('community.blocked')}
                </span>
              <span className="meta">
                  <span className="date">
                    {timeString(post.createdAt, t)}
                  </span>
                </span>
            </button>
          }
          {!blocked &&
            <button onClick={() => { this.onClickPost(post) }}>
              {post.author &&
                <span className="author">
                  <UiCountry code={post.country} />
                  {post.author.nickname}
                </span>
              }
              {!post.reported &&
                <>
                      <span className={`content ${post.thumbnail ? 'thumb' : ''}`}>
                        <span className="title">
                          {post.title}
                        </span>
                        <span className="summary">
                          {post.summary}
                        </span>
                      </span>
                  {post.thumbnail &&
                    <span className="thumbnail"
                          style={{ backgroundImage: `url('${getS3Url(post.thumbnail)}')` }}> </span>
                  }
                </>
              }
              {post.reported &&
                <span className="content reported">
                  <span className="summary">
                    {t('community.reported.post')}
                  </span>
                </span>
              }
              <span className="meta">
                  {!post.reported &&
                    <span className="count">
                      <span className="like">
                        <UiIcon name={`post-like ${post.liked ? 'active' : ''}`} />
                        {post.meta.countLike}
                      </span>
                      <span className="comment">
                        <UiIcon name={`post-comment ${post.commented ? 'active' : ''}`} />
                        {post.meta.countComment}
                      </span>
                    </span>
                  }
                  <span className="date">
                    {post.board.title}
                    <span className="gap" />
                    {timeString(post.createdAt, t)}
                  </span>
                </span>
            </button>
          }
        </li>
      )
    }

    const pickItem = (post, i) => {
      const blocked = userData ? (userData.blockList && userData.blockList.includes(post.author.id)) : false

      return (
        <li key={`list-button-${i}`}>
          <button onClick={() => { this.onClickPost(post) }}>
            {(!blocked && !post.reported) &&
              <>
                <span className="thumbnail"
                      style={post.thumbnail ? { backgroundImage: `url('${getS3Url(post.thumbnail)}')` } : {}} />
                <span className="meta">
                  <span className="board">
                    {post.board.title}
                  </span>
                  <span className="title">
                    {post.title}
                  </span>
                </span>
              </>
            }
            {(blocked || post.reported) &&
              <span className="reported">
                {blocked &&
                  <>{t('community.blocked')}</>
                }
                {(!blocked && post.reported) &&
                  <>{t('community.reported.post')}</>
                }
              </span>
            }
          </button>
        </li>
      )
    }

    return(
      <div className="nearcle-home">
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        <CSSTransition in={!!bannerList && !!bannerList.length}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={this.nodeRefBanner}
                       classNames="fade">
          <div className="banner-carousel"
               ref={this.nodeRefBanner}>
            {bannerList &&
              <Swipe className="banners"
                     onSwipeStart={this.onBannerSwipeStart}
                     onSwipeMove={this.onBannerSwipeMove}
                     onSwipeEnd={this.onBannerSwipeEnd}
                     tolerance={50}
                     allowMouseEvents>
                <div className="layout-container">
                  <div className="carousel-container">
                    <ul style={{ left: `calc(-${bannerIndex * 100}% - ${(bannerIndex + 0.5) * 12}px)` }}>
                      {bannerList.map((banner, i) => {
                        return (<li key={`banner-${i}`}>
                            <a style={{ backgroundImage: `url('${getS3Url(banner.meta.image)}')` }} // eslint-disable-line react/jsx-no-target-blank, jsx-a11y/anchor-is-valid
                               onClick={() => this.onClickBanner(banner)}
                               href={banner.meta.link && banner.meta.link.indexOf('http') === 0 ? banner.meta.link : null}
                               target={banner.meta.link && banner.meta.link.indexOf('http') === 0 ? '_blank' : null}
                               rel={banner.meta.link && banner.meta.link.indexOf('http') === 0 ? 'noreferrer' : null}>
                              <span className="title">
                                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: banner.title }} />
                              </span>
                              <span className="content">
                                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: banner.content }} />
                              </span>
                            </a>
                          </li>)
                      })}
                    </ul>
                  </div>
                  <div className="carousel-navigation">
                    {bannerList.map((banner, i) => {
                      return (
                        <button key={`banner-bullet-${i}`}
                                className={`bullet ${bannerIndex === i ? 'active' : ''}`}
                                onClick={() => { this.nextBanner(i) }} />
                      )
                    })}
                  </div>
                </div>
              </Swipe>
            }
          </div>
        </CSSTransition>

        <div className="talk-list">
          <div className="layout-container">
            <h4>{t('talk.groupChatting')}</h4>
            <div className="talk-matching-button">
              {!talkRequesting &&
                <button onClick={() => { this.toTalk() }}>{t('talk.request.button.request')}</button>
              }
              {talkRequesting &&
                <button onClick={() => { this.toTalk() }} className="ing">{t('talk.request.button.requesting')}</button>
              }
            </div>
          </div>
        </div>

        <CSSTransition in={!!communityList && !!communityList.length}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefCommunity}
                       classNames="fade">
          <div className="community-list"
               ref={nodeRefCommunity}>
            <div className="layout-container">
              <h4>{t('community.title')}</h4>
              <ul>
                {communityList && communityList.map((board, i) => {
                  return (
                    <li key={`list-button-${i}`}>
                      <button onClick={() => { this.onClickBoard(board) }}>
                        <span className="board">
                          {board.title}
                        </span><span className="post">
                          {board.post}
                        </span>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={!!pickList && !!pickList.length}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefPick}
                       classNames="fade">
          <div className="community-list"
               ref={nodeRefPick}>
            <div className="layout-container">
              <h4>{t('main.pick')}</h4>
              <ul className="pick-list"
                  ref={nodeRefSearchList}>
                {pickList.map((post, i) => {
                  return pickItem(post, i)
                })}
              </ul>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={!!popularList && !!popularList.length}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefPopular}
                       classNames="fade">
          <div className="community-list"
               ref={nodeRefPopular}>
            <div className="layout-container">
              <h4>{t('main.popular')}</h4>
              <ul className="board-list"
                  ref={nodeRefSearchList}>
                {popularList.map((post, i) => {
                  return postItem(post, i)
                })}
              </ul>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={showSearch > 0}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefSearch}
                       classNames="page-slide">
          <div className={`search-container ${showSearch < 0 ? 'backward' : ''}`}
               id="searchContainer"
               ref={nodeRefSearch}>
            <div className="layout-container">
              <div className="search">
                <UiInput type="text"
                         color="fill"
                         placeholder={t('common.search')}
                         icon="search"
                         value={keyword}
                         onChange={value => this.keywordOnChange(value)} />
              </div>
              <CSSTransition in={searchList.length > 0}
                             timeout={500}
                             mountOnEnter={true}
                             unmountOnExit={true}
                             nodeRef={nodeRefSearchList}
                             classNames="fade">
                <ul className="board-list"
                    id="boardSearchList"
                    ref={nodeRefSearchList}>
                  {searchList.map((post, i) => {
                    return postItem(post, i)
                  })}
                </ul>
              </CSSTransition>
              <CSSTransition in={searchList.length < 1}
                             timeout={500}
                             mountOnEnter={true}
                             unmountOnExit={true}
                             nodeRef={nodeRefSearchListLoading}
                             classNames="fade">
                <ul className="board-list"
                    ref={nodeRefSearchListLoading}>
                  <li className="data-loader">
                    <UiIcon name="loader-logo" />
                  </li>
                </ul>
              </CSSTransition>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition in={showRead > 0}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefRead}
                       classNames="page-slide">
          <div className={`post-read-wrap ${showRead < 0 ? 'backward' : ''}`}
               ref={nodeRefRead}>
              {post &&
                <BoardPostInfo board={post.board}
                               post={post}
                               afterLike={this.updatePostLike}
                               afterComment={this.updatePostComment}
                               afterEdit={() => { this.updatePostInfo(post) }}
                               afterDelete={this.updatePostList} />
              }
          </div>
        </CSSTransition>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(Home, props)
export default withRouter(StateContainer)
