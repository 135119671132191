const country = {
  AD: { name: 'Andorra', description: 'Andorra' },
  AE: { name: 'الإمارات العربيّة المتّحدة', description: 'United Arab Emirates' },
  AF: { name: 'افغانستان', description: 'Afghanistan' },
  AG: { name: 'Antigua and Barbuda', description: 'Antigua and Barbuda' },
  AI: { name: 'Anguilla', description: 'Anguilla' },
  AL: { name: 'Shqipëria', description: 'Albania' },
  AM: { name: 'Հայաստան', description: 'Armenia' },
  AO: { name: 'Angola / Ngola', description: 'Angola' },
  AQ: { name: 'Antarctica', description: 'Antarctica' },
  AR: { name: 'Argentina', description: 'Argentina' },
  AS: { name: 'Amerika Sāmoa / American Samoa', description: 'American Samoa' },
  AT: { name: 'Österreich', description: 'Austria' },
  AU: { name: 'Australia', description: 'Australia' },
  AW: { name: 'Aruba', description: 'Aruba' },
  AX: { name: 'Åland / Ahvenanmaa', description: 'Åland' },
  AZ: { name: 'Azərbaycan', description: 'Azerbaijan' },
  BA: { name: 'Босна и Херцеговина', description: 'Bosnia and Herzegovina' },
  BB: { name: 'Barbados', description: 'Barbados' },
  BD: { name: 'বাংলাদেশ', description: 'Bangladesh' },
  BE: { name: 'België / Belgique / Belgien', description: 'Belgium' },
  BF: { name: 'Burkina Faso', description: 'Burkina Faso' },
  BG: { name: 'България', description: 'Bulgaria' },
  BH: { name: 'البحرين', description: 'Bahrain' },
  BI: { name: 'Uburundi / Burundi', description: 'Burundi' },
  BJ: { name: 'Bénin', description: 'Benin' },
  BL: { name: 'Saint-Barthélemy', description: 'Saint Barthélemy' },
  BM: { name: 'Bermuda', description: 'Bermuda' },
  BN: { name: 'بروني', description: 'Brunei' },
  BO: { name: 'Bolivia / Buliwya / Wuliwya / Volívia', description: 'Bolivia' },
  BQ: { name: 'Caribisch Nederland', description: 'Caribbean Netherlands' },
  BR: { name: 'Brasil', description: 'Brazil' },
  BS: { name: 'The Bahamas', description: 'The Bahamas' },
  BT: { name: 'འབྲུག་ཡུལ', description: 'Bhutan' },
  BV: { name: 'Bouvetøya', description: 'Bouvet Island' },
  BW: { name: 'Botswana', description: 'Botswana' },
  BY: { name: 'Беларусь', description: 'Belarus' },
  BZ: { name: 'Belize', description: 'Belize' },
  CA: { name: 'Canada', description: 'Canada' },
  CC: { name: 'Cocos Islands', description: 'Cocos (Keeling) Islands' },
  CD: { name: 'République démocratique du Congo / Republíki ya Kongó Demokratíki / Repubilika ya Kôngo ya Dimokalasi / Jamhuri ya Kidemokrasia ya Kongo', description: 'Democratic Republic of the Congo' },
  CF: { name: 'Centrafrique / Bêafrîka', description: 'Central African Republic' },
  CG: { name: 'République du Congo / Repubilika ya Kôngo / Republíki ya Kongó', description: 'Republic of the Congo' },
  CH: { name: 'Schweiz / Suisse / Svizzera / Svizra', description: 'Switzerland' },
  CI: { name: 'Côte d\'Ivoire', description: 'Côte d\'Ivoire' },
  CK: { name: 'Cook Islands / Kūki \'Āirani', description: 'Cook Islands' },
  CL: { name: 'Chile', description: 'Chile' },
  CM: { name: 'Cameroun / Cameroon', description: 'Cameroon' },
  CN: { name: '中华人民共和国(中国)', description: 'China' },
  CO: { name: 'Colombia', description: 'Colombia' },
  CR: { name: 'Costa Rica', description: 'Costa Rica' },
  CU: { name: 'Cuba', description: 'Cuba' },
  CV: { name: 'Cabo Verde', description: 'Cape Verde' },
  CW: { name: 'Curaçao / Kòrsou', description: 'Curaçao' },
  CX: { name: 'Christmas Island', description: 'Christmas Island' },
  CY: { name: 'Κύπρος / Kıbrıs', description: 'Cyprus' },
  CZ: { name: 'Česká republika', description: 'Czech Republic (Czechia)' },
  DE: { name: 'Deutschland', description: 'Germany' },
  DJ: { name: 'جيبوتي / Djibouti / Jabuuti / Gabuuti', description: 'Djibouti' },
  DK: { name: 'Danmark', description: 'Denmark' },
  DM: { name: 'Dominica', description: 'Dominica' },
  DO: { name: 'República Dominicana', description: 'Dominican Republic' },
  DZ: { name: 'الجزائر', description: 'Algeria' },
  EC: { name: 'Ecuador', description: 'Ecuador' },
  EE: { name: 'Eesti', description: 'Estonia' },
  EG: { name: 'مصر', description: 'Egypt' },
  EH: { name: 'الصحراوية الديمقراطية / República Árabe Saharaui Democrática', description: 'Sahrawi Arab Democratic Republic (SADR / Western Sahara)' },
  ER: { name: 'إرتريا / ኤርትራ', description: 'Eritrea' },
  ES: { name: 'España / Espanya / Espainia / Espanha', description: 'Spain' },
  ET: { name: 'ኢትዮጵያ', description: 'Ethiopia' },
  FI: { name: 'Suomi / Finland', description: 'Finland' },
  FJ: { name: 'Fiji / Viti / फ़िजी', description: 'Fiji' },
  FK: { name: 'Falkland Islands', description: 'Falkland Islands (Malvinas)' },
  FM: { name: 'Federated States of Micronesia', description: 'Federated States of Micronesia' },
  FO: { name: 'Føroyar / Færøerne', description: 'Faroe Islands' },
  FR: { name: 'France', description: 'France' },
  GA: { name: 'République gabonaise', description: 'Gabon' },
  GB: { name: 'United Kingdom / Britain / Y Deyrnas Unedig / Unitit Kinrick / Rìoghachd Aonaichte / Ríocht Aontaithe / An Rywvaneth Unys', description: 'United Kingdom of Great Britain and Northern Ireland' },
  GD: { name: 'Grenada', description: 'Grenada' },
  GE: { name: 'საქართველო', description: 'Georgia' },
  GF: { name: 'Guyane', description: 'French Guiana' },
  GG: { name: 'Guernsey', description: 'Guernsey' },
  GH: { name: 'Ghana / Gaana / Gana', description: 'Ghana' },
  GI: { name: 'Gibraltar', description: 'Gibraltar' },
  GL: { name: 'Kalaallit Nunaat / Grønland', description: 'Greenland' },
  GM: { name: 'The Gambia', description: 'The Gambia' },
  GN: { name: 'Guinée / Gine / Gine', description: 'Guinea' },
  GP: { name: 'Guadeloupe', description: 'Guadeloupe' },
  GQ: { name: 'Guinea Ecuatorial / Guinée équatoriale / Guiné Equatorial', description: 'Equatorial Guinea' },
  GR: { name: 'Ελλάδα', description: 'Greece' },
  GS: { name: 'South Georgia and the South Sandwich Islands', description: 'South Georgia and the South Sandwich Islands' },
  GT: { name: 'Guatemala', description: 'Guatemala' },
  GU: { name: 'Guam / Guåhån', description: 'Guam' },
  GW: { name: 'Guiné-Bissau', description: 'Guinea-Bissau' },
  GY: { name: 'Guyana', description: 'Guyana' },
  HK: { name: 'Hong Kong / Heung Gong / 香港', description: 'Hong Kong' },
  HN: { name: 'Honduras', description: 'Honduras' },
  HR: { name: 'Hrvatska', description: 'Croatia' },
  HT: { name: 'Haïti / Ayiti', description: 'Haiti' },
  HU: { name: 'Magyarország', description: 'Hungary' },
  ID: { name: 'Indonesia', description: 'Indonesia' },
  IE: { name: 'Éire / Ireland', description: 'Ireland' },
  IL: { name: 'ישראל / إسرائيل', description: 'Israel' },
  IM: { name: 'Isle of Man / Ellan Vannin', description: 'Isle of Man' },
  IN: { name: 'ভাৰত / ভারত / India / ભારત / भारत / ಭಾರತ / भारत / ഭാരതം / भारत / ଭାରତ / ਭਾਰਤ / भारतम् / பாரதம் / భారత దేశం', description: 'India' },
  IO: { name: 'British Indian Ocean Territory', description: 'British Indian Ocean Territory' },
  IQ: { name: 'العراق / عێراق', description: 'Iraq' },
  IR: { name: 'ایران', description: 'Iran' },
  IS: { name: 'Ísland', description: 'Iceland' },
  IT: { name: 'Italia', description: 'Italy' },
  JE: { name: 'Jersey / Jèrri', description: 'Jersey' },
  JM: { name: 'Jamaica', description: 'Jamaica' },
  JO: { name: 'الأردن', description: 'Jordan' },
  JP: { name: '日本', description: 'Japan' },
  KE: { name: 'Kenya', description: 'Kenya' },
  KG: { name: 'Кыргызстан / Киргизия', description: 'Kyrgyzstan' },
  KH: { name: 'កម្ពុជា', description: 'Cambodia' },
  KI: { name: 'Kiribati', description: 'Kiribati' },
  KM: { name: 'Komori / جزر القمر / Comores', description: 'Comoros' },
  KN: { name: 'Saint Kitts and Nevis', description: 'Saint Kitts and Nevis' },
  KP: { name: '북한', description: 'Korea, Democratic People\'s Republic of (North Korea)' },
  KR: { name: '대한민국', description: 'Korea, Republic of (South Korea)' },
  KW: { name: 'دولة الكويت / الكويت', description: 'Kuwait' },
  KY: { name: 'Cayman Islands', description: 'Cayman Islands' },
  KZ: { name: 'Қазақстан / Казахстан', description: 'Kazakhstan' },
  LA: { name: 'ປະເທດລາວ', description: 'Laos' },
  LB: { name: 'لبنان / Liban', description: 'Lebanon' },
  LC: { name: 'Saint Lucia', description: 'Saint Lucia' },
  LI: { name: 'Liechtenstein', description: 'Liechtenstein' },
  LK: { name: 'ශ්‍රී ලංකාව / இலங்கை', description: 'Sri Lanka' },
  LR: { name: 'Liberia', description: 'Liberia' },
  LS: { name: 'Lesotho', description: 'Lesotho' },
  LT: { name: 'Lietuva', description: 'Lithuania' },
  LU: { name: 'Lëtzebuerg / Luxemburg / Luxembourg', description: 'Luxembourg' },
  LV: { name: 'Latvija', description: 'Latvia' },
  LY: { name: 'ⵍⵉⴱⵢⴰ / ليبيا', description: 'Libya' },
  MA: { name: 'ⴰⵎⵔⵔⵓⴽ / ⵍⵎⵖⵔⵉⴱ / المغرب', description: 'Morocco' },
  MC: { name: 'Monaco / Múnegu', description: 'Monaco' },
  MD: { name: 'Moldova', description: 'Moldova' },
  ME: { name: 'Црна Гора', description: 'Montenegro' },
  MF: { name: 'Saint-Martin', description: 'Saint Martin' },
  MG: { name: 'Madagasikara / Madagascar', description: 'Madagascar' },
  MH: { name: 'Marshall Islands / Aorōkin Ṃajeḷ', description: 'Marshall Islands' },
  MK: { name: 'Северна Македонија / Maqedonia e Veriut', description: 'North Macedonia' },
  ML: { name: 'Mali', description: 'Mali' },
  MM: { name: 'မြန်မာ', description: 'Myanmar' },
  MN: { name: 'Монгол Улс / ᠮᠤᠩᠭᠤᠯ ᠤᠯᠤᠰ', description: 'Mongolia' },
  MO: { name: '澳門 / Macau', description: 'Macau' },
  MP: { name: 'Northern Mariana Islands / Notte Mariånas', description: 'Northern Mariana Islands' },
  MQ: { name: 'Martinique', description: 'Martinique' },
  MR: { name: 'ⵎⵓⵔⵉⵜⴰⵏ / ⴰⴳⴰⵡⵛ / موريتانيا', description: 'Mauritania' },
  MS: { name: 'Montserrat', description: 'Montserrat' },
  MT: { name: 'Malta', description: 'Malta' },
  MU: { name: 'Mauritius / Maurice / Moris', description: 'Mauritius' },
  MV: { name: 'ދިވެހިރާއްޖެ', description: 'Maldives' },
  MW: { name: 'Malawi / Malaŵi', description: 'Malawi' },
  MX: { name: 'México / Mēxihco', description: 'Mexico' },
  MY: { name: 'Malaysia', description: 'Malaysia' },
  MZ: { name: 'Moçambique', description: 'Mozambique' },
  NA: { name: 'Namibia / Namibië', description: 'Namibia' },
  NC: { name: 'Nouvelle-Calédonie', description: 'New Caledonia' },
  NE: { name: 'Niger', description: 'Niger' },
  NF: { name: 'Norfolk Island / Norf\'k Ailen', description: 'Norfolk Island' },
  NG: { name: 'Nigeria / Nijeriya / Naìjíríyà / Nàìjíríà', description: 'Nigeria' },
  NI: { name: 'Nicaragua', description: 'Nicaragua' },
  NL: { name: 'Nederland / Nederlân', description: 'Netherlands' },
  NO: { name: 'Norge / Noreg / Norga / Vuodna / Nöörje', description: 'Norway' },
  NP: { name: 'Nepāl / नेपाल', description: 'Nepal' },
  NR: { name: 'Nauru / Naoero', description: 'Nauru' },
  NU: { name: 'Niuē / Niue', description: 'Niue' },
  NZ: { name: 'New Zealand / Aotearoa', description: 'New Zealand' },
  OM: { name: 'عُمان', description: 'Oman' },
  PA: { name: 'Panamá', description: 'Panama' },
  PE: { name: 'Perú / Piruw', description: 'Peru' },
  PF: { name: 'Polynésie française', description: 'French Polynesia' },
  PG: { name: 'Papua New Guinea / Papua Niugini / Papua Niu Gini', description: 'Papua New Guinea' },
  PH: { name: 'Pilipinas / Philippines', description: 'Philippines' },
  PK: { name: 'Pākistān / پاکستان', description: 'Pakistan' },
  PL: { name: 'Polska', description: 'Poland' },
  PM: { name: 'Saint-Pierre et Miquelon', description: 'Saint Pierre and Miquelon' },
  PN: { name: 'Pitcairn Islands / Pitkern Ailen', description: 'Pitcairn Islands' },
  PR: { name: 'Puerto Rico', description: 'Puerto Rico' },
  PS: { name: 'فلسطين', description: 'Palestine' },
  PT: { name: 'Portugal', description: 'Portugal' },
  PW: { name: 'Belau / Palau', description: 'Palau' },
  PY: { name: 'Paraguay / Paraguái', description: 'Paraguay' },
  QA: { name: 'قطر', description: 'Qatar' },
  RE: { name: 'La Réunion', description: 'Réunion' },
  RO: { name: 'România', description: 'Romania' },
  RS: { name: 'Srbija / Србија', description: 'Serbia' },
  RU: { name: 'Россия', description: 'Russian Federation' },
  RW: { name: 'Rwanda', description: 'Rwanda' },
  SA: { name: 'المملكة العربية السعودية', description: 'Saudi Arabia' },
  SB: { name: 'Solomon Islands / Solomon Aelan', description: 'Solomon Islands' },
  SC: { name: 'Sesel / Seychelles', description: 'Seychelles' },
  SD: { name: 'السودان', description: 'Sudan' },
  SE: { name: 'Sverige', description: 'Sweden' },
  SG: { name: 'Singapura / Singapore / 新加坡 / சிங்கப்பூர்', description: 'Singapore' },
  SH: { name: 'Saint Helena, Ascension and Tristan da Cunha', description: 'Saint Helena, Ascension and Tristan da Cunha' },
  SI: { name: 'Slovenija', description: 'Slovenia' },
  SJ: { name: 'Svalbard', description: 'Svalbard' },
  SK: { name: 'Slovensko', description: 'Slovakia' },
  SL: { name: 'Sierra Leone', description: 'Sierra Leone' },
  SM: { name: 'San Marino', description: 'San Marino' },
  SN: { name: 'Sénégal / Senegaal', description: 'Senegal' },
  SO: { name: 'Soomaaliya / الصومال', description: 'Somalia' },
  SR: { name: 'Suriname', description: 'Suriname' },
  SS: { name: 'South Sudan / Sudan Kusini / Paguot Thudän', description: 'South Sudan' },
  ST: { name: 'São Tomé e Príncipe', description: 'São Tomé e Príncipe' },
  SV: { name: 'El Salvador', description: 'El Salvador' },
  SX: { name: 'Sint Maarten', description: 'Sint Maarten' },
  SY: { name: 'سورية', description: 'Syria' },
  SZ: { name: 'Eswatini / eSwatini', description: 'Eswatini' },
  TC: { name: 'Turks and Caicos Islands', description: 'Turks and Caicos Islands' },
  TD: { name: 'Tchad / تشاد', description: 'Chad' },
  TF: { name: 'Terres australes et antarctiques françaises', description: 'French Southern and Antarctic Lands' },
  TG: { name: 'Togo', description: 'Togo' },
  TH: { name: 'ไทย (ประเทศไทย / ราชอาณาจักรไทย)', description: 'Thailand' },
  TJ: { name: 'Тоҷикистон', description: 'Tajikistan' },
  TK: { name: 'Tokelau', description: 'Tokelau' },
  TL: { name: 'Timor Lorosa\'e / Timor-Leste', description: 'Timor-Leste' },
  TM: { name: 'Türkmenistan', description: 'Turkmenistan' },
  TN: { name: 'ⵜⵓⵏⵙ / تونس', description: 'Tunisia' },
  TO: { name: 'Tonga', description: 'Tonga' },
  TR: { name: 'Türkiye', description: 'Turkey' },
  TT: { name: 'Trinidad and Tobago', description: 'Trinidad and Tobago' },
  TV: { name: 'Tuvalu', description: 'Tuvalu' },
  TW: { name: '中華民國 / 臺灣 / 台灣', description: 'Taiwan (Republic of China)' },
  TZ: { name: 'Tanzania', description: 'Tanzania' },
  UA: { name: 'Україна', description: 'Ukraine' },
  UG: { name: 'Uganda', description: 'Uganda' },
  UM: { name: 'United States Minor Outlying Islands', description: 'United States Minor Outlying Islands' },
  US: { name: 'United States of America / Estados Unidos / États-Unis / ‘Amelika Hui Pū ‘ia', description: 'United States of America' },
  UY: { name: 'Uruguay', description: 'Uruguay' },
  UZ: { name: 'Ўзбекистон', description: 'Uzbekistan' },
  VA: { name: 'Civitas Vaticana / Città del Vaticano', description: 'Vatican City' },
  VC: { name: 'Saint Vincent and the Grenadines', description: 'Saint Vincent and the Grenadines' },
  VE: { name: 'Venezuela', description: 'Venezuela' },
  VG: { name: 'British Virgin Islands', description: 'British Virgin Islands' },
  VI: { name: 'United States Virgin Islands', description: 'United States Virgin Islands' },
  VN: { name: 'Việt Nam', description: 'Vietnam' },
  VU: { name: 'Vanuatu', description: 'Vanuatu' },
  WF: { name: 'Wallis-et-Futuna / ʻUvea mo Futuna', description: 'Wallis and Futuna' },
  WS: { name: 'Samoa / Sāmoa', description: 'Samoa' },
  YE: { name: 'اليمن', description: 'Yemen' },
  YT: { name: 'Mayotte / Maore ', description: 'Mayotte' },
  ZA: { name: 'South Africa / Suid-Afrika / iNingizimu Afrika / uMzantsi Afrika / Afrika-Borwa / Afrika Borwa / Aforika Borwa / Afurika Tshipembe / Afrika Dzonga / iSewula Afrika', description: 'South Africa' },
  ZM: { name: 'Zambia', description: 'Zambia' },
  ZW: { name: 'Zimbabwe', description: 'Zimbabwe' }
}

export default country
