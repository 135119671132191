import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { timeString, addListener, removeListener, getS3Url, historyPushState, bodyScroll } from '../../utils/tool'

import { boardPostListAll, boardPostInfo } from '../../api/community'

import BoardPostInfo from '../../pages/community/BoardPostInfo'

import UiIcon from '../../components/ui/Icon'
import UiCountry from '../../components/ui/Country'
import UiInput from '../../components/ui/Input'

import './BoardPost.scss'

class MyBoardPostBookmark extends Component {
  state = {
    loading: false,
    post: null,
    showSearch: 0,
    showRead: 0,
    error: {
      title: null,
      content: null,
      files: null
    },
    list: [],
    searchList: [],
    keyword: '',
    listOption: {},
    searchListOption: {},
    firstDate: null,
    initialListOption: {
      lastDate: null,
      page: 0,
      count: 10
    },
    noMoreList: false,
    noMoreSearchList: false
  }

  keywordOnChange = (keyword) => {
    const { keywordTimer } = this.state
    clearTimeout(keywordTimer)
    this.setState({
      loading: true,
      keyword,
      keywordTimer: setTimeout(() => {
        this.onSearch()
      }, 1500)
    })
  }

  onSearch = () => {
    const {
      keyword,
      initialListOption
    } = this.state

    if (keyword.trim()) {
      this.setState({
        searchListOption: initialListOption,
        noMoreSearchList: false
      })

      removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)

      boardPostListAll({
        bookmark: true,
        keyword,
        ...initialListOption
      }).then(result => {
        const list = result.data.list
        this.setState({
          loading: false,
          searchList: list,
          searchListOption: {
            ...initialListOption,
            lastDate: list.length ? list[list.length - 1].createdAt : false
          },
          noMoreSearchList: list.length < initialListOption.count
        })

        addListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)
      }).catch(error => {
        console.error(error)
      })
    } else {
      this.setState({
        loading: false,
        searchList: []
      })
    }
  }

  loadNextSearchPage = (loading = true) => {
    const {
      keyword,
      searchList,
      searchListOption
    } = this.state

    const newListOption = {
      ...searchListOption,
      page: searchListOption.page + 1
    }

    this.setState({
      searchListOption: newListOption
    })

    if (loading) {
      this.setState({ loading: true })
    }
    boardPostListAll({
      bookmark: true,
      keyword,
      ...newListOption
    }).then(result => {
      const nextList = result.data.list
      const newList = searchList.concat(nextList)
      this.setState({
        loading: false,
        searchList: newList,
        searchListOption: {
          ...newListOption,
          lastDate: newList.length ? newList[newList.length - 1].createdAt : false
        },
        noMoreSearchList: nextList.length < searchListOption.count
      })
    }).catch(error => {
      console.error(error)
    })
  }

  onClickPost = (post) => {
    this.openRead(post)
  }

  openSearch = () => {
    this.setState({
      showSearch: 1,
      searchList: [],
      keyword: ''
    })

    this.setSearchTitle()

    bodyScroll(true)
  }

  closeSearch = () => {
    this.setInitialTitle(true)
    this.setState({
      showSearch: -1
    })

    bodyScroll(false)
  }

  openRead = (post) => {
    const {
      action
    } = this.props

    this.setState({
      post: null,
      loading: true
    })

    setTimeout(() => {
      action.setNavbarBackbutton(
        <UiIcon name="back"
                onClick={() => { this.closeRead() }}/>
      )
    }, 0)

    boardPostInfo({
      id: post.id
    }).then(response => {
      this.setState({
        post: {
          ...response.data,
          board: post.board
        },
        loading: false,
        showRead: 1
      })

      historyPushState(window.location.pathname + '?p=' + post.id, { post: post.id })
      action.setNavbarTitle('')

      bodyScroll(true)
    }).catch(error => {
      console.error(error)
    })
  }

  closeRead = () => {
    const {
      list,
      searchList,
      showSearch,
      noMoreList,
      noMoreSearchList
    } = this.state

    if (showSearch > 0) {
      if (searchList.length < 1 && !noMoreSearchList) {
        this.loadList()
      }
      this.setSearchTitle(true)
    } else {
      if (list.length < 1 && !noMoreList) {
        this.loadList()
      }
      this.setInitialTitle(true)
    }
    this.setState({
      showRead: -1
    })
    if (window.location.search) {
      window.history.back()
    }

    bodyScroll(false)
  }

  detectPost = () => {
    const {
      showRead
    } = this.state

    const params = new URLSearchParams(window.location.search)
    if (params) {
      const postId = params.get('p')
      if (postId) {
        if (showRead <= 0) {
          this.openRead({ id: postId })
        }
        return true
      } else {
        if (showRead > 0) {
          this.closeRead()
        }
        return false
      }
    }
  }

  updatePostLike = (postMatch, liked, countLike) => {
    const {
      list
    } = this.state

    for (let i = 0; i < list.length; i ++) {
      const post = list[i]
      if (post.id === postMatch.id) {
        post.liked = liked
        post.meta.countLike = countLike
        break
      }
    }
  }

  updatePostInfo = (post) => {
    this.openRead(post)
    this.updatePostList()
  }

  updatePostList = () => {
    const {
      showSearch
    } = this.state

    this.closeRead()

    if (showSearch) {
      this.onSearch()
    } else {
      this.loadList()
    }
  }

  setSearchTitle = (backward = false) => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('common.search'), backward)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.closeSearch() }} />
    )
    action.setNavbarButtons(null)
    action.setLayoutShowFooter(false)
  }

  setInitialTitle = () => {
    const {
      t,
      action,
      history
    } = this.props

    action.setNavbarTitle(t('setting.bookmark.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/my'); }} />
    )
    action.setNavbarButtons(
      <UiIcon name="search"
              onClick={() => { this.openSearch() }} />
    )
    action.setLayoutShowFooter(false)
  }

  loadList = (loading = true) => {
    const {
      initialListOption
    } = this.state

    this.setState({
      listOption: initialListOption,
      noMoreList: false
    })

    if (loading) {
      this.setState({ loading: true })
    }

    removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    boardPostListAll({
      bookmark: true,
      ...initialListOption
    }).then(result => {
      const list = result.data.list
      this.setState({
        loading: false,
        list,
        listOption: {
          ...initialListOption,
          lastDate: list.length ? list[list.length - 1].createdAt : false
        },
        firstDate: list.length ? list[0].createdAt : false,
        noMoreList: list.length < initialListOption.count
      })

      addListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    }).catch(error => {
      console.error(error)
    })
  }

  loadNextPage = (loading = true) => {
    const {
      list,
      listOption
    } = this.state

    const newListOption = {
      ...listOption,
      page: listOption.page + 1
    }

    this.setState({
      listOption: newListOption
    })

    if (loading) {
      this.setState({ loading: true })
    }
    boardPostListAll({
      bookmark: true,
      ...newListOption
    }).then(result => {
      const nextList = result.data.list
      const newList = list.concat(nextList)
      this.setState({
        loading: false,
        list: newList,
        listOption: {
          ...newListOption,
          lastDate: newList.length ? newList[newList.length - 1].createdAt : false
        },
        noMoreList: nextList.length < listOption.count
      })
    }).catch(error => {
      console.error(error)
    })
  }

  trackListScrolling = () => {
    const {
      loading,
      noMoreList
    } = this.state
    if (noMoreList) {
      removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    }
    const el = document.getElementById('boardList');
    if (!noMoreList && !loading) {
      if (el.getBoundingClientRect().bottom < window.innerHeight + 500) {
        this.setState({ loading: true })
        this.loadNextPage()
      }
    }
  }

  trackSearchListScrolling = () => {
    const {
      loading,
      noMoreSearchList
    } = this.state
    if (noMoreSearchList) {
      document.getElementById('searchContainer').removeEventListener('scroll', this.trackSearchListScrolling)
    }
    const el = document.getElementById('boardSearchList');
    if (!noMoreSearchList && !loading) {
      if (el.getBoundingClientRect().bottom < window.innerHeight + 500) {
        this.setState({ loading: true })
        this.loadNextSearchPage()
      }
    }
  }

  componentDidMount () {
    const {
      action
    } = this.props

    if (!this.detectPost()) {
      this.setInitialTitle()
      this.loadList()
    }

    action.setLayoutShowHeader(true)

    window.addEventListener('popstate', this.detectPost)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectPost)
    removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefNoData = createRef(null)
    const nodeRefList = createRef(null)
    const nodeRefRead = createRef(null)
    const nodeRefSearchList = createRef(null)
    const nodeRefSearchListLoading = createRef(null)
    const nodeRefSearch = createRef(null)

    const {
      loading,
      post,
      showRead,
      showSearch,
      list,
      searchList,
      keyword,
      firstDate
    } = this.state

    const {
      t
    } = this.props

    const postItem = (post, i) => {
      return (
        <li key={`list-button-${i}`}>
          <button onClick={() => { this.onClickPost(post) }}>
            {post.author &&
              <span className="author">
                          <UiCountry code={post.country} />
                {post.author.nickname}
                        </span>
            }
            <span className={`content ${post.thumbnail ? 'thumb' : ''}`}>
              <span className="title">
                {post.title}
              </span>
              <span className="summary">
                {post.summary}
              </span>
            </span>
            {post.thumbnail &&
              <span className="thumbnail"
                    style={{ backgroundImage: `url('${getS3Url(post.thumbnail)}')` }}> </span>
            }
            <span className="meta">
              <span className="count">
                <span className="like">
                  <UiIcon name={`post-like ${post.liked ? 'active' : ''}`} />
                  {post.meta.countLike}
                </span>
                <span className="comment">
                  <UiIcon name={`post-comment ${post.commented ? 'active' : ''}`} />
                  {post.meta.countComment}
                </span>
              </span>
              <span className="date">
                {post.board.title}
                <span className="gap" />
                {timeString(post.createdAt, t)}
              </span>
            </span>
          </button>
        </li>
      )
    }

    return(
      <div className="layout-container">
        <div className="my-board-post-list"
             id="boardPostListContainer">
          <CSSTransition in={loading}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefLoader}
                         classNames="fade">
            <div className="data-loader"
                 ref={nodeRefLoader}>
              <UiIcon name="loader" />
            </div>
          </CSSTransition>

          <CSSTransition in={firstDate === false}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefNoData}
                         classNames="fade">
            <div className="no-data"
                 ref={nodeRefNoData}>
              <div className="data-loader">
                <UiIcon name="loader-logo" />
              </div>
            </div>
          </CSSTransition>

          <CSSTransition in={list.length > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefList}
                         classNames="fade">
            <ul className="board-list"
                id="boardList"
                ref={nodeRefList}>
              {list.map((post, i) => {
                return postItem(post, i)
              })}
            </ul>
          </CSSTransition>

          <CSSTransition in={showSearch > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefSearch}
                         classNames="page-slide">
            <div className={`search-container ${showSearch < 0 ? 'backward' : ''}`}
                 id="searchContainer"
                 ref={nodeRefSearch}>
              <div className="layout-container">
                <div className="search">
                  <UiInput type="text"
                           color="fill"
                           placeholder={t('common.search')}
                           icon="search"
                           value={keyword}
                           onChange={value => this.keywordOnChange(value)} />
                </div>
                <CSSTransition in={searchList.length > 0}
                               timeout={500}
                               mountOnEnter={true}
                               unmountOnExit={true}
                               nodeRef={nodeRefSearchList}
                               classNames="fade">
                  <ul className="board-list"
                      id="boardSearchList"
                      ref={nodeRefSearchList}>
                    {searchList.map((post, i) => {
                      return postItem(post, i)
                    })}
                  </ul>
                </CSSTransition>
                <CSSTransition in={searchList.length < 1}
                               timeout={500}
                               mountOnEnter={true}
                               unmountOnExit={true}
                               nodeRef={nodeRefSearchListLoading}
                               classNames="fade">
                  <ul className="board-list"
                      ref={nodeRefSearchListLoading}>
                    <li className="data-loader">
                      <UiIcon name="loader-logo" />
                    </li>
                  </ul>
                </CSSTransition>
              </div>
            </div>
          </CSSTransition>

          <CSSTransition in={showRead > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefRead}
                         classNames="page-slide">
            <div className={`post-read-wrap ${showRead < 0 ? 'backward' : ''}`}
                 ref={nodeRefRead}>
              <div className="layout-container">
                {post &&
                  <BoardPostInfo board={post.board}
                                 post={post}
                                 afterLike={this.updatePostLike}
                                 afterEdit={() => { this.updatePostInfo(post) }}
                                 afterDelete={this.updatePostList} />
                }
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(MyBoardPostBookmark, props)
export default withRouter(StateContainer)
