import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import { format } from 'date-fns'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import { notificationList, notificationAction } from '../../api/user'

import UiIcon from '../../components/ui/Icon'

import './Notification.scss'

class MyNotification extends Component {
  state = {
    loading: false,
    list: []
  }

  onAction = (notification) => {
    const {
      history
    } = this.props

    if (notification.action) {
      const action = {}
      notification.action.split('|').forEach(option => {
        const op = option.split(':')
        action[op[0]] = op[1]
      })

      if (action.action === 'toPost') {
        history.push({
          pathname: '/c/' + action.board,
          state: { post: action.post }
        })
      } else if (action.action === 'toTalkList') {
        history.push({
          pathname: '/t/r'
        })
      }
    }

    if (!notification.actionAt) {
      notificationAction({
        id: notification.id
      }).then(() => {
        if (!notification.action) {
          this.loadList(false)
        }
      })
    }
  }

  loadList = (loading = true) => {
    if (loading) {
      this.setState({ loading: true })
    }
    notificationList().then(result => {
      this.setState({
        loading: false,
        list: [...result.data.list]
      })
    }).catch(error => {
      console.error(error)
    })
  }

  componentDidMount () {
    const {
      t,
      action
    } = this.props


    setTimeout(() => {
      action.setNavbarTitle(t('notification.title'))
    }, 0)
    action.setNavbarBackbutton(null)
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(true)

    this.loadList()
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefList = createRef(null)

    const {
      loading,
      list
    } = this.state

    const {
      t
    } = this.props

    const todayDate = format(new Date(), 'MM.dd')

    return(
      <div className="layout-container">
        <div className="my-notification">
          <CSSTransition in={loading}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefLoader}
                         classNames="fade">
            <div className="data-loader"
                 ref={nodeRefLoader}>
              <UiIcon name="loader" />
            </div>
          </CSSTransition>

          <CSSTransition in={list.length > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefList}
                         classNames="fade">
            <ul className="notification-list"
                ref={nodeRefList}>
              {list.map((notification, i) => {
                let dateString = format(new Date(notification.createdAt), 'MM.dd')
                if (todayDate === dateString) {
                  dateString = format(new Date(notification.createdAt), 'HH:mm')
                }

                const notiTitle = (notification.title || '').replace(/\{+([^}{]+)}+/g, (match, p1) => t(p1))
                const notiContent = (notification.content || '').replace(/\{+([^}{]+)}+/g, (match, p1) => t(p1))

                return (
                  <li key={`list-button-${i}`}>
                    <button className={notification.actionAt ? 'disable' : ''}
                            onClick={() => { this.onAction(notification) }}>
                      <span className="icon">
                        <UiIcon name={notification.icon} />
                      </span>
                      <span className="text">
                        <span className="title"
                              dangerouslySetInnerHTML={{ __html: notiTitle }}>
                        </span>
                        <span className="content"
                              dangerouslySetInnerHTML={{ __html: notiContent }}>
                        </span>
                        <span className="date">
                          {dateString}
                        </span>
                      </span>
                    </button>
                  </li>
                )
              })}
              <li className="notice">
                {t('notification.description')}
              </li>
            </ul>
          </CSSTransition>
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(MyNotification, props)
export default withRouter(StateContainer)
