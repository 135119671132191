import { format, differenceInMinutes, differenceInDays } from 'date-fns'

const awsS3Url = 'https://s3.ap-northeast-2.amazonaws.com'

export const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
export const alphabetCharRegex = /[a-zA-Z]/
export const numericCharRegex = /[0-9]/
export const specialCharRegex = /[~!@#$%^*()\-_=+|[\]{}:'",.<>/?]/

export function historyPushState (path, data, title) {
  window.history.pushState(data || {}, title || document.title, path || window.location.pathname)
}

export function getS3Url (pathKey, timestamp) {
  if (!pathKey) {
    return null
  }

  if (pathKey.substr(0, awsS3Url.length) === awsS3Url) {
    return pathKey
  }

  const [bucketName, filePath] = pathKey.split(':')
  let url = `${awsS3Url}/${bucketName}/${filePath}`

  if (timestamp) {
    url += `?v=${timestamp}`
  }
  return url
}

export function getS3Key (url) {
  let path = url.replace(awsS3Url + '/', '')
  return path.replace('/', ':')
}

export function timeDiff (scale, a, b = null) {
  const dateA = new Date(a)
  const dateB = b ? new Date(b) : new Date()

  if (dateB < dateA) {
    dateB.setDate(dateB.getDate() + 1)
  }

  let divide = 1000
  if (scale === 's') {
    divide *= 1
  } else if (scale === 'm') {
    divide *= 60
  } else if (scale === 'h') {
    divide *= 60 * 60
  } else if (scale === 'd') {
    divide *= 60 * 60 * 24
  }
  return Math.floor((dateB - dateA) / divide)
}

export function timeString (date, t) {
  const today = new Date()
  const dateAt = new Date(date)
  const minDiff = differenceInMinutes(today, dateAt)
  today.setHours(23)
  dateAt.setHours(0)
  const dayDiff = differenceInDays(today, dateAt)
  let dateString = format(dateAt, 'yyyy. MM. dd')
  if (dateAt.getFullYear() === today.getFullYear()) {
    if (dayDiff === 1 && t) {
      dateString = t('date.yesterday')
    } else if (dayDiff < 1) {
      if (minDiff < 1) {
        dateString = t ? t('date.moment') : '> 1m'
      } else if (minDiff < 60) {
        dateString = minDiff + (t ? t('date.m') : 'm')
      } else if (minDiff < 60 * 24) {
        dateString = Math.floor(minDiff / 60) + (t ? t('date.h') : 'h')
      }
    } else {
      dateString = format(dateAt, 'MM. dd')
    }
  }
  return dateString
}

export function isArray (value) {
  return Array.isArray(value)
}

export function isPlainObject (value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export function isUserId (value) {
    const re = /^[a-zA-Z0-9!@#$%^*()\-_=+|[\]{}:'",.<>/?]{4,15}$/
    return re.test(value)
}

export function isPassword (value) {
  const lenCheck = value.length >= 6
  const alphabetNumber = alphabetCharRegex.test(value) && numericCharRegex.test(value)
  const alphabetSpecialChar = alphabetCharRegex.test(value) && specialCharRegex.test(value)
  const numberSpecialChar = numericCharRegex.test(value) && specialCharRegex.test(value)
  return lenCheck && (alphabetNumber || alphabetSpecialChar || numberSpecialChar)
  
}

export function isEmail (value) {
  return emailRegex.test(value)
}

export function objectToForm (obj) {
  const formData = new FormData()

  const putInForm = (value, key) => {
    if (value && value instanceof Object && !(value instanceof File)) {
      if (Array.isArray(value)) {
        value.forEach((v, idx) => {
          putInForm(v, key ? `${key}[${idx}]` : idx)
        })
      } else {
        Object.entries(value).forEach(([k, v]) => {
          putInForm(v, key ? `${key}[${k}]` : k)
        })
      }
    } else {
      formData.append(key, value)
    }
  }
  putInForm(obj)
  return formData
}

export const image = (url, onLoad, onError, rejectTimeout = 5000) => new Promise((resolve, reject) => {
  let timer = null

  const img = new Image()

  img.addEventListener('load', () => {
    if (timer) {
      clearTimeout(timer)
    }
    if (onLoad) {
      onLoad(img)
    }
    resolve(img)
  })

  img.addEventListener('error', (event) => {
    if (timer) {
      clearTimeout(timer)
    }
    if (onError) {
      onError(img)
    }
    reject(`${event.type}: ${event.message}`)
  })

  img.src = url

  if (rejectTimeout) {
    timer = setTimeout(() => reject('Timeout exception'), rejectTimeout)
  }
})

export const resizeImage = (file, dataUri, { resizeSize, imageType, imageQuality }, delay = 200) => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        image(dataUri, (img) => {
          let resizeWidth = resizeSize.width
          let resizeHeight = resizeSize.height

          const width = img.width
          const height = img.height
          if (!resizeWidth && !resizeHeight) {
            resizeWidth = width
            resizeHeight = height
          } else if (!resizeWidth && resizeHeight) {
            resizeWidth = width * resizeHeight / height
          } else if (resizeWidth && !resizeHeight) {
            resizeHeight = height * resizeWidth / width
          }

          let ratio
          if (width > height) {
            ratio = resizeHeight / height
          } else {
            ratio = resizeWidth / width
          }

          let canvas = document.createElement('canvas')
          canvas.width = resizeWidth
          canvas.height = resizeHeight

          const dw = width * ratio
          const dh = height * ratio
          const dx = (resizeWidth - dw) / 2
          const dy = (resizeHeight - dh) / 2

          let ctx = canvas.getContext('2d')
          ctx.fillStyle = 'white'
          ctx.fillRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(img, dx, dy, dw, dh)

          const thumbnailUrl = canvas.toDataURL(imageType, imageQuality)
          const resizedFile = base64ToFile(thumbnailUrl, file)
          const originalFile = base64ToFile(dataUri, file)
          resolve({ dataUri, thumbnailUrl, resizedFile, originalFile, ratio: width / height })
        })
      }, delay)
    } catch (e) {
      reject(e)
    }
  })
}

export const base64ToFile = (dataURI, origFile) => {
  let byteString, mimeString
  if (dataURI.split(',')[0].indexOf('base64') !== -1) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = decodeURI(dataURI.split(',')[1])
  }
  mimeString = dataURI.split(',')[0].split(':')[1].split('')[0]
  let content = []
  for (let i = 0; i < byteString.length; i++) {
    content[i] = byteString.charCodeAt(i)
  }
  let newFile = new File(
    [new Uint8Array(content)], origFile.name, { type: mimeString }
  )
  let origProps = [
    'upload', 'status', 'previewElement', 'previewTemplate', 'accepted'
  ]
  origProps.forEach(p => {
    newFile[p] = origFile[p]
  })

  return newFile
}

export const addListener = (el, type, func) => {
  if (el) {
    el.addEventListener(type, func)
  }
}

export const removeListener = (el, type, func) => {
  if (el) {
    el.removeEventListener(type, func)
  }
}

export const br2nl = (content) => {
  return (content || '').replace(/<br>/g, '\n')
}

export const removeTags = (content) => {
  return (content || '').replace(/<\/?[^>]+(>|$)/g, '')
}

export const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text)
  } else {
    const t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = text
    t.setSelectionRange(0, 999999999999)
    document.execCommand('copy')
    document.body.removeChild(t)
  }
  if (window.androidClipboard) {
    window.androidClipboard.copyToClipboard(text)
  }
}

export const browserInfo = () => {
  if (window.nearcleAndroid) {
    return 'android'
  }
  if (window.webkit && window.webkit.messageHandlers.nearcleIos) {
    return 'ios'
  }
  return 'browser'
}

export const bodyScroll = (prevent) => {
  if (prevent) {
    document.getElementById('defaultLayout').style.overflow = 'hidden'
    document.body.style['overscroll-behavior-y'] = 'none'
  } else {
    document.getElementById('defaultLayout').style.overflow = null
    document.body.style['overscroll-behavior-y'] = null
  }
}

export const easeOutCubic = (x) => {
  return 1 - Math.pow(1 - x, 3)
}

export const distBetweenCoords = (coord1, coord2) => { // km
  const R = 6371
  const dLat = toRad(coord2.lat - coord1.lat)
  const dLon = toRad(coord2.lng - coord1.lng)
  const lat1 = toRad(coord1.lat)
  const lat2 = toRad(coord2.lat)

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

const toRad = (value) => {
  return value * Math.PI / 180
}

// 2036년까지 지원
const calendarDay = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']
const solarHolidays = ['0101', '0301', '0505', '0606', '0717', '0815', '1003', '1225'] // 양력 휴일
const lunaHolidays = ['0101', '0102', '0408', '0814', '0815', '0816'] // 음력 휴일
const alternativeHolidays = ['20230124', '20240212', '20240506', '20251008', '20270209', '20290924', '20290507', '20300205', '20300506', '20320921', '20330202', '20340221', '20350918', '20360130' ] // 대체공휴일
const today = new Date()
const todayUTC = today.getTime() + (today.getTimezoneOffset() * 60000)
const todaySeoul = new Date(todayUTC + (3600000 * 9))
const todayString = (todaySeoul.getMonth() > 8 ? '' : '0') + (todaySeoul.getMonth() + 1) + (todaySeoul.getDate() > 9 ? '' : '0') + (todaySeoul.getDate())
const todayYearString = todaySeoul.getFullYear() + todayString
const todayYearStringDash = todayYearString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
const yesterdayUTC = today.getTime() + ((today.getTimezoneOffset() - (24 * 60)) * 60000)
const yesterdaySeoul = new Date(yesterdayUTC + (3600000 * 9))
const yesterdayString = (yesterdaySeoul.getMonth() > 8 ? '' : '0') + (yesterdaySeoul.getMonth() + 1) + (yesterdaySeoul.getDate() > 9 ? '' : '0') + (yesterdaySeoul.getDate())
const yesterdayYearString = yesterdaySeoul.getFullYear() + yesterdayString
const yesterdayYearStringDash = yesterdayYearString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
const lunarMonthTable = [
  [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
  [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1], // 2000
  [2, 2, 2, 3, 2, 1, 1, 2, 1, 2, 1, 2],
  [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2],
  [1, 5, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
  [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 1],
  [2, 1, 2, 1, 2, 1, 5, 2, 2, 1, 2, 2],
  [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
  [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
  [2, 2, 1, 1, 5, 1, 2, 1, 2, 1, 2, 2],
  [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2], // 2010
  [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 1, 6, 2, 1, 2, 1, 1, 2, 1, 2, 1],
  [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
  [1, 2, 1, 2, 1, 2, 1, 2, 5, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
  [2, 1, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2],
  [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
  [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
  [2, 1, 2, 5, 2, 1, 1, 2, 1, 2, 1, 2], // 2020
  [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
  [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
  [1, 5, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
  [2, 1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
  [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
  [1, 2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1],
  [2, 2, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
  [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1], // 2030
  [2, 1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1],
  [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 5, 2],
  [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
  [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
  [2, 2, 1, 2, 1, 4, 1, 1, 2, 2, 1, 2],
  [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
  [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],
  [2, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 1],
  [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1], // 2040
  [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
  [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
  [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2]]

function lunarDate (year, month, day, leapMonth)  {
  this.year = year
  this.month = month
  this.day = day
  this.leapMonth = leapMonth
}

function lunarCalc (year, month, day, type, leapmonth) {
  let solYear, solMonth, solDay
  let lunYear, lunMonth, lunDay
  let lunLeapMonth, lunMonthDay
  let lunIndex

  let solMonthDay = [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  if (year < 2000 || year > 2040) {
    alert('2000년부터 2040년까지만 지원합니다')
    return
  }
  solYear = 2000
  solMonth = 1
  solDay = 1
  lunYear = 1999
  lunMonth = 11
  lunDay = 25
  lunLeapMonth = 0
  solMonthDay[1] = 29
  lunMonthDay = 30
  lunIndex = lunYear - 1999

  while (true) {

    if (type === 1 && year === solYear && month === solMonth && day === solDay) {
      return new lunarDate(lunYear, lunMonth, lunDay, lunLeapMonth)
    }
    else if (type === 2 && year === lunYear && month === lunMonth && day === lunDay && leapmonth === lunLeapMonth) {
      return new lunarDate(solYear, solMonth, solDay, 0)
    }

    if (solMonth === 12 && solDay === 31) {
      solYear++
      solMonth = 1
      solDay = 1

      if (solYear % 400 === 0)
        solMonthDay[1] = 29
      else if (solYear % 100 === 0)
        solMonthDay[1] = 28
      else if (solYear % 4 === 0)
        solMonthDay[1] = 29
      else
        solMonthDay[1] = 28

    } else if (solMonthDay[solMonth - 1] === solDay) {
      solMonth++
      solDay = 1
    } else
      solDay++

    if (lunMonth === 12 && ((lunarMonthTable[lunIndex][lunMonth - 1] === 1 && lunDay === 29) || (lunarMonthTable[lunIndex][lunMonth - 1] === 2 && lunDay === 30))) {
      lunYear++
      lunMonth = 1
      lunDay = 1

      if (lunYear > 2043) {
        alert("입력하신 달은 없습니다.")
        break
      }

      lunIndex = lunYear - 1999

      if (lunarMonthTable[lunIndex][lunMonth - 1] === 1)
        lunMonthDay = 29
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 2)
        lunMonthDay = 30
    } else if (lunDay === lunMonthDay) {
      if (lunarMonthTable[lunIndex][lunMonth - 1] >= 3 && lunLeapMonth === 0) {
        lunDay = 1
        lunLeapMonth = 1
      } else {
        lunMonth++
        lunDay = 1
        lunLeapMonth = 0
      }

      if (lunarMonthTable[lunIndex][lunMonth - 1] === 1)
        lunMonthDay = 29
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 2)
        lunMonthDay = 30
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 3)
        lunMonthDay = 29
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 4 &&
        lunLeapMonth === 0)
        lunMonthDay = 29
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 4 &&
        lunLeapMonth === 1)
        lunMonthDay = 30
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 5 &&
        lunLeapMonth === 0)
        lunMonthDay = 30
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 5 &&
        lunLeapMonth === 1)
        lunMonthDay = 29
      else if (lunarMonthTable[lunIndex][lunMonth - 1] === 6)
        lunMonthDay = 30
    }
    else
      lunDay++
  }
}
const todayLunar = lunarCalc(todaySeoul.getFullYear(), todaySeoul.getMonth() + 1, todaySeoul.getDate(), 1);
const todayLunarString = (todayLunar.month > 9 ? '' : '0') + (todayLunar.month) + (todayLunar.day > 9 ? '' : '0') + (todayLunar.day)

let todayIs = ''
if (alternativeHolidays.includes(todayYearString) || solarHolidays.includes(todayString) || lunaHolidays.includes(todayLunarString)) {
  todayIs = 'ho'
} else {
  todayIs = calendarDay[todaySeoul.getDay()]
}

export const todayDay = () => {
  return todayIs
}

export const isOpen = (openTime) => {
  let on = -1 // 2: 영업 전, 1: 영업 중, 0: 영업 종료, -1: 휴무

  if (!openTime || openTime.length < 1) {
    return on // -1
  }

  const todaySchedule = openTime.filter(range => {
    return range.d === todayDay()
  })
  if (todaySchedule.length < 1) {
    return on // -1
  }

  on = 0

  const now = new Date()
  const nowTime = format(now, 'HH:mm')

  let tobe = false

  for (const range of todaySchedule) {
    if (range.s && range.e) {
      if (range.s > nowTime) {
        tobe = true
      }
      if (range.s <= nowTime && range.e > nowTime) {
        on = 1
      }
    }
  }

  if (on === 0 && tobe) {
    on = 2
  }

  return on
}

export const secString = (sec) => {
  const second = Math.round(sec)
  const s = second % 60
  return Math.floor(second / 60) + ':' + (s < 10 ? '0' : '') + s
}

export const browserOsInfo = () => {
  if (browserInfo() === 'browser') {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android|Android/.test(userAgent)) {
      return 'aos'
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios'
    }
  } else {
    return 'app'
  }
}

export const goToStore = (url = '/', shorten = null, callback) => {
  let os = browserOsInfo()
  if (os === 'app') {
    window.location.href = url
  } else {
    if (os === 'ios' || os === 'aos') {
      let appURL = 'nearcle://'
      let appStoreURL = 'https://itunes.apple.com/app/nearcle/id1626357720'
      if (os === 'aos') {
        appURL += `action`
        appStoreURL = 'https://play.google.com/store/apps/details?id=com.nearcle.android.app'
      }
      if (shorten) {
        appURL += `?s=${shorten}`
      }

      let now = new Date().valueOf()
      setTimeout(function () {
        if (new Date().valueOf() - now < 5000) {
          window.location.href = appStoreURL
        }
      }, 2000)
      window.location.href = appURL

    } else {
      if (callback) {
        callback()
      }
    }
  }
}