import React, { Component, createRef } from 'react'
import { Route as ReactRouter, Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { getAuthToken } from './auth'

import SplashLoader from '../components/layout/SplashLoader'

export class Route extends Component {
  render() {
    const nodeRef = createRef(null)
    const nodeRefLoader = createRef(null)

    const {
      path,
      exact,
      content,
      privilege,
    } = this.props

    const authToken = getAuthToken()
    let redirectPath = null

    if (privilege  === 'guestOnly' && authToken) {
      redirectPath = '/'
    } else if (privilege === 'memberOnly' && !authToken) {
      redirectPath = '/signin'
    }

    return redirectPath
      ? <Redirect exact={exact}
                  from={path}
                  to={redirectPath} />
      : (
          <div>
            <ReactRouter exact={exact}
                         path={path}>
              <TransitionGroup>
                <CSSTransition key={content.type.name}
                               timeout={1200}
                               mountOnEnter={true}
                               unmountOnExit={true}
                               nodeRef={nodeRef}
                               classNames="loader">
                  <div className="with-loader"
                       ref={nodeRef}>
                    <div className="loading-content">
                      {content}
                    </div>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </ReactRouter>
            <TransitionGroup>
              <CSSTransition key={content.type.name}
                             timeout={1200}
                             nodeRef={nodeRefLoader}
                             classNames="loader">
                <div className="with-loader"
                     ref={nodeRefLoader}>
                  <SplashLoader />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
      )
  }
}
