import httpClient from '../utils/http';

export function articleList (params) {
  const url = '/a/l';

  return httpClient.post(url, params).then(response => {
    return response.data;
  });
}

export function articleInfo (params) {
  const url = '/a/i';

  return httpClient.post(url, params).then(response => {
    return response.data;
  });
}
