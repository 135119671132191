import httpClient from '../utils/http'
import { getAuthToken } from '../utils/auth'

export function countryList (params) {
  const url = '/u/c/l'
  return httpClient.get(url, params).then(response => {
    return response.data
  })
}

export function getInterestList (params) {
  const url = '/u/i/l'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function signUp (params) {
  const url = '/u/s'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function checkUserId (params) {
  const url = '/u/c/i'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function checkPassword (params) {
  const url = '/u/c/p'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function updateUserProfile (params) {
  const url = '/u/u'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function changeUserLanguage (params) {
  const url = '/u/h/l'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function changeUserNotification (params) {
  const url = '/u/h/n'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function changeUserId (params) {
  const url = '/u/h/i'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function changePassword (params) {
  const url = '/u/h/p'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function deleteAccount (params) {
  const url = '/u/d'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function userInfo () {
  const url = '/u/i'
  const authToken = getAuthToken()

  if (authToken) {
    return httpClient.post(url).then(response => {
      return response.data
    }).catch(err => console.error(err))
  } else {
    return Promise.resolve(null).catch(err => console.error(err))
  }
}

export function userInfoCount (params) {
  const url = '/u/i/c'
  const authToken = getAuthToken()

  if (authToken) {
    return httpClient.post(url, params).then(response => {
      return response.data
    })
  } else {
    return Promise.resolve(null)
  }
}

export function userPurchaseList (params) {
  const url = '/u/p/l'
  const authToken = getAuthToken()

  if (authToken) {
    return httpClient.post(url, params).then(response => {
      return response.data
    })
  } else {
    return Promise.resolve(null)
  }
}

export function notificationCount () {
  const url = '/u/n/c'
  const authToken = getAuthToken()

  if (authToken) {
    return httpClient.post(url).then(response => {
      return response.data
    }).catch(err => console.error(err))
  } else {
    return Promise.resolve(null).catch(err => console.error(err))
  }
}

export function notificationList () {
  const url = '/u/n/l'
  const authToken = getAuthToken()

  if (authToken) {
    return httpClient.post(url).then(response => {
      return response.data
    }).catch(err => console.error(err))
  } else {
    return Promise.resolve(null).catch(err => console.error(err))
  }
}

export function notificationAction (params) {
  const url = '/u/n/a'
  const authToken = getAuthToken()

  if (authToken) {
    return httpClient.post(url, params).then(response => {
      return response.data
    })
  } else {
    return Promise.resolve(null)
  }
}

export function blockUser (params) {
  const url = '/u/b'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
