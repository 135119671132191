import { createRef } from 'react'
import Component from '../../utils/Component'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { getS3Url } from '../../utils/tool'

import UiIcon from './Icon'

import './EnlargeImage.scss'

class EnlargeImage extends Component {
  onTransformed = (transformRef) => {
    const {
      imageKey,
      imageList,
      onNext
    } = this.props

    const images = imageList || [{key: imageKey}]

    if (transformRef && transformRef.current && onNext) {
      const currentIndex = images.findIndex(file => imageKey === file.key)
      const position = transformRef.current.state.positionX

      if (currentIndex > 0 && position > 50) {
        onNext(images[currentIndex - 1])
      } else if (currentIndex < images.length - 1) {
        if (position < window.innerWidth * (1 - transformRef.current.state.scale) - 50) {
          onNext(images[currentIndex + 1])
        }
      }
    }
  }

  render () {
    const {
      imageKey,
      imageRef,
      imageList,
      onClose
    } = this.props

    const images = imageList || [{key: imageKey}]
    const currentIndex = images.findIndex(file => imageKey === file.key)

    return(
      <div className="ui-enlarge-image">
        <div className="image-close">
          <UiIcon name="close white"
                  onClick={() => { if(onClose) onClose() }} />
        </div>

        <div className="image-images"
             style={{ transform: `translateX(calc(-${currentIndex * 100}vw))` }}>
          {images.map((file, i) => {
            const transformRef = createRef(null)
            return (
              <TransformWrapper ref={transformRef}
                                key={`enlarge-${i}`}>
                <TransformComponent>
                  <div className={`image-image ${imageKey === file.key ? 'active' : ''}`}
                       onClick={() => this.onTransformed(transformRef)}
                       onTouchEnd={() => this.onTransformed(transformRef)}>
                    <img src={getS3Url(file.key)}
                         alt=""
                         ref={imageKey === file.key ? imageRef : null}/>
                  </div>
                </TransformComponent>
              </TransformWrapper>
            )
          })}
        </div>

        <div className="layout-container meta-container">
          {this.props.children}
        </div>
      </div>
    )
  }
}

EnlargeImage.defaultProps = {
  imageKey: null,
  imageRef: null,
  onClose: null
}

export default EnlargeImage
