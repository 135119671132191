import { createElement } from 'react'
import sanitizeHtml from 'sanitize-html'
import Component from '../../utils/Component'

import './WysiwygContent.scss'

class UiWysiwygContent extends Component {
  render() {
    const {
      content,
      elementType,
      allowedTags,
      detectLink,
      nl2br
    } = this.props

    let result = sanitizeHtml(content, {
      allowedTags,
      allowedAttributes: {
        h1: ['class', 'style'],
        h2: ['class', 'style'],
        h3: ['class', 'style'],
        h4: ['class', 'style'],
        h5: ['class', 'style'],
        h6: ['class', 'style'],
        ol: ['class', 'style'],
        ul: ['class', 'style'],
        li: ['class', 'style'],
        img: ['src', 'alt'],
        p: ['style'],
        a: ['style', 'href', 'target'],
        strong: ['style'],
        i: ['style'],
        em: ['style'],
        s: ['style'],
        span: ['style']
      },
      allowedSchemes: [ 'data', 'http', 'https']
    })

    if (detectLink) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      result = result.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank" rel="noreferrer">' + url + '</a>'
      })
    }

    if (nl2br) {
      result = result.replaceAll('\n', '<br />')
    }

    return createElement(
      elementType,
      {
        className: 'wysiwyg',
        dangerouslySetInnerHTML: { __html: result }
      }
    )
  }
}

UiWysiwygContent.defaultProps = {
  content: '',
  elementType: 'div',
  allowedTags: ['br'],
  detectLink: true,
  nl2br: false
}

export default UiWysiwygContent
