import React from 'react';
import Component from '../../utils/Component';

import './TabBar.scss'

class TabBar extends Component {
  render() {
    const {
      buttons
    } = this.props;

    return (
      <footer className="tabbar">
        {buttons.map((button, i) => {
          return (
            <div className="tabbar-button"
                 key={`tabbar-button-${i}`}>
              {button}
            </div>
          );
        })}
      </footer>
    );
  }
}

TabBar.defaultProps = {
  buttons: []
};

export default TabBar;
