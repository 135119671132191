import React, { createRef } from 'react';
import Component from '../../utils/Component';

import UiCheck from '../../components/ui/Check';

import './CheckItem.scss'

class CheckItem extends Component {
  inputCheck = createRef();

  state = {
    value: Array.isArray(this.props.value) ?
      this.props.value :
      [this.props.value],
    thisValue: this.props.thisValue
  };

  onClick = (value) => {
    const {
      onClick
    } = this.props
    if (onClick) {
      onClick(value)
    }
  };

  onChange = (value) => {
    const {
      onChange
    } = this.props
    if (onChange) {
      onChange(value)
    }
  };

  itemOnClick = () => {
    this.inputCheck.current.inputOnClick();
  };

  componentDidUpdate() {
    const {
      value,
      thisValue
    } = this.props;

    if (thisValue !== this.state.thisValue) {
      this.setState({
        thisValue
      });
    }
    if (JSON.stringify(value) !== JSON.stringify(this.state.value)) {
      this.setState({
        value: Array.isArray(value) ? value : [value]
      });
    }
  }

  render() {
    const {
      thisValue,
      title,
      description,
      value
    } = this.props;

    let checked = this.state.value.includes(this.state.thisValue);

    return (
      <div className="check-item">
        <div className={`check-text${checked ? ' checked' : ''}`}
             onClick={this.itemOnClick}>
          {title &&
            <div className={`item-title${description ? ' with-desc' : ''}`}>
              {title}
            </div>
          }

          {description &&
            <div className="item-description">
              {description}
            </div>
          }
        </div>

        <UiCheck ref={this.inputCheck}
                 thisValue={thisValue}
                 value={value}
                 onClick={value => { this.onClick(value) }}
                 onChange={value => { this.onChange(value) }} />
      </div>
    );
  }
}

CheckItem.defaultProps = {
  thisValue: '',
  title: null,
  description: null,
  value: [],
  onChange: null,
  onClick: null
};

export default CheckItem;
