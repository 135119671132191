import { Component as ReactComponent } from 'react'

class Component extends ReactComponent {
  setStateInside = (type, key, value) => {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        [key]: value
      }
    }))
  }
}

export default Component