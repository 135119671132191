import { withRouter } from 'react-router-dom'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { getAuthToken } from '../../utils/auth'
import { format } from 'date-fns'

import { userInfoCount, userPurchaseList } from '../../api/user'

import UiButton from '../../components/ui/Button'
import UiIcon from '../../components/ui/Icon'
import UiCountry from '../../components/ui/Country'
import defaultBlankImage from '../../assets/images/asset--no-image.svg'

import './My.scss'

class My extends Component {
  state = {
    countLiked: 0,
    purchaseList: []
  }

  logoutOnClick = () => {
    const {
      action,
      history
    } = this.props

    setTimeout(() => {
      history.push('/h')
      window.location.reload(false)
    }, 500)

    action.setUserData(null)
  }

  loadLikedCount = () => {
    userInfoCount({
      type: 'liked'
    }).then(response => {
      this.setState({
        countLiked: response.data
      })
    }).catch(error => {
      console.error(error)
    })
  }

  loadPurchaseList = () => {
    userPurchaseList().then(response => {
      this.setState({
        purchaseList: response.data.list
      })
    }).catch(error => {
      console.error(error)
    })
  }

  componentDidMount () {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    action.setLayoutShowHeader(false)
    action.setLayoutShowFooter(true)

    if (userData) {
      this.loadLikedCount()
      this.loadPurchaseList()
    }
  }

  render() {
    const {
      countLiked,
      purchaseList
    } = this.state

    const {
      t,
      history,
      state
    } = this.props

    const isAuth = getAuthToken() && state.userData

    return(
      <div className="layout-container">
        <div className="my-page">
          <div className="my-profile">
            {!isAuth &&
              <div className="login">
                <UiButton text={t('common.loginSignUp')}
                          onClick={() => { history.push('/signin') }} />
              </div>
            }
            {isAuth &&
              <div className="profile"
                   onClick={() => { history.push('/my/profile') }}>
                <div className="profile-image"
                     style={{ backgroundImage: `url('${state.userData.profileImage || defaultBlankImage}')` }}> </div>
                <div className="profile-info">
                  <div className="country">
                    <UiCountry code={state.userData.country} />
                  </div>
                  <div className="nickname">
                    {state.userData.nickname}
                  </div>
                </div>
                <div className="profile-heart">
                  <UiIcon name="heart" /> {countLiked || t('common.like')}
                </div>
                {purchaseList.length > 0 &&
                  <div className="purchase-list">
                    {purchaseList.map(purchase => {
                      return (
                        <div className="purchase"
                             key={purchase.id}>
                          <div className="name">
                            {purchase.name}
                          </div>
                          <div className="date">
                            {format(new Date(purchase.useStartAt), 'yyyy. MM. dd HH:mm:ss')}
                            &nbsp;~&nbsp;
                            {format(new Date(purchase.useEndAt), 'yyyy. MM. dd HH:mm:ss')}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                }
              </div>
            }
          </div>
          <ul className="my-menu">
            {isAuth &&
              <>
                <li>
                  <button onClick={() => { history.push('/my/post') }}>
                    {t('setting.myPost.title')}
                  </button>
                </li>
                <li>
                  <button onClick={() => { history.push('/my/bookmark') }}>
                    {t('setting.bookmark.title')}
                  </button>
                </li>
                <li>
                  <button onClick={() => { history.push('/my/setting') }}>
                    {t('setting.title')}
                  </button>
                </li>
              </>
            }
            <li>
              <button onClick={() => { history.push('/service/notice') }}>
                {t('setting.notice.title')}
              </button>
            </li>
            <li>
              <button onClick={() => { history.push('/service') }}>
                {t('setting.service.title')}
              </button>
            </li>
            {isAuth &&
              <li>
                <button onClick={this.logoutOnClick}>
                  {t('common.logout')}
                </button>
              </li>
            }
          </ul>
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(My, props)
export default withRouter(StateContainer)
