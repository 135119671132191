import { withRouter } from 'react-router-dom'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import Profile from '../../pages/mypage/Profile'

class MyProfile extends Component {
  componentDidMount() {
    const {
      action
    } = this.props

    action.setLayoutShowHeader(false)
    action.setLayoutShowFooter(false)
  }

  render() {
    const {
      history
    } = this.props

    const {
      userData
    } = this.props.state

    return (
      <Profile user={userData}
               goBack={() => {
                 history.push('/my')
               }} />
    )
  }
}

const StateContainer = (props) => StateConsumer(MyProfile, props)
export default withRouter(StateContainer)
