import bcrypt from 'bcryptjs'
import { BCRYPT_SALT_ROUNDS } from '../config'

const ACCESS_TOKEN_NAME = 'accessToken'
const VERIFY_TOKEN_NAME = 'emailVerifiedToken'

export function getAuthToken () {
  return localStorage.getItem(ACCESS_TOKEN_NAME) || null
}

export function removeAuthToken () {
  localStorage.removeItem(ACCESS_TOKEN_NAME)
}

export function setAuthToken (token) {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN_NAME, token)
  } else {
    removeAuthToken()
  }
}

export function setVerifyToken (token) {
  if (token) {
    localStorage.setItem(VERIFY_TOKEN_NAME, token)
  } else {
    removeAuthToken()
  }
}
export  function getVerifyToken () {
  return localStorage.getItem(VERIFY_TOKEN_NAME) || null
}

export function removeVerifyToken () {
  localStorage.removeItem(VERIFY_TOKEN_NAME)
}

export function hashPassword (password) {
  return bcrypt.hashSync(password, Number(BCRYPT_SALT_ROUNDS))
}
