import { withRouter } from 'react-router-dom'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import UiIcon from '../../components/ui/Icon'

import './ServiceCommon.scss'

class ServiceVersion extends Component {
  componentDidMount () {
    const {
      t,
      action,
      history
    } = this.props

    action.setNavbarTitle(t('setting.version.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/service') }} />
    )
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)
  }

  render() {
    return(
      <div className="layout-container">
        <div className="service-version">
          v.1.0.1
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ServiceVersion, props)
export default withRouter(StateContainer)
