import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import constants from '../../utils/constant'

import { articleList, articleInfo } from '../../api/archive'

import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiHighlight from '../../components/ui/Highlight'
import UiWysiwygContent from '../../components/ui/WysiwygContent'

import './ServiceCommon.scss'

class ServiceFaq extends Component {
  state = {
    loading: false,
    keyword: '',
    list: [],
    open: []
  }

  keywordOnChange = (keyword) => {
    this.setState({ keyword })
  }

  toggleContent = (article) => {
    const {
      open
    } = this.state

    if (open.includes(article.id)) {
      let i = 0
      while (i < open.length) {
        if (open[i] === article.id) {
          open.splice(i, 1)
        } else {
          ++i
        }
      }
      this.setState({ open })
    } else {
      if (article.content === undefined) {
        this.setState({ loading: true })
        articleInfo({ id: article.id }).then(result => {
          article.content = result.data.content
          open.push(article.id)
          this.setState({
            loading: false,
            open
          })
        }).catch(error => {
          console.error(error)
        })
      } else {
        open.push(article.id)
        this.setState({ open })
      }
    }
  }

  loadList = () => {
    this.setState({ loading: true })
    articleList({
      id: constants.FAQ_ID
    }).then(result => {
      this.setState({
        loading: false,
        list: [...result.data.list]
      })
    }).catch(error => {
      console.error(error)
    })
  }

  componentDidMount () {
    const {
      t,
      action,
      history
    } = this.props

    action.setNavbarTitle(t('setting.faq.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/service') }} />
    )
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)

    this.loadList()
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefList = createRef(null)

    const {
      loading,
      keyword,
      list,
      open
    } = this.state

    const {
      t
    } = this.props

    const listWithKeyword = list.filter(article => {
      return !keyword || article.title.includes(keyword)
    })

    return(
      <div className="layout-container">
        <div className="service-center">
          <div className="search">
            <UiInput type="text"
                     color="fill"
                     placeholder={t('common.search')}
                     icon="search"
                     value={keyword}
                     onChange={value => this.keywordOnChange(value)} />
          </div>

          <CSSTransition in={loading}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefLoader}
                         classNames="fade">
            <div className="data-loader"
                 ref={nodeRefLoader}>
              <UiIcon name="loader" />
            </div>
          </CSSTransition>

          <CSSTransition in={list.length > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefList}
                         classNames="fade">
            <ul className="service-menu"
                ref={nodeRefList}>
              {listWithKeyword.length < 1 &&
                <li className="data-loader">
                  <UiIcon name="loader-logo" />
                </li>
              }
              {listWithKeyword.map((article, i) => {
                return (
                  <li className={`${open.includes(article.id) ? ' open' : ''}`}
                      key={`list-button-${i}`}>
                    <button onClick={() => { this.toggleContent(article) }}>
                    <span className="value-left">
                      <UiHighlight content={article.title}
                                   keyword={keyword}/>
                    </span>
                      <span className="value-right">
                  <UiIcon name="menu-arrow"/>
                </span>
                    </button>
                    <div className="content">
                      <UiWysiwygContent content={article.content}
                                        allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li',
                                          'img', 'p', 'br', 'strong', 'i', 'em', 's', 'span']} />
                    </div>
                  </li>
                )
              })}
            </ul>
          </CSSTransition>
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ServiceFaq, props)
export default withRouter(StateContainer)
