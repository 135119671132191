import Component from '../../utils/Component'
import DatePicker from 'react-mobile-datepicker'
import nextId from 'react-id-generator'
import { format } from 'date-fns'
import { StateConsumer } from '../../context/StateProvider'

import './Input.scss'

import UiIcon from '../../components/ui/Icon'

class UiInput extends Component {
  id = nextId()
  ref = null

  state = {
    value: this.props.value,
    focus: false,
    showEye: false,
    datepicker: false
  }

  cutString = (value) => {
    const {
      max
    } = this.props

    while (max && this.valueLength(value) > max) {
      const exceptLength = value.length - this.valueLength(value)
      value = value.substr(0, max + exceptLength)
    }

    return value
  }

  inputOnClick = (e) => {
    const {
      type
    } = this.props

    if (type === 'date') {
      this.ref.blur()
      this.setState({ datepicker: true })
    }
  }

  inputOnKeyUp = (e) => {
    const {
      onKeyUp,
      onEnter
    } = this.props

    if (onKeyUp) {
      onKeyUp(e.key, e.target.value, this.ref)
    }
    if (onEnter) {
      if (e.key === 'Enter') {
        onEnter(e.target.value, this.ref)
      }
    }
  }

  inputOnChange = (e) => {
    const {
      onChange
    } = this.props

    let value = this.cutString(String(e.target.value))

    this.setState({
      value
    })
    if (onChange) {
      onChange(value, this.ref)
    }
  }

  inputOnFocus = (e) => {
    const {
      onFocus
    } = this.props

    this.setState({
      focus: true
    })
    if (onFocus) {
      const value = e.target.value
      onFocus(value, this.ref)
    }
  }

  inputOnBlur = (e) => {
    const {
      onBlur
    } = this.props

    this.setState({
      focus: false
    })
    if (onBlur) {
      const value = e.target.value
      onBlur(value, this.ref)
    }
  }

  datepickerOnChange = (time) => {
    const {
      onChange
    } = this.props

    if (this.cutString(time)) {
      if (onChange) {
        onChange(format(time, 'yyyy-MM-dd'), this.ref)
      }
    }
  }

  datepickerOnCancel = () => {
    this.setState({ datepicker: false })
  }

  eyeOnClick = () => {
    this.setState(prevState => ({
      showEye: !this.state.showEye
    }))
  }

  eraserOnClick = () => {
    this.setState({
      value: ''
    })
    this.props.onChange('', this.ref)
  }

  valueLength = (val = null) => {
    const {
      value
    } = this.state

    const {
      maxExcept
    } = this.props

    let target = String(val || value)
    if (maxExcept) {
      target = target.replace(maxExcept, '')
    }
    return target.length
  }

  componentDidUpdate () {
    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  render() {
    const id = this.id

    const {
      t,
      color,
      type,
      isDisabled,
      label,
      icon,
      placeholder,
      description,
      error,
      max,
      rows,
      showEye,
      showEraser
    } = this.props

    const {
      value
    } = this.state

    let Element = 'input'
    let elementType = null
    let rowSize = rows
    if (type === 'textarea') {
      Element = 'textarea'
    } else {
      elementType = type
      rowSize = null
    }

    const sEye = type === 'password' && showEye
    const sEraser = type !== 'password' && type !== 'date' && showEraser && !isDisabled

    let className = `ui-input`
    if (color) {
      className += ` style-${color}`
    }
    if (icon) {
      className += ` has-icon`
    }
    if (error) {
      className += ` has-error`
    }
    if (max) {
      className += ` has-max`
    }
    if (sEye || sEraser) {
      className += ` show-icon`
    }

    return (
      <div className={className}>
        {label !== null &&
          <label className="input-label"
                 htmlFor={id}>
            {label}
          </label>
        }

        <div className="input-wrap">
          {type === 'date' &&
            <DatePicker value={value ? (typeof value === 'string' ? new Date(value) : value) : new Date()}
                        theme="default"
                        showHeader={false}
                        showFooter={false}
                        isPopup={false}
                        confirmText={t('common.completeSelect')}
                        cancelText={t('common.cancel')}
                        onSelect={this.datepickerOnCancel}
                        onChange={this.datepickerOnChange}
                        onCancel={this.datepickerOnCancel} />
          }

          {type !== 'date' &&
            <Element id={id}
                     ref={el => this.ref = el}
                     type={this.state.showEye ? 'text' : elementType}
                     placeholder={placeholder}
                     disabled={isDisabled}
                     value={value}
                     rows={rowSize}
                     onClick={this.inputOnClick}
                     onKeyUp={this.inputOnKeyUp}
                     onChange={this.inputOnChange}
                     onFocus={this.inputOnFocus}
                     onBlur={this.inputOnBlur} />
          }

          {icon &&
            <div className="input-icon">
              <UiIcon name={icon} />
            </div>
          }

          {sEye &&
            <div className="input-eye">
              <UiIcon name={'eye-slash' + (this.state.showEye ? ' hide' : '')} />
              <UiIcon name="eye"
                      onClick={this.eyeOnClick} />
            </div>
          }

          {sEraser &&
            <div className="input-eraser">
              <UiIcon name={'close-circle' + (!this.state.focus || !value ? ' hide' : '')}
                      onClick={this.eraserOnClick} />
            </div>
          }
        </div>

        {description !== null &&
          <div className="input-description">
            {description}
          </div>
        }

        {error !== null &&
          <div className="input-error">
            {error}
          </div>
        }

        {max !== null &&
          <div className="input-max">
            {this.valueLength()}/{max}
          </div>
        }
      </div>
    )
  }
}

UiInput.defaultProps = {
  color: 'line',
  type: 'text',
  isDisabled: false,
  label: null,
  icon: null,
  value: '',
  rows: 3,
  placeholder: null,
  description: null,
  error: null,
  max: null,
  maxExcept: null,
  showEye: true,
  showEraser: true,
  onKeyUp: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onEnter: null
}

const StateContainer = (props) => StateConsumer(UiInput, props)
export default StateContainer
