import { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { getUrl } from '../api/home'

import { browserOsInfo, goToStore } from '../utils/tool'

import UiIcon from '../components/ui/Icon'

import './Shared.scss'

class Shared extends Component {
  goToUrl () {
    const {
      match
    } = this.props

    getUrl({
      url: match.params.url
    }).then(response => {
      window.location.href = response.data.href
    }).catch(e => {
      console.error(e)
    })
  }

  componentDidMount () {
    const {
      match
    } = this.props

    if (browserOsInfo() === 'app') {
      this.goToUrl()
    } else {
      goToStore(`/s/${match.params.url}`,  match.params.url,() => {
        this.goToUrl()
      })
    }
  }

  render() {
    return(
      <div className="shared">
        <div className="data-loader">
          <UiIcon name="logo-blue" />
        </div>
      </div>
    )
  }
}

export default withRouter(Shared)
