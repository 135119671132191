import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { objectToForm, br2nl, removeTags } from '../../utils/tool'

import { boardPostCommentCreate } from '../../api/community'

import UiIcon from '../../components/ui/Icon'
import UiAlert from '../../components/ui/layer/Alert'

import './BoardPostCommentCreate.scss'

class CommunityBoardPostCommunityCreate extends Component {
  state = {
    loading: false,
    showAlert: {
      contentRequired: false,
      timeLimit: false
    },
    value: {
      content: ''
    },
    error: {
      content: null
    },
    contentHeight: 51
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
    setTimeout(() => {
      this.setContentHeight()
    }, 0)
  }

  onCancelEditing = () => {
    const {
      onCancelEditing
    } = this.props

    this.inputOnChange('content', '')
    onCancelEditing()
  }

  onPost = () => {
    const {
      value,
    } = this.state

    const {
      replying,
      onEndReplying
    } = this.props

    const {
      userData
    } = this.props.state

    if (userData) {
      if (!value.content) {
        this.toggleAlert('contentRequired')
      } else {
        const {
          board,
          post,
          editing,
          afterComment
        } = this.props

        this.setState({loading: true})

        const values = {
          board: board.id,
          post: post.id,
          ...value
        }

        if (editing) {
          values.id = editing.id
        }

        if (replying) {
          values.parent = replying.id
        }

        boardPostCommentCreate(objectToForm(values)).then(response => {
          this.setState({loading: false})
          this.inputOnChange('content', '')
          if (afterComment) {
            afterComment(response.data)
          }
          if (onEndReplying) {
            onEndReplying()
          }
        }).catch(error => {
          this.setState({loading: false})
          if (error.message === '"Time Limit"') {
            this.toggleAlert('timeLimit')
          } else {
            this.toggleAlert('unknown')
          }
        })
      }
    }
  }

  onClick = () => {
    const {
      action
    } = this.props

    const {
      userData
    } = this.props.state

    if (!userData) {
      action.setLayoutShowLogin(true)
    }
  }

  setContentHeight = () => {
    const inputEl = document.getElementById('commentInput')
    inputEl.style.height = 'inherit';
    inputEl.style.height = `${inputEl.scrollHeight - 22}px`;
    this.setState({
      contentHeight: inputEl.scrollHeight
    })
  }

  componentDidUpdate (prevProps) {
    const {
      editing
    } = this.props

    if (editing && JSON.stringify(prevProps.editing) !== JSON.stringify(editing)) {
      this.inputOnChange('content', removeTags(br2nl(editing.content)))
    }
  }

  render () {
    const nodeRefLoader = createRef(null)
    const {
      loading,
      showAlert,
      value
    } = this.state

    const {
      t,
      editing,
      replying,
      onEndReplying
    } = this.props

    const {
      userData
    } = this.props.state

    return(
      <div className="board-post-comment"
           onClick={this.onClick}>
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        <div className="layout-container">
          {replying &&
            <div className="replyiing">
              <em>{t('community.reply')}</em>
              {replying.summary}
              <UiIcon name="close-circle"
                      onClick={onEndReplying} />
            </div>
          }

          {editing &&
            <div className="replyiing">
              <em>{t('community.edit')}</em>
              {editing.summary}
              <UiIcon name="close-circle"
                      onClick={this.onCancelEditing} />
            </div>
          }

          <div className={`content ${replying ? 'replying' : ''}`}>
            <div className="reply-icon">
              <UiIcon name="reply" />
            </div>

            <textarea id="commentInput"
                      placeholder={t('community.placeholder.comment')}
                      value={value.content}
                      rows="1"
                      disabled={!userData}
                      onInput={this.setContentHeight}
                      onChange={(e) => { this.inputOnChange('content', String(e.target.value)) }} />
          </div>

          <div className="button">
            <UiIcon name="send"
                    activeName="send active"
                    onClick={this.onPost} />
          </div>
        </div>
        
        {showAlert.contentRequired &&
          <UiAlert content={t('community.error.comment')}
                   onOkay={() => {
                     this.toggleAlert('contentRequired')
                   }} />
        }
        {showAlert.timeLimit &&
          <UiAlert content={t('community.error.commentTimeLimit')}
                   onOkay={() => {
                     this.toggleAlert('timeLimit')
                   }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(CommunityBoardPostCommunityCreate, props)
export default withRouter(StateContainer)
