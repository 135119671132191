import httpClient from '../utils/http'

export function bannerList (params) {
  const url = '/h/b'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function communityArticle (params) {
  const url = '/h/c'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function getUrl (params) {
  const url = '/h/u'
  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
