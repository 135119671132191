import { REACT_APP_GOOGLE_MAP_API_KEY } from '../../config'

import { createRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { withRouter } from 'react-router-dom'
import { StateConsumer } from '../../context/StateProvider'
import GoogleMap from 'google-map-react'
import supercluster from 'points-cluster'
import Component from '../../utils/Component'
import { bodyScroll, distBetweenCoords, getS3Url, historyPushState, isOpen, browserInfo } from '../../utils/tool'
import { getAuthToken } from '../../utils/auth'
import constants from '../../utils/constant'

import { placeList, placeInfo, placeBookmark } from '../../api/place'

import MapPlace from '../../pages/place/MapPlace'

import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiConfirm from '../../components/ui/layer/Confirm'
import UiAlert from '../../components/ui/layer/Alert'
import UiDropdown from '../../components/ui/layer/Dropdown'
import UiTxt from '../../components/ui/Txt'

import './Map.scss'

class PlaceMap extends Component {
  state = {
    agreeTerm: false,
    showAlert: {
      term: false,
      showTerm: false,
      noLocation: false,
      freeDocentEnd: false,
      toApp: false
    },
    loading: false,
    searchLoading: false,
    hasPlaceId: false,
    loadMap: false,
    loadSearch: false,
    noLocation: true,
    mapDraggable: true,
    mapOptions: {
      minZoom: 6,
      maxZoom: 18,
      clickableIcons: false,
      disableDefaultUI: true,
      backgroundColor: '#F6F6FA',
      gestureHandling: 'greedy',
      styles: [
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#fcfcfc"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#e2f3d8"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "stylers": [
            {
              "color": "#e2f3d8"
            },
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ebebeb"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#d9dce0"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#bfc4ca"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#f0f0f0"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.station.airport",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#72bdf7"
            }
          ]
        },
        {
          "featureType": "transit.station.airport",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "transit.station.bus",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#72bdf7"
            }
          ]
        },
        {
          "featureType": "transit.station.rail",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#72bdf7"
            }
          ]
        },
        {
          "featureType": "transit.station.rail",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#d8edfd"
            }
          ]
        }
      ]
    },
    mapMarkers: [],
    mapClusters: [],
    mapBounds: {},
    mapLocation: { lat: 37.5642135, lng: 127.0016985 },
    initialMapLocation: { lat: 37.5642135, lng: 127.0016985 },
    currentLocation: { lat: 37.5642135, lng: 127.0016985 },
    lastLocation: null,
    lastZoom: 11,
    locating: false,
    zoom: 11,
    watchLocation: null,
    map: null,
    animationTimer: null,
    list: [],
    recommendList: [],
    clicked: false,
    placeClicked: null,
    clusterClicked: null,
    initialDrag: 0,
    initialHeight: 0,
    listHeight: false,
    showRead: false,
    place: null,
    keyword: '',
    keywordTimer: null,
    lastKeyword: '',
    placeTypes: ['photo', 'attra', 'resta', 'club'],
    placeType: [],
    bookmarkList: null,
    docentCountLimit: 100,
    freeDocentCountLimit: 3
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  getCurrentLocation (successCallback, errorCallback) {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng }
        this.setState({ currentLocation: pos })
        if (successCallback) {
          successCallback()
        }
      }
      ,(error) => {
        console.error(error)
        if (errorCallback) {
          errorCallback()
        }
      })
  }

  locating () {
    const {
      locating
    } = this.state

    if (locating) {
      this.endLocating()
    } else {
      this.startLocating()
    }
  }

  goCurrentLocation () {
    const {
      currentLocation
    } = this.state

    this.setState({ mapLocation: currentLocation })
  }

  startLocating () {
    const {
      map,
      currentLocation
    } = this.state
    this.setState({ loading: true })

    if (map) {
      this.limitPosition(currentLocation)
    }
    const watchLocation = navigator?.geolocation.watchPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng }
        this.setState({
          loading: false,
          locating: true,
          currentLocation: pos,
          mapLocation: pos,
          noLocation: false
        })
        map.panTo(pos)
      }
      ,(error) => {
        this.toggleAlert('noLocation')
        this.endLocating()
        console.error(error)
      }
    )
    this.setState({ watchLocation: watchLocation })
  }

  endLocating () {
    const {
      locating,
      watchLocation
    } = this.state

    if (locating) {
      navigator?.geolocation.clearWatch(watchLocation)
      this.setState({ locating: false })
    }
  }

  setMap (map) {
    this.setState({ map: map.map })
    this.loadList()
  }

  centerChanged ({ center, zoom, bounds }) {
    const {
      map,
      clicked,
      placeClicked,
      clusterClicked,
      lastLocation,
      lastZoom,
      keyword,
      lastKeyword
    } = this.state

    if (clicked) {
      if (placeClicked) {
        if (center.lat.toFixed(6) !== placeClicked.coord.coordinates[1].toFixed(6) || center.lng.toFixed(6) !== placeClicked.coord.coordinates[0].toFixed(6)) {
          this.removeClickedPlace()
          this.setMarkerCluster(center, zoom, bounds)
        }
      } else if (clusterClicked) {
        if (center.lat.toFixed(6) !== clusterClicked.lat.toFixed(6) || center.lng.toFixed(6) !== clusterClicked.lng.toFixed(6)) {
          this.removeClickedPlace()
          this.setMarkerCluster(center, zoom, bounds)
        }
      }
    }
    if (!clicked && map) {
      let distance = -1
      if (lastKeyword === keyword) {
        if (lastLocation) {
          distance = distBetweenCoords(lastLocation, center)
        }
      }
      if (distance === -1 || distance > (keyword ? 1 : 0.2) || lastZoom - zoom > 2) {
        this.loadList()
      } else {
        this.setMarkerCluster(center, zoom, bounds)
      }
    }
  }

  loadList () {
    const {
      map,
      keyword,
      lastKeyword,
      placeType,
      bookmarkList,
      hasPlaceId,
      showRead
    } = this.state

    const {
      userData
    } = this.props.state

    if (map) {
      const center = map.getCenter()
      const zoom = map.getZoom()
      const b = map.getBounds()
      const areaBounds = {
        n: b.getNorthEast().lat(),
        s: b.getSouthWest().lat(),
        e: b.getNorthEast().lng(),
        w: b.getSouthWest().lng()
      }
      const bounds = {
        ne: { lat: areaBounds.n, lng: areaBounds.e },
        nw: { lat: areaBounds.n, lng: areaBounds.w },
        se: { lat: areaBounds.s, lng: areaBounds.e },
        sw: { lat: areaBounds.s, lng: areaBounds.w }
      }
      const location = { lat: center.lat(), lng: center.lng() }

      this.setState({ lastLocation: location, lastZoom: zoom })
      const listParam = { ...location, zoom, keyword, bookmark: !bookmarkList }
      if (placeType.length > 0) {
        listParam.type = placeType
      }
      if (hasPlaceId) {
        listParam.needPlace = hasPlaceId
      }
      placeList(listParam).then(response => {
        this.setState({
          list: response.data.list,
          mapMarkers: response.data.list.map(p => { return { ...p, lat: p.coord.coordinates[1], lng: p.coord.coordinates[0] }}),
          searchLoading: false
        })
        this.setRecommendList()
        if (userData && !bookmarkList) {
          this.setState({ bookmarkList: response.data.bookmark })
        }

        if (keyword !== lastKeyword && response.data.list.length) {
          const bound = new window.google.maps.LatLngBounds()
          response.data.list.forEach(place => {
            bound.extend(new window.google.maps.LatLng(
              place.coord.coordinates[1],
              place.coord.coordinates[0]
            ))
          })
          map.fitBounds(bound, () => {
            this.setMarkerCluster(center, zoom, bounds)
          })
          this.setState({ lastKeyword: keyword })
        } else {
          this.setMarkerCluster(center, zoom, bounds)
        }
        this.limitPosition()
        if (hasPlaceId) {
          if (showRead <= 0) {
            const place = response.data.list.find(el => el.id === hasPlaceId)
            setTimeout(() => {
              this.onClickPlace(place)
            }, 0)
          }
        }
        this.setState({ hasPlaceId: false })
      }).catch(error => {
        console.error(error)
        this.setState({ hasPlaceId: false })
      })
    }
  }

  setMarkerCluster (center, zoom, bounds) {
    const {
      mapMarkers
    } = this.state

    const cluster = supercluster(mapMarkers, {
      minZoom: 0,
      maxZoom: 16,
      // radius: 20,
      radius: 0,
    })

    const clusters = cluster({ center, zoom, bounds }).map(({ wx, wy, numPoints, points }) => ({
      lat: wy,
      lng: wx,
      numPoints,
      id: `${numPoints}_${points[0].id}`,
      ids: points.map(p => p.id),
      points
    }))

    this.setState({ mapClusters: clusters })
  }

  setRecommendList () {
    const {
      list
    } = this.state
    const recommendList = [...list].sort((a, b) => b.starPointShow - a.starPointShow).slice(0, 10)
    this.setState({ recommendList })
  }

  hideList () {
    if (this.nodeRefList['current']) {
      this.nodeRefList['current'].classList.add('animating')
      this.nodeRefList['current'].style.height = '1px'
    }
    this.setState({ listHeight: false })
    if (this.nodeRefList['current']) {
      setTimeout(() => {
        if (this.nodeRefList['current']) {
          this.nodeRefList['current'].classList.remove('animating')
        }
      }, 3000)
    }
  }

  onClickCluster (cluster) {
    const {
      map,
      clicked
    } = this.state

    this.setState({ placeClicked: null })

    let timeout = 0
    if (clicked) {
      timeout = 300
      this.setState({ clicked: false })
    }
    setTimeout(() => {
      this.setState({ clicked: true, clusterClicked: cluster })
    }, timeout)
    map.panTo({
      lat: cluster.lat,
      lng: cluster.lng
    })

    this.hideList()
  }

  onClickPlace (place) {
    const {
      map,
      clicked,
      clusterClicked
    } = this.state

    const notInCluster = !clusterClicked || !clusterClicked.ids.includes(place.id)

    if (clusterClicked && !clusterClicked.ids.includes(place.id)) {
      this.setState({ clusterClicked: null })
    }

    let timeout = 0
    if (clicked && notInCluster) {
      timeout = 300
      this.setState({ clicked: false })
    }
    setTimeout(() => {
      this.setState({ clicked: true, placeClicked: place })
    }, timeout)
    if (notInCluster) {
      map.panTo({
        lat: place.coord.coordinates[1],
        lng: place.coord.coordinates[0]
      })
    }

    this.hideList()
  }

  removeClickedPlace (timeout = 300) {
    this.setState({ clicked: false, clusterClicked: null  })
    setTimeout(() => {
      this.setState({ placeClicked: null })
    }, timeout)
  }

  calcDistance (coord) {
    const {
      currentLocation
    } = this.state

    const distanceInMeter = Math.round(distBetweenCoords(currentLocation, { lng: coord.coordinates[0], lat: coord.coordinates[1] }) * 1000)
    if (distanceInMeter > 999) {
      const distanceInKilometer = Math.round(distanceInMeter / 1000)
      if (distanceInKilometer > 10) {
        return '10+km'
      }
      return distanceInKilometer + 'km'
    }
    return distanceInMeter + 'm'
  }

  listDragStart (e, mouse = true) {
    if (mouse) {
      const img = new Image()
      e.dataTransfer.setDragImage(img, 0, 0)
    }

    this.removeClickedPlace()

    this.setState({
      initialDrag: (mouse ? e.clientY : e.touches[0].pageY),
      initialHeight: this.nodeRefList['current'].offsetHeight
    })
  }

  listDrag (e, mouse = true) {
    if ((mouse ? e.clientY : e.touches[0].pageY) !== 0) {
      const {
        initialHeight,
        initialDrag
      } = this.state

      const dragTick = initialDrag - (mouse ? e.clientY : e.touches[0].pageY)
      this.nodeRefList['current'].style.height = (initialHeight + dragTick) + 'px'
    }
  }

  listDragEnd () {
    const {
      initialHeight
    } = this.state

    if (this.nodeRefList['current']) {
      this.nodeRefList['current'].classList.add('animating')
      const currentHeight = this.nodeRefList['current'].offsetHeight
      if (initialHeight < currentHeight) {
        this.nodeRefList['current'].style.height = '100vh'
        this.setState({ listHeight: true })
      } else {
        this.nodeRefList['current'].style.height = '1px'
        this.setState({ listHeight: false })
      }
      setTimeout(() => {
        if (this.nodeRefList['current']) {
          this.nodeRefList['current'].classList.remove('animating')
        }
      }, 3000)
    }
  }

  closeList () {
    if (this.nodeRefList['current']) {
      this.nodeRefList['current'].classList.add('animating')
      this.nodeRefList['current'].style.height = '1px'
      this.setState({ listHeight: false })
      setTimeout(() => {
        if (this.nodeRefList['current']) {
          this.nodeRefList['current'].classList.remove('animating')
        }
      }, 3000)
    }
  }

  openRead = (place) => {
    const {
      docentCountLimit,
      freeDocentCountLimit
    } = this.state

    const {
      location
    } = this.props

    const {
      userData
    } = this.props.state

    if (!userData) {
      const lastCount = parseInt(localStorage.getItem('docentCount') || 0)
      if (lastCount >= docentCountLimit) {
        const {
          action
        } = this.props
        action.setLayoutShowLogin(true)

        return
      } else {
        localStorage.setItem('docentCount', lastCount + 1)
      }
    } else {
      if (!userData.purchaseList.includes(constants.PLAN01_ID)) {
        const lastCount = parseInt(localStorage.getItem('freeDocentCount') || 0)
        if (lastCount >= freeDocentCountLimit) {
          this.toggleAlert('freeDocentEnd')

          return
        } else {
          localStorage.setItem('freeDocentCount', lastCount + 1)
        }
      }
    }

    this.setState({
      place: null
    })

    const loadingTimer = setTimeout(() => {
      this.setState({
        loading: true
      })
    }, 300)

    return placeInfo({
      id: place.id
    }).then(response => {
      clearTimeout(loadingTimer)
      this.setState({
        place: response.data,
        loading: false,
        showRead: 1
      })

      if (!location.state || !location.state.place) {
        historyPushState(window.location.pathname + '?p=' + place.id, { place: place.id })
      }
    }).catch(error => {
      console.error(error)
    })
  }

  setBookmark = (place) => {
    const {
      bookmarkList
    } = this.state

    if (bookmarkList) {
      if (bookmarkList.includes(place.id)) {
        bookmarkList.splice(bookmarkList.indexOf(place.id), 1)
      } else {
        bookmarkList.push(place.id)
      }
      placeBookmark({ place: place.id }).catch(err => {
        console.error(err)
      })
      this.setState({ bookmarkList })
    }
  }

  closeRead = (backButton = false) => {
    this.setState({
      showRead: -1,
      place: -1
    })

    if (backButton) {
      window.history.back()
    }
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  detectPlace = (init = false) => {
    const {
      showRead
    } = this.state

    const params = new URLSearchParams(window.location.search)
    if (params) {
      const placeId = params.get('p')
      if (placeId) {
        if (init) {
          this.setState({ hasPlaceId: placeId })
        }
        return true
      } else {
        if (showRead > 0) {
          this.closeRead()
        }
        return false
      }
    } else {
      this.closeRead(false)
    }
  }

  toggleType = (type) => {
    const {
      placeType
    } = this.state

    let newPlaceType = placeType
    if (placeType.includes(type)) {
      newPlaceType.splice(newPlaceType.indexOf(type), 1)
    } else {
      newPlaceType.push(type)
    }
    this.setState({ placeType: newPlaceType })
    this.loadList()
  }

  keywordOnChange = (keyword) => {
    const {
      keywordTimer
    } = this.state
    clearTimeout(keywordTimer)

    this.setState({
      searchLoading: true,
      keyword,
      keywordTimer: setTimeout(() => {
        this.onSearch()
      }, 1500)
    })
  }

  onSearch = () => {
    this.loadList()
  }

  limitPosition = (location) => {
    const {
      map,
      mapOptions
    } = this.state

    if (map) {
      const center = map.getCenter()
      let lat = location ? location.lat : center.lat()
      let lng = location ? location.lng : center.lng()
      if (lat > 38.9 || lat < 33.0 || lng < 124.5 || lng > 132.0) {
        lat = 37.5642135
        lng = 127.0016985
        map.setZoom(mapOptions.minZoom)
        map.panTo({ lat, lng })
      } else if (lat > 37.715133 || lat < 37.413294 || lng < 126.734086 || lng > 127.269311) {
        if (lat > 37.715133) lat = 37.71513
        else if (lat < 37.413294) lat = 37.413294
        if (lng < 126.734086) lng = 126.734086
        else if (lng > 127.269311) lng = 127.269311
        map.panTo({ lat, lng })
      }
    }
  }

  purchasePlan = (itemId) => {
    this.toggleAlert('freeDocentEnd')

    if (window.nearcleAndroid) {
      window.nearcleAndroid.purchaseItem(itemId)
    } else if (window.webkit && window.webkit.messageHandlers.nearcleIos) {
      window.webkit.messageHandlers.nearcleIos.postMessage(JSON.stringify({
        action: 'purchaseItem',
        data: itemId
      }))
    } else {
      this.toggleAlert('toApp')
    }
  }

  initLocating = () => {
    this.getCurrentLocation(() => {
      this.goCurrentLocation()
      this.setState({ noLocation: false })
      this.startLocating()
    }, () => {
      this.setState({ noLocation: true })
    })
  }

  agreeTerm = () => {
    this.setState({ agreeTerm: true })
    localStorage.setItem('locationTerm', 'true')
    this.toggleAlert('term')
  }

  disagreeTerm = () => {
    this.setState({ agreeTerm: false })
    localStorage.setItem('locationTerm', 'false')
    this.toggleAlert('term')
  }

  componentDidMount () {
    bodyScroll(true)
    const {
      action
    } = this.props

    const authToken = getAuthToken()

    action.setLayoutShowHeader(false)
    action.setLayoutShowFooter(true)

    this.setState({ noLocation: true })
    setTimeout(() => {
      this.setState({ loadMap: true })
      setTimeout(() => {
        this.setState({ loadSearch: true })
      }, 500)
    }, 500)
    this.detectPlace(true)

    const agreed = localStorage.getItem('locationTerm') === 'true'
    this.setState({ agreeTerm: agreed })

    if (!authToken && !agreed) {
      this.toggleAlert('term')
    }

    if (!localStorage.getItem('docentCount')) {
      localStorage.setItem('docentCount', '0')
    }
    if (!localStorage.getItem('freeDocentCount')) {
      localStorage.setItem('freeDocentCount', '0')
    }

    window.addEventListener('popstate', this.detectPlace)
  }

  componentWillUnmount () {
    bodyScroll(false)
    this.setState({ loadSearch: false })

    const {
      watchLocation
    } = this.state

    window.removeEventListener('popstate', this.detectPlace)
    navigator?.geolocation.clearWatch(watchLocation)
  }

  renderListItem (place) {
    const {
      t
    } = this.props

    const {
      bookmarkList
    } = this.state

    const placeOpen = isOpen(place.openTime)
    return (
      <div className="place-item"
           key={`list-${place.id}`}>
        {place.thumbnail &&
          <div className="thumbnail"
               onClick={() => this.openRead(place)}
               style={{ backgroundImage: `url(${getS3Url(place.thumbnail)})` }}/>
        }
        <div className="info">
          <div className="title"
               onClick={() => this.openRead(place)}>
            <h4>{place.title}</h4>
            {!!bookmarkList &&
              <div className="bookmark">
                <UiIcon name={`bookmark ${bookmarkList.includes(place.id) ? 'active' : ''}`} /><br/>
              </div>
            }
          </div>
          <div className="meta"
               onClick={() => this.openRead(place)}>
            <span className="item">
              <UiIcon name="star-point" />
              {place.starPointShow}<span>/5</span>
            </span>
            {placeOpen === 2 &&
              <span className="item open">
                  <UiIcon name="time red"/>
                {t('place.open.tobe')}
              </span>
            }
            {placeOpen === 1 &&
              <span className="item open">
                <UiIcon name="time"/>
                {t('place.open.ing')}
              </span>
            }
            {placeOpen === 0 &&
              <span className="item open">
                <UiIcon name="time red"/>
                {t('place.open.end')}
              </span>
            }
            {placeOpen === -1 &&
              <span className="item open">
                <UiIcon name="time red"/>
                {t('place.open.vacation')}
              </span>
            }
          </div>
          <div className="description">
            <p onClick={() => this.openRead(place)}>
              {place.summary}
            </p>
            <span className="distance"
                  onClick={() => this.onClickPlace(place)}>
              <UiIcon name="place" />
              {this.calcDistance(place.coord)}
            </span>
          </div>
        </div>
      </div>
    )
  }

  nodeRefList = createRef(null)

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefSearch = createRef(null)
    const nodeRefRead = createRef(null)
    const nodeRefPointList = createRef(null)

    const {
      agreeTerm,
      showAlert,
      loading,
      loadMap,
      loadSearch,
      noLocation,
      mapDraggable,
      mapOptions,
      mapLocation,
      mapClusters,
      currentLocation,
      zoom,
      locating,
      recommendList,
      clicked,
      placeClicked,
      clusterClicked,
      listHeight,
      place,
      showRead,
      keyword,
      placeTypes,
      placeType,
      searchLoading,
      bookmarkList
    } = this.state

    const {
      t,
      i18n
    } = this.props

    const {
      userData
    } = this.props.state

    let placeOpen = -1
    if (placeClicked) {
      placeOpen = isOpen(placeClicked.openTime)
    }

    const termAgreed = userData || agreeTerm

    return(
      <div className="place-map">
        {loadMap &&
          <>
            <CSSTransition in={loadSearch}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefSearch}
                           classNames="pop-top">
              <div className="map-search"
                   ref={nodeRefSearch}>
                <UiInput type="text"
                         color="float"
                         placeholder={t('place.search.by')}
                         icon="search"
                         value={keyword}
                         onChange={value => this.keywordOnChange(value)} />

                <div className="place-type">
                  {placeTypes.map((type, i) => {
                    return (
                      <UiButton text={<><UiIcon name={`${type} ${placeType.includes(type) ? ' white' : ''}`} />{t('place.' + type)}</>}
                                key={`place-type-${i}`}
                                color={placeType.includes(type) ? 'blue' : 'white'}
                                isSmall={true}
                                onClick={() => this.toggleType(type)} />
                    )
                  })}
                </div>
              </div>
            </CSSTransition>

            <div className={`map-container
                            ${recommendList.length > 0 ? 'map-recommend' : ''}
                            ${searchLoading ? 'map-loading' : ''}
                            ${recommendList.length < 1 && !searchLoading ? 'map-no-result' : ''}`}>
              <GoogleMap bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAP_API_KEY, region: 'KR', language: i18n.language }}
                         defaultZoom={zoom}
                         defaultCenter={mapLocation}
                         options={{
                           ...mapOptions,
                           gestureHandling: mapDraggable ? 'greedy' : 'none'
                         }}
                         yesIWantToUseGoogleMapApiInternals={true}
                         onGoogleApiLoaded={(map) => this.setMap(map)}
                         onDrag={() => this.endLocating()}
                         onChange={(...props) => this.centerChanged(...props)}>
                {(!noLocation && locating) &&
                  <span className="current active"
                        lat={currentLocation.lat}
                        lng={currentLocation.lng} />
                }

                {mapClusters.map((cluster, i) => {
                  if (cluster.numPoints === 1) {
                    const inBookmark = bookmarkList && bookmarkList.includes(cluster.points[0].id)
                    return (
                      <span key={`place-pin-${i}`}
                            className={`pin
                              ${cluster.points[0].type}
                              ${cluster.points[0].starPointShow < 3 ? 'level-1' : (cluster.points[0].starPointShow < 5 ? 'level-2' : 'level-3')}
                              ${clicked && placeClicked && placeClicked.id === cluster.points[0].id ? 'active' : ''}
                              ${inBookmark ? 'bookmark' : ''}`}
                            lat={cluster.lat}
                            lng={cluster.lng}
                            onClick={() => this.onClickPlace(cluster.points[0])}>
                        <svg className="pin-svg"
                             width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2,20c0,1.7,0.2,2.9,0.6,4.5C4.5,32.4,11,41.7,22,48c11-6.3,17.5-15.6,19.4-23.5c0.4-1.5,0.6-2.8,0.6-4.5M22,28c-4.4,0-8-3.6-8-8h16C30,24.4,26.4,28,22,28z" fill="#3154FF"/>
                        </svg>
                        {!inBookmark && <>
                          {cluster.points[0].type === 'attra' &&
                            <UiIcon name="spot-attra" />
                          }
                          {cluster.points[0].type === 'photo' &&
                            <UiIcon name="spot-photo" />
                          }
                          {cluster.points[0].type === 'resta' &&
                            <UiIcon name="spot-resta" />
                          }
                          {cluster.points[0].type === 'club' &&
                            <UiIcon name="spot-club" />
                          }
                          {cluster.points[0].type === 'audio' &&
                            <UiIcon name="spot-audio" />
                          }
                        </>}
                        {inBookmark &&
                          <UiIcon name="bookmark active" />
                        }
                    </span>
                    )
                  }

                  return (
                    <span className="cluster-wrap"
                          key={`place-cluster-${i}`}
                          lat={cluster.lat}
                          lng={cluster.lng}>
                      <span className={`pin cluster
                              ${clicked && clusterClicked && clusterClicked.id === cluster.id ? 'active' : ''}
                              ${bookmarkList && bookmarkList.some(id => cluster.ids.includes(id)) ? 'bookmark' : ''}`}
                            onClick={() => this.onClickCluster(cluster)}>
                        <svg className="pin-svg"
                             width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2,20c0,1.7,0.2,2.9,0.6,4.5C4.5,32.4,11,41.7,22,48c11-6.3,17.5-15.6,19.4-23.5c0.4-1.5,0.6-2.8,0.6-4.5M22,28c-4.4,0-8-3.6-8-8h16C30,24.4,26.4,28,22,28z" fill="#3154FF"/>
                        </svg>
                        {(bookmarkList && bookmarkList.some(id => cluster.ids.includes(id))) &&
                          <UiIcon name="bookmark active" />
                        }
                        <span className="points">
                          {cluster.numPoints > 9 ? '9+' : cluster.numPoints}
                        </span>
                      </span>

                      <CSSTransition in={clicked && clusterClicked && clusterClicked.id === cluster.id}
                                     timeout={500}
                                     mountOnEnter={true}
                                     unmountOnExit={true}
                                     nodeRef={nodeRefPointList}
                                     classNames="pop-bottom">
                        <span className="point-list"
                              ref={nodeRefPointList}
                              onMouseEnter={() => { this.setState({ mapDraggable: false }) }}
                              onMouseLeave={() => { this.setState({ mapDraggable: true }) }}
                              onTouchStart={() => { this.setState({ mapDraggable: false }) }}
                              onTouchEnd={() => { this.setState({ mapDraggable: true }) }}>
                            {cluster.points.map((p, j) => { return (
                            <span className={`point 
                                    ${clicked && placeClicked && placeClicked.id === p.id ? 'active' : ''}`}
                                  key={`place-item-${j}`}
                                  onClick={() => this.onClickPlace(p)}>
                              <span className={`pin
                                    ${p.starPointShow < 3 ? 'level-1' : (p.starPointShow < 5 ? 'level-2' : 'level-3')}
                                    ${bookmarkList && bookmarkList.includes(p.id) ? 'bookmark' : ''}`}>
                                <svg className="pin-svg"
                                     width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2,20c0,1.7,0.2,2.9,0.6,4.5C4.5,32.4,11,41.7,22,48c11-6.3,17.5-15.6,19.4-23.5c0.4-1.5,0.6-2.8,0.6-4.5M22,28c-4.4,0-8-3.6-8-8h16C30,24.4,26.4,28,22,28z" fill="#3154FF"/>
                                </svg>
                                {p.type === 'attra' &&
                                  <UiIcon name="spot-attra" />
                                }
                                {p.type === 'photo' &&
                                  <UiIcon name="spot-photo" />
                                }
                                {p.type === 'resta' &&
                                  <UiIcon name="spot-resta" />
                                }
                                {p.type === 'club' &&
                                  <UiIcon name="spot-club" />
                                }
                                {p.type === 'audio' &&
                                  <UiIcon name="spot-audio" />
                                }
                                {(bookmarkList && bookmarkList.includes(p.id)) &&
                                  <UiIcon name="bookmark active" />
                                }
                              </span>
                              <span className="point-name">
                                {p.title}
                              </span>
                            </span>
                          )})}
                        </span>
                      </CSSTransition>
                    </span>
                  )
                })}
              </GoogleMap>
            </div>

            <div className={`map-data ${listHeight ? 'listing' : ''}`}>
              <div className="map-control"
                   onClick={() => this.closeList() }>
                {!termAgreed &&
                  <UiIcon name="location"
                          onClick={() => { this.toggleAlert('term') }}
                          button={true} />
                }
                {termAgreed &&
                  <UiIcon name={`location ${locating ? 'active' : ''}`}
                          onClick={() => { this.locating() }}
                          button={true} />
                }
              </div>

              <div className={`map-place-item ${clicked && placeClicked ? 'active' : ''}`}>
                {placeClicked &&
                  <div className="place-wrap"
                       onClick={() => this.openRead(placeClicked)}>
                    {placeClicked.thumbnail &&
                      <div className="thumbnail"
                           style={{ backgroundImage: `url(${getS3Url(placeClicked.thumbnail)})` }}/>
                    }
                    <div className={`info ${!placeClicked.thumbnail ? 'no-thumb' : ''}`}>
                      <div className="title">
                        <h4>{placeClicked.title}</h4>
                        {!!bookmarkList &&
                          <div className="bookmark">
                            <UiIcon name={`bookmark ${bookmarkList.includes(placeClicked.id) ? 'active' : ''}`} /><br />
                          </div>
                        }
                      </div>
                      <div className="meta">
                        <span className="item">
                          <UiIcon name="star-point" />
                          {placeClicked.starPointShow}<span>/5</span>
                        </span>
                        <span className="item">
                          <UiIcon name="place" />
                          {this.calcDistance(placeClicked.coord)}
                        </span>
                        {placeOpen === 2 &&
                          <span className="item">
                              <UiIcon name="time red" />
                            {t('place.open.tobe')}
                          </span>
                        }
                        {placeOpen === 1 &&
                          <span className="item">
                            <UiIcon name="time" />
                            {t('place.open.ing')}
                          </span>
                        }
                        {placeOpen === 0 &&
                          <span className="item">
                            <UiIcon name="time red" />
                            {t('place.open.end')}
                          </span>
                        }
                        {placeOpen === -1 &&
                          <span className="item">
                            <UiIcon name="time red" />
                            {t('place.open.vacation')}
                          </span>
                        }
                      </div>
                      <div className="description">
                        <p>
                          {placeClicked.summary}
                        </p>
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div className={`map-list
                              ${recommendList.length > 0 ? 'recommend' : ''}
                              ${searchLoading ? 'loading' : ''}
                              ${recommendList.length < 1 && !searchLoading ? 'no-result' : ''}`}
                   ref={this.nodeRefList}>
                {searchLoading &&
                  <div className="list-search">
                    {t('place.search.ing')}
                    <UiIcon name="refresh" />
                  </div>
                }
                {(recommendList.length > 0 && !searchLoading) &&
                  <div className="list-handle"
                       draggable
                       onDragStart={e => this.listDragStart(e)}
                       onDrag={e => this.listDrag(e)}
                       onDragEnd={e => this.listDragEnd(e)}
                       onTouchStart={e => this.listDragStart(e, false)}
                       onTouchMove={e => this.listDrag(e, false)}
                       onTouchEnd={e => this.listDragEnd(e, false)}>
                    {recommendList.length > 0 &&
                      <span>{t('place.recommend')} {recommendList.length}</span>
                    }
                  </div>
                }
                {(recommendList.length < 1 && !searchLoading) &&
                  <div className="list-search">
                    <span dangerouslySetInnerHTML={{ __html: t('place.search.noResult') }} />
                  </div>
                }

                <div className="list-list">
                  {recommendList.map(p => this.renderListItem(p))}
                </div>
              </div>
            </div>
          </>
        }

        <CSSTransition in={showRead > 0}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefRead}
                       classNames="page-slide">
          <div className={`map-place-wrap ${showRead < 0 ? 'backward' : ''}`}
               ref={nodeRefRead}>
            {place &&
              <MapPlace place={place}
                        bookmarkList={bookmarkList}
                        onSetBookmark={() => this.setBookmark(place)}
                        onClose={this.closeRead} />
            }
          </div>
        </CSSTransition>

        <CSSTransition in={!loadMap || loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>

        {showAlert.term &&
          <UiConfirm okayText={t('common.agree')}
                     onOkay={() => { this.agreeTerm() }}
                     cancelText={t('common.cancel')}
                     onCancel={() => { this.disagreeTerm() }}>
            <ul className="term-confirm">
              <li>
                <UiIcon name="location-pin" />
                <p dangerouslySetInnerHTML={{ __html: t('place.term.use') }} />
              </li>
              <li>
                <UiIcon name="check-circle-full-blue" />
                <p>
                  {t('place.term.location')}
                </p>
                <button onClick={() => { this.toggleAlert('showTerm') }}>
                  {t('place.term.term')}
                </button>
              </li>
            </ul>
          </UiConfirm>
        }

        {showAlert.noLocation &&
          <UiAlert content={t('place.turnOn.' + browserInfo())}
                   onOkay={() => { this.toggleAlert('noLocation') }} />
        }

        {showAlert.freeDocentEnd &&
          <div className="free-docent-end">
            <UiAlert content={t('place.freeEnd.description')}
                     okayText={t('place.freeEnd.button')}
                     onOkay={() => { this.purchasePlan(constants.PLAN01_ID) }} />
          </div>
        }

        {showAlert.toApp &&
          <UiAlert content={t('error.onlyInApp')}
                   onOkay={() => { this.toggleAlert('toApp') }} />
        }

        <UiDropdown show={showAlert.showTerm}
                    onTryClose={() => { this.toggleAlert('showTerm') }}>
          <UiTxt dataName="terms/location" />
        </UiDropdown>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(PlaceMap, props)
export default withRouter(StateContainer)
