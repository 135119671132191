import Dropzone from 'react-dropzone'
import Component from '../../utils/Component'
import { StateConsumer } from '../../context/StateProvider'
import { resizeImage } from '../../utils/tool'

import defaultBlankImage from '../../assets/images/asset--no-image.svg'

import './Image.scss'

class Image extends Component {
  state = {
    thumbnail: null,
    error: null
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  setThumbnail = (thumbnail) => {
    const {
      blankImage
    } = this.props

    if (thumbnail) {
      this.setState({
        thumbnail
      })
    } else {
      this.setState({
        thumbnail: blankImage
      })
    }
  }

  onDrop = (file, change = true) => {
    const {
      t,
      resizeSize,
      imageType,
      imageQuality
    } = this.props

    if (!file || (file.type && file.type.substr(0, 6) !== 'image/')) {
      this.setState({ error: t('community.error.image') })
      return
    }

    if (typeof file !== 'string') {
      const reader = new FileReader()

      const setError = () => this.setState({error: t('community.error.image')})
      reader.onabort = () => setError()
      reader.onerror = () => setError()
      reader.onload = () => {
        resizeImage(file, reader.result, { resizeSize, imageType, imageQuality })
          .then(({ thumbnailUrl, resizedFile }) => {
            this.setThumbnail(thumbnailUrl)
            if (change) {
              this.imageOnChange(resizedFile)
            }
          }).catch(err => {
            console.error(err)
            setError()
          })
      }
      reader.readAsDataURL(file)
    } else {
      this.setThumbnail(file)
    }
  }

  imageOnChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  componentDidMount () {
    const {
      blankImage,
      value
    } = this.props

    if (value) {
      this.onDrop(value, false)
    } else {
      this.setState({
        thumbnail: blankImage
      })
    }
  }

  render() {
    const {
      thumbnail
    } = this.state

    const {
      width,
      height,
      placeholder
    } = this.props

    let className = `ui-image`
    let thumbnailStyle = {
      width,
      height,
      backgroundImage: `url(${thumbnail})`
    }

    return (
      <div className={className}>
        <div className="ui-image-dropzone">
          <Dropzone onDrop={acceptedFiles => this.onDrop(acceptedFiles[0])}>
            {({ getRootProps, getInputProps }) => (
              <div { ...getRootProps() }>
                <input { ...getInputProps() } />
                <div className="thumbnail"
                     style={thumbnailStyle}> </div>
                {placeholder}
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    )
  }
}

Image.defaultProps = {
  blankImage: defaultBlankImage,
  value: null,
  width: '89px',
  height: '89px',
  resizeSize: { width: null, height: null },
  imageType: 'image/jpeg',
  imageQuality: 0.7,
  placeholder: '',
  onChange: null,
  onClick: null
}

const StateContainer = (props) => StateConsumer(Image, props)
export default StateContainer