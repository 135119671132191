import React, { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Component from '../utils/Component'
import { getAuthToken } from '../utils/auth'
import { StateConsumer } from '../context/StateProvider'

import { notificationCount } from '../api/user'

import Blank from '../pages/Blank'
import NavBar from '../components/layout/NavBar'
import TabBar from '../components/layout/TabBar'
import UiIcon from '../components/ui/Icon'
import UiButton from '../components/ui/Button'
import UiDropdown from '../components/ui/layer/Dropdown'

import './DefaultLayout.scss'

class DefaultLayout extends Component {
  state = {
    locationPathname: '',
    layoutShowHeader: false,
    layoutShowFooter: false,
    isMovingBack: false,
    notificationTimer: null,
    notificationTalkCount: 0
  }
  
  loadNotificationCount = () => {
    const {
      action,
      state
    } = this.props

    const authToken = getAuthToken()

    if (authToken) {
      notificationCount().then(response => {
        const count = parseInt(response.data.n) || 0
        const countTalk = parseInt(response.data.t) || 0
        const old = state.notificationCount
        const oldTalk = this.state.notificationTalkCount

        if (window.webkit && window.webkit.messageHandlers.nearcleIos) {
          window.webkit.messageHandlers.nearcleIos.postMessage(JSON.stringify({
            action: 'setBadgeCount',
            count: count + countTalk
          }))
        }

        if (this.props.state.layoutShowFooter && (count !== old || countTalk !== oldTalk)) {
          action.setNotificationCount(count > 999 ? '999+' : count)
          this.setState({
            notificationTalkCount: countTalk > 999 ? '999+' : countTalk,
          })

          this.setTabbarButtons()
        }
      }).catch(err => console.error(err))
    } else {
      action.setNotificationCount(0)
      this.setState({
        notificationTalkCount: 0
      })
    }
  }

  onClickHome = () => {
    const {
      history
    } = this.props

    history.push('/h')
  }

  onClickCommunity = () => {
    const {
      history
    } = this.props

    history.push('/c/b')
  }

  onClickTalk = () => {
    const {
      history
    } = this.props

    history.push('/t/r')
  }

  onClickPlace = () => {
    const {
      history
    } = this.props

    history.push('/p')
  }

  onClickMyPage = () => {
    const {
      history
    } = this.props

    history.push('/my')
  }

  setTabbarButtons = (current) => {
    const {
      action,
      location
    } = this.props
    
    const {
      notificationTalkCount
    } = this.state

    const currentLocation = current || location.pathname

    action.setTabbarButtons([
      <UiIcon name={`tabbar-home${currentLocation === '/h' || currentLocation === '/noti' ? ' active' : ''}`}
              activeName="tabbar-home active"
              onClick={() => { this.onClickHome() }} />,
      <UiIcon name={`tabbar-community${currentLocation === '/c' || currentLocation.substr(0, 3) === '/c/' ? ' active' : ''}`}
              activeName="tabbar-community active"
              onClick={() => { this.onClickCommunity() }} />,
      <UiIcon name={`tabbar-place${currentLocation === '/' || currentLocation === '/p' || currentLocation.substr(0, 3) === '/p/' ? ' active' : ''}`}
              activeName="tabbar-place active"
              onClick={() => { this.onClickPlace() }} />,
      <UiIcon name={`tabbar-chat${currentLocation === '/t' || currentLocation.substr(0, 3) === '/t/' ? ' active' : ''}`}
              activeName="tabbar-chat active"
              badge={notificationTalkCount || null}
              onClick={() => { this.onClickTalk() }} />,
      <UiIcon name={`tabbar-mypage${currentLocation.substr(0, 3) === '/my' ? ' active' : ''}`}
              activeName="tabbar-mypage active"
              onClick={() => { this.onClickMyPage() }} />
    ])
  }

  toggleLogin = () => {
    const {
      action
    } = this.props

    const {
      layoutShowLogin
    } = this.props.state

    action.setLayoutShowLogin(!layoutShowLogin)
  }

  goToLogin = () => {
    const {
      history,
      action
    } = this.props

    setTimeout(() => {
      action.setLayoutShowLogin(false)
    }, 500)
    history.push('/signin')
  }

  componentDidUpdate() {
    const {
      action,
      state
    } = this.props

    const {
      layoutShowHeader,
      layoutShowFooter
    } = this.state

    if (state.layoutShowHeader !== layoutShowHeader) {
      this.setState({ layoutShowHeader: state.layoutShowHeader})
    }

    if (state.layoutShowFooter !== layoutShowFooter) {
      this.setState({ layoutShowFooter: state.layoutShowFooter})

      if (state.layoutShowFooter) {
        action.setLayoutBottom(56)
        this.setTabbarButtons()
      } else {
        action.setLayoutBottom(0)
      }
    }
  }

  componentDidMount() {
    const {
      history,
      action
    } = this.props

    this.unlisten = history.listen((location, act) => {
      if (act === 'POP') {
        action.setMovingBack()
      }
      this.setState({ locationPathname: location.pathname })
      this.setTabbarButtons(location.pathname)
    })

    this.loadNotificationCount()
    this.notificationTimer = setInterval(() => {
      this.loadNotificationCount()
    }, 10000)
  }

  componentWillUnmount() {
    const {
      action
    } = this.props

    const {
      layoutShowFooter
    } = this.state

    if (layoutShowFooter) {
      action.setLayoutBottom(56)
    } else {
      action.setLayoutBottom(0)
    }

    this.unlisten()

    clearInterval(this.notificationTimer)
  }

  render() {
    const nodeRefHeader = createRef(null)
    const nodeRefContent = createRef(null)
    const nodeRefFooter = createRef(null)

    const {
      t,
      page
    } = this.props

    const {
      layoutShowHeader,
      layoutShowFooter,
      locationPathname
    } = this.state

    const {
      navbarTitle,
      navbarBackButton,
      navbarButtons,
      tabbarButtons,
      isMovingBack,
      layoutShowLogin
    } = this.props.state

    let contentStyle = {}
    if (layoutShowHeader) {
      contentStyle.paddingTop = '56px'
    }
    if (layoutShowFooter) {
      contentStyle.paddingBottom = '56px'
    }

    return (
      <div id="defaultLayout"
           className={`default-layout ${isMovingBack ? 'backward' : 'forward'}`}>
        <CSSTransition in={layoutShowHeader}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefHeader}
                       classNames="slide">
          <div className="layout-header"
               ref={nodeRefHeader}>
            <div className="layout-container">
              <NavBar title={navbarTitle}
                      buttons={navbarButtons}
                      backButton={navbarBackButton} />
            </div>
          </div>
        </CSSTransition>

        <div className="layout-content"
             style={contentStyle}>
          <TransitionGroup>
            <CSSTransition key={locationPathname}
                           timeout={500}
                           nodeRef={nodeRefContent}
                           classNames="slide">
              <div className={`layout-content-container ${isMovingBack ? 'backward' : 'forward'}`}
                   ref={nodeRefContent}>
                <div>{page}</div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>

        <UiDropdown show={layoutShowLogin}
                    onTryClose={() => { this.toggleLogin() }}>
          <div className="layout-sign-in">
            <p className="layout-sign-in-p">
              {t('signIn.error.signInRequired')}
            </p>

            <UiButton text={t('common.loginSignUp')}
                      onClick={() => { this.goToLogin() }} />
          </div>
        </UiDropdown>

        <CSSTransition in={layoutShowFooter}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefFooter}
                       classNames="slide">
          <div className="layout-footer"
               ref={nodeRefFooter}>
            <div className="layout-container">
              <TabBar buttons={tabbarButtons} />
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

DefaultLayout.defaultProps = {
  page: Blank
}

const StateContainer = (props) => StateConsumer(DefaultLayout, props)
export default withRouter(StateContainer)
