import React from 'react'
import { StateConsumer } from '../context/StateProvider'
import Component from '../utils/Component'
import { getLang, getStoredLang, setLang } from '../utils/lang'

import lang from '../assets/data/lang'

import UiSelect from '../components/ui/Select'

class LayoutContainer extends Component {
  state = {
    language: ''
  }

  langData = Object.entries(lang).map(([key, value]) => ({ value: key, text: value.name, description: value.description }))

  setLanguage (lang) {
    setLang(lang)
    window.location.reload(false)
  }

  render() {
    const {
      language
    } = this.state

    const {
      i18n,
      children
    } = this.props

    const currentLanguage = getLang()
    if (i18n.language !== currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    }

    return (
      <>
        <div className="container">
          {children}
        </div>

        {!getStoredLang() &&
          <UiSelect value={language}
                    items={this.langData}
                    search={true}
                    showNow={true}
                    onChange={value => this.setLanguage(value)}/>
        }
      </>
    )
  }
}


const StateContainer = (props) => StateConsumer(LayoutContainer, props)
export default StateContainer
