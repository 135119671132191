import React, { createRef } from 'react';
import Component from '../../utils/Component';
import { withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import './NavBar.scss'

class NavBar extends Component {
  render() {
    const nodeRefBackButton = createRef(null)
    const nodeRefTitle = createRef(null)
    const nodeRefButtons = createRef(null)
    const {
      title,
      buttons,
      backButton
    } = this.props;

    return (
      <header className="navbar">
        <TransitionGroup className="navbar-back">
          <CSSTransition in={!!backButton}
                         key={backButton}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefBackButton}
                         classNames="fade">
            <div className="navbar-back"
                 ref={nodeRefBackButton}>
              {backButton}
            </div>
          </CSSTransition>
        </TransitionGroup>

        <TransitionGroup className={`navbar-title ${!!backButton ? 'has-back' : ''}`}>
          <CSSTransition in={!!title}
                         key={title}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefTitle}
                         classNames="navbar-title">
            <h1 ref={nodeRefTitle}>
              <span className="navbar-title-wrap">
                {title}
              </span>
            </h1>
          </CSSTransition>
        </TransitionGroup>

        <TransitionGroup className="navbar-icons">
          <CSSTransition in={!!buttons}
                         key={buttons}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefButtons}
                         classNames="fade">
            <div ref={nodeRefButtons}>
              {buttons}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </header>
    );
  }
}

NavBar.defaultProps = {
  title: null,
  buttons: null,
  backButton: false
};

export default withRouter(NavBar);
