import { withRouter } from 'react-router-dom'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import UiIcon from '../../components/ui/Icon'

import './ServiceCommon.scss'

class ServiceCenter extends Component {
  componentDidMount () {
    const {
      t,
      action,
      history
    } = this.props

    action.setNavbarTitle(t('setting.center.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/my') }} />
    )
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)
  }

  render() {
    const {
      t,
      history
    } = this.props

    return(
      <div className="layout-container">
        <div className="service-center">
          <ul className="service-menu">
            <li>
              <button onClick={() => { history.push('/service/faq') }}>
                <span className="value-left">
                  {t('setting.faq.title')}
                </span>
                <span className="value-right">
                  <UiIcon name="menu-arrow" />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => { history.push('/service/term') }}>
                <span className="value-left">
                  {t('setting.term.title')}
                </span>
                <span className="value-right">
                  <UiIcon name="menu-arrow" />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => { history.push('/service/version') }}>
                <span className="value-left">
                  {t('setting.version.title')}
                </span>
                <span className="value-right">
                  <UiIcon name="menu-arrow" />
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ServiceCenter, props)
export default withRouter(StateContainer)
