import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Component from '../../utils/Component'
import { StateConsumer } from '../../context/StateProvider'
import { signIn } from '../../api/auth'
import { checkUserId, getInterestList, signUp } from '../../api/user'
import { getVerifyToken, hashPassword, removeVerifyToken, setAuthToken } from '../../utils/auth'
import { isUserId, isPassword, objectToForm } from '../../utils/tool'

import country from '../../assets/data/country'
import lang from '../../assets/data/lang'

import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiCheckItem from '../../components/ui/CheckItem'
import UiCheckSelect from '../../components/ui/CheckSelect'
import UiSelect from '../../components/ui/Select'
import UiIcon from '../../components/ui/Icon'
import UiImage from '../../components/ui/Image'
import UiAlert from '../../components/ui/layer/Alert'
import UiDropdown from '../../components/ui/layer/Dropdown'
import UiTxt from '../../components/ui/Txt'

import EmailVerification from './EmailVerification'

import noProfileImage from '../../assets/images/asset--no-profile.svg'

import './SignUp.scss'

class SignUp extends Component {
  state = {
    backward: false,
    step: 1,
    showAlert: {
      success: false
    },
    value: {
      email: '',
      userId: '',
      password: '',
      passwordConfirm: '',
      gender: '',
      birthday: '',
      marketingAgreement: false,
      country: '',
      lang: '',
      profile: null,
      interest: {}
    },
    agreement: [],
    error: {
      email: null,
      userId: null,
      password: null,
      passwordConfirm: null,
      birthday: null
    },
    description: {
      email: null,
      userId: null,
      password: null,
      passwordConfirm: null,
      birthday: null
    },
    interestList: {},
    success: false,
    showSuccess: false,
    selectedInterest: [],
    showTerm: {
      service: false,
      data: false,
      location: false,
      marketing: false
    }
  }

  countryData = Object.entries(country).map(([key, value]) => ({ icon: `flag ${key}`, value: key, text: value.name, description: value.description }))
  langData = Object.entries(lang).map(([key, value]) => ({ value: key, text: value.name, description: value.description }))

  toggleTerm = (term) => {
    const {
      showTerm
    } = this.state
    this.setStateInside('showTerm', term, !showTerm[term])
  }

  moveToStep = (nextStep, navBarTitle) => {
    const {
      step
    } = this.state

    const {
      action
    } = this.props

    if (nextStep > step) {
      this.setState({ backward: false })
      action.setNavbarTitle(navBarTitle)
    } else {
      this.setState({ backward: true })
      action.setNavbarTitle(navBarTitle, true)
    }
    setTimeout(() => {
      this.setState({ step: nextStep })
    }, 0)
  }

  step1OnSubmit = (value) => {
    const {
      t,
      action,
      history
    } = this.props

    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/signin') }} />
    )
    this.setStateInside('value', 'email', value)
    this.moveToStep(2, t('signUp.header.signUp'))
  }

  step2OnSubmit = () => {
    const {
      t,
      action
    } = this.props

    const {
      userId
    } = this.state.value

    checkUserId({
      userId
    }).then(response => {
      if (response.data.isDuplicatedId) {
        this.setStateInside('error', 'userId', t('signUp.error.alreadyUsingId'))
      } else {
        action.setNavbarBackbutton(
          <UiIcon name="back"
                  onClick={() => this.step1OnSubmit()} />
        )
        action.setNavbarButtons(null)
        this.moveToStep(3, t('signUp.header.signUp'))
      }
    }).catch(error => {
      this.toggleAlert('unknown')
    })
  }

  step3OnSubmit = () => {
    // const {
    //   t,
    //   action
    // } = this.props

    // action.setNavbarBackbutton(
    //   <UiIcon name="back"
    //           onClick={() => this.step2OnSubmit()} />
    // )
    // action.setNavbarButtons(
    //   <button onClick={() => this.step4OnSkip()}>{t('common.skip')}</button>
    // )
    // this.moveToStep(4, t('signUp.header.privacy'))

    this.step4OnSkip()
  }

  step4OnSkip = () => {
    this.setStateInside('value', 'gender', '')
    this.setStateInside('value', 'birthday', '')
    this.step4OnSubmit()
  }

  step4OnSubmit = () => {
    const {
      t,
      action
    } = this.props

    // action.setNavbarBackbutton(
    //   <UiIcon name="back"
    //           onClick={() => this.step3OnSubmit()} />
    // )
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => this.step2OnSubmit()} />
    )
    action.setNavbarButtons(null)
    this.moveToStep(5, t('signUp.header.term'))
  }

  step5OnSubmit = () => {
    const {
      t,
      action
    } = this.props

    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => this.step4OnSubmit()} />
    )
    action.setNavbarButtons(null)
    this.moveToStep(6, t('signUp.header.country'))
  }

  step6OnSubmit = () => {
    const {
      t,
      action
    } = this.props

    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => this.step5OnSubmit()} />
    )
    // action.setNavbarButtons(
    //   <button onClick={() => this.step7OnSkip()}>{t('common.skip')}</button>
    // )
    // this.moveToStep(7, t('signUp.header.profile'))
    this.step8OnSubmit()
  }

  // step7OnSkip = () => {
  //   this.setStateInside('value', 'profile', null)
  //   this.step7OnSubmit()
  // }

  step7OnSubmit = () => {
    // const {
    //   action
    // } = this.props

    // this.step8OnSubmit()

    // this.getInterestCateList()
    //
    // action.setLayoutShowHeader(false)
    // this.moveToStep(8, '')
  }

  step8OnSubmit = () => {
    const {
      userId,
      password,
      email,
      marketingAgreement,
      country,
      lang,
      birthday,
      gender,
      profile,
      interest
    } = this.state.value

    const {
      action
    } = this.props

    const token = getVerifyToken()

    this.toggleAlert('success')
    action.setLayoutShowHeader(false)
    action.setLayoutShowFooter(false)

    signUp(objectToForm({
      userId: userId.trim(),
      password: hashPassword(password.trim()),
      email: email.trim(),
      marketingAgree: marketingAgreement,
      country,
      lang,
      birthday,
      gender,
      profile,
      interest,
      token,
    })).then(() => {
      setTimeout(() => {
        this.setState({ showSuccess: true })
      }, 1)
      setTimeout(() => {
        this.setState({ success: true })
      }, 2000)
      removeVerifyToken()

      signIn({
        userId: userId.trim(),
        password: password.trim()
      }).then(response => {
        setAuthToken(response.data.accessToken)
        action.fetchUserData(true)
      }).catch(error => {
        console.error(error)
      })
    }).catch(error => {
      this.toggleAlert('unknown')
    })
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  inputUserIdOnChange = (value) => {
    const {
      t
    } = this.props

    this.inputOnChange('userId', value)
    if (value) {
      if (!isUserId(value)) {
        this.setStateInside('error', 'userId', t('signUp.error.userIdLimit'))
      } else {
        this.setStateInside('description', 'userId', t('signUp.error.userIdLimit'))
      }
    } else {
      this.setStateInside('description', 'userId', null)
    }
  }

  inputPasswordOnChange = (value) => {
    const {
      t
    } = this.props

    const {
      passwordConfirm
    } = this.state.value

    this.inputOnChange('password', value)
    if (value) {
      if (!isPassword(value)) {
        this.setStateInside('error', 'password', t('signUp.error.passwordLimit'))
      } else {
        this.setStateInside('description', 'password', t('signUp.error.canUsePassword'))
      }
    } else {
      this.setStateInside('description', 'password', null)
    }
    this.inputPasswordConfirmOnChange(passwordConfirm, value)
  }

  inputPasswordConfirmOnChange = (value, pw) => {
    const {
      t
    } = this.props

    const {
      password
    } = this.state.value

    const currentPassword = pw || password
    this.inputOnChange('passwordConfirm', value)
    if (value && value === currentPassword) {
      this.setStateInside('description', 'passwordConfirm', t('signUp.error.passwordMatch'))
    } else {
      this.setStateInside('description', 'passwordConfirm', null)
    }
  }

  agreeAll = () => {
    const {
      value,
      agreement
    } = this.state

    if (agreement.length >= 3 && value.marketingAgreement) {
      this.setState({ agreement: [] })
      this.setStateInside('value', 'marketingAgreement', false)
    } else {
      this.setState({ agreement: ['service', 'data', 'location'] })
      this.setStateInside('value', 'marketingAgreement', true)
    }
  }

  getInterestCateList = () => {
    getInterestList().then(response => {
      this.setState({ interestList: response.data })
    })
  }

  interestOnClick = (key) => {
    const {
      selectedInterest
    } = this.state

    if (selectedInterest.includes(key)) {
      selectedInterest.splice(selectedInterest.indexOf(key), 1)
    } else {
      selectedInterest.push(key)
    }

    this.setState({ 'selectedInterest': selectedInterest })
  }

  interestOnSelect = (key, subKey) => {
    const {
      interest
    } = this.state.value

    let current = interest
    if (!current[key]) {
      current[key] = []
    }
    if (current[key].includes(subKey)) {
      let i = 0
      while (i < current[key].length) {
        if (current[key][i] === subKey) {
          current[key].splice(i, 1)
        } else {
          ++i
        }
      }
    } else {
      current[key].push(subKey)
    }

    if (current[key].length < 1) {
      delete current[key]
    }

    this.setStateInside('value', 'interest', current)
  }

  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
  }

  componentDidMount () {
    const {
      t,
      action,
      history
    } = this.props

    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)
    action.setNavbarTitle(t('signUp.header.email'))
    action.setNavbarButtons(null)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/signin') }} />
    )
  }

  render() {
    const nodeRefContent = createRef(null)

    const {
      backward,
      step,
      showAlert,
      value,
      agreement,
      error,
      description,
      success,
      showSuccess,
      selectedInterest,
      interestList,
      showTerm
    } = this.state

    const {
      t,
      history
    } = this.props

    const isStep2SubmitDisabled = () => {
      return !value.userId || error.userId
    }

    const isStep3SubmitDisabled = () => {
      return !description.password || !description.passwordConfirm
    }

    const agreeAll = agreement.length >= 3 && value.marketingAgreement ? ['all'] : []

    const isStep5SubmitDisabled = () => {
      return agreement.length < 3
    }

    const isStep6SubmitDisabled = () => {
      return !value.country || !value.lang
    }

    return (
      <TransitionGroup>
        <CSSTransition key={step}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefContent}
                       classNames="page-slide">
          <div className={`sign-up ${backward ? 'backward' : ''}`}
               ref={nodeRefContent}>
            <div className="layout-container">
              {step === 1 &&
                <EmailVerification isSignUp={ true }
                                   onSuccess={val => this.step1OnSubmit(val)} />
              }

              {step === 2 &&
                <div className="step-2">
                  <UiInput type="text"
                           placeholder={t('signUp.placeholder.userId')}
                           label={t('common.userId')}
                           value={value.userId}
                           error={error.userId}
                           description={description.userId}
                           onChange={val => this.inputUserIdOnChange(val)}
                           onEnter={() => { if (!isStep2SubmitDisabled()) this.step2OnSubmit() }} />

                  <UiButton text={t('common.next')}
                            isDisabled={isStep2SubmitDisabled()}
                            isFixed={true}
                            onClick={this.step2OnSubmit} />
                </div>
              }

              {step === 3 &&
                <div className="step-3">
                  <UiInput type="password"
                           placeholder={t('signUp.placeholder.password')}
                           label={t('common.password')}
                           value={value.password}
                           error={error.password}
                           description={description.password}
                           onChange={val => this.inputPasswordOnChange(val)}
                           onEnter={() => { if (!isStep3SubmitDisabled()) this.step3OnSubmit() }} />

                  <UiInput type="password"
                           placeholder={t('signUp.placeholder.password')}
                           value={value.passwordConfirm}
                           error={error.passwordConfirm}
                           description={description.passwordConfirm}
                           onChange={val => this.inputPasswordConfirmOnChange(val)}
                           onEnter={() => { if (!isStep3SubmitDisabled()) this.step3OnSubmit() }} />

                  <UiButton text={t('common.next')}
                            isDisabled={isStep3SubmitDisabled()}
                            isFixed={true}
                            onClick={this.step3OnSubmit} />
                </div>
              }

              {step === 4 &&
                <div className="step-4">
                  <div className="description">
                    <p>
                      {t('signUp.privacy.description')}
                    </p>
                  </div>

                  <UiCheckSelect label={t('common.gender')}
                                 thisValues={['F', 'M', 'O']}
                                 value={[value.gender]}
                                 titles={[t('gender.F'), t('gender.M'), t('gender.O')]}
                                 onClick={value => this.setStateInside('value', 'gender', value)} />

                  <UiInput type="date"
                           placeholder={t('signUp.placeholder.birthday')}
                           label={t('common.birthday')}
                           value={value.birthday}
                           error={error.birthday}
                           description={description.birthday}
                           onChange={val => { this.inputOnChange('birthday', val) }}
                           onEnter={() => { this.step4OnSubmit() }} />

                  <UiButton text={t('common.next')}
                            isFixed={true}
                            onClick={this.step4OnSubmit} />
                </div>
              }

              {step === 5 &&
                <div className="step-5">
                  <div className="description">
                    <p>
                      <span dangerouslySetInnerHTML={{ __html: t('signUp.term.description') }} />
                    </p>
                  </div>

                  <UiCheckItem thisValue="all"
                               value={agreeAll}
                               title={<b>{t('signUp.term.agreeAll')}</b>}
                               onChange={() => this.agreeAll()} />

                  <UiCheckItem thisValue="service"
                               value={agreement}
                               title={t('signUp.term.serviceTerm')}
                               description={<span onClick={() => { this.toggleTerm('service') }}>{t('signUp.term.showDetail')}</span>}
                               onChange={value => this.setState({ agreement: value })} />

                  <UiCheckItem thisValue="data"
                               value={agreement}
                               title={t('signUp.term.dataTerm')}
                               description={<span onClick={() => { this.toggleTerm('data') }}>{t('signUp.term.showDetail')}</span>}
                               onChange={value => this.setState({ agreement: value })} />

                  <UiCheckItem thisValue="location"
                               value={agreement}
                               title={t('signUp.term.locationTerm')}
                               description={<span onClick={() => { this.toggleTerm('location') }}>{t('signUp.term.showDetail')}</span>}
                               onChange={value => this.setState({ agreement: value })} />

                  <UiCheckItem thisValue={true}
                               value={[value.marketingAgreement]}
                               title={t('signUp.term.marketingTerm')}
                               description={<span onClick={() => { this.toggleTerm('marketing') }}>{t('signUp.term.showDetail')}</span>}
                               onChange={() => this.setStateInside('value', 'marketingAgreement', !value.marketingAgreement)} />

                  <UiButton text={t('common.next')}
                            isFixed={true}
                            isDisabled={isStep5SubmitDisabled()}
                            onClick={this.step5OnSubmit} />
                </div>
              }

              {step === 6 &&
                <div className="step-6">
                  <div className="description">
                    {!value.country && !value.lang &&
                      <p>
                        <span dangerouslySetInnerHTML={{ __html: t('signUp.country.description') }} />
                      </p>
                    }
                  </div>

                  <UiSelect placeholder={t('signUp.placeholder.country')}
                            value={value.country}
                            items={this.countryData}
                            search={true}
                            searchValue={true}
                            onChange={value => this.setStateInside('value', 'country', value)} />

                  <UiSelect placeholder={t('signUp.placeholder.language')}
                            value={value.lang}
                            items={this.langData}
                            search={true}
                            searchValue={true}
                            onChange={value => this.setStateInside('value', 'lang', value)} />

                  <UiButton text={t('common.next')}
                            isFixed={true}
                            isDisabled={isStep6SubmitDisabled()}
                            onClick={this.step6OnSubmit} />
                </div>
              }

              {step === 7 &&
                <div className="step-7">
                  <div className="profile-image">
                    <UiImage blankImage={noProfileImage}
                             resizeSize={{ width: 256, height: 256 }}
                             placeholder={(
                               <div className="profile-icon">
                                 <UiIcon name="photo" />
                               </div>
                             )}
                             onChange={value => this.setStateInside('value', 'profile', value)} />

                    <div className="description">
                      <p>
                        {!value.profile &&
                          <>{t('signUp.placeholder.profile')}</>
                        }
                        {value.profile &&
                          <>{t('signUp.profile.added')}</>
                        }
                      </p>
                    </div>
                  </div>

                  <UiButton text={t('common.complete')}
                            isFixed={true}
                            onClick={this.step7OnSubmit} />
                </div>
              }

              {step === 8 &&
                <div className="step-8">
                  <div className="description">
                    <p>{t('signUp.placeholder.interest')}</p>
                  </div>

                  <ul className="interest-select">
                    {Object.entries(interestList).map(([key, val]) => {
                      const selected = !!value.interest[key]

                      return (
                        <li className={`interest-depth-1-item
                                        ${selected ? ' selected' : ''}
                                        ${selectedInterest.includes(key) ? ' active' : ''}`}
                            key={`interest-${key}`}>
                          <p onClick={() => this.interestOnClick(key)}>
                            <UiIcon name={selected ? 'check-blue' : 'check-gray'} />
                            {val.name}
                          </p>

                          <div className="interest-depth-2">
                            {Object.entries(val.sub).map(([k, v]) => {
                              const s = selected && value.interest[key].includes(k)
                              return (
                                <button className={`interest-depth-2-item${s ? ' selected' : ''}`}
                                        onClick={() => this.interestOnSelect(key, k)}
                                        key={`interest-${k}`}>
                                  {v}
                                </button>
                              )
                            })}
                          </div>
                        </li>
                      )
                    })}
                  </ul>

                  <UiButton text={t('common.completeSelect')}
                            isFixed={true}
                            isFull={true}
                            onClick={this.step8OnSubmit} />
                </div>
              }

              {showAlert.success &&
                <div className={`sign-up-success${showSuccess ? ' show' : ''}`}>
                  <div className="success-wrap">
                    <h3 className="success-title">
                      <span className="success-title-wrap">
                        {t('signUp.complete.title')}
                      </span>
                    </h3>
                    <p className="success-description">
                      <span dangerouslySetInnerHTML={{ __html: t('signUp.complete.description') }} />
                    </p>
                    <div className={`success-buttons${success ? ' success' : ''}`}>
                      <div className="load">
                        <UiIcon name="loader" />
                        {t('common.loading')}
                      </div>
                      <div className="button">
                        <UiButton text={t('common.doStart')}
                                  onClick={() => { history.push('/') }} />
                      </div>
                    </div>
                  </div>
                </div>
              }

              <UiDropdown show={showTerm.service}
                          onTryClose={() => { this.toggleTerm('service') }}>
                <UiTxt dataName="terms/service" />
              </UiDropdown>

              <UiDropdown show={showTerm.data}
                          onTryClose={() => { this.toggleTerm('data') }}>
                <UiTxt dataName="terms/data" />
              </UiDropdown>

              <UiDropdown show={showTerm.location}
                          onTryClose={() => { this.toggleTerm('location') }}>
                <UiTxt dataName="terms/location" />
              </UiDropdown>

              <UiDropdown show={showTerm.marketing}
                          onTryClose={() => { this.toggleTerm('marketing') }}>
                <UiTxt dataName="terms/marketing" />
              </UiDropdown>

              {showAlert.unknown &&
                <UiAlert content={t('error.unknownRetry')}
                         onOkay={() => { this.toggleAlert('unknown') }} />
              }
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

const StateContainer = (props) => StateConsumer(SignUp, props)
export default withRouter(StateContainer)
