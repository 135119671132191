import React from 'react'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import './Txt.scss'

import openSourceEn from '../../assets/data/terms/openSource/en-US.txt'
import openSourceKo from '../../assets/data/terms/openSource/ko-KR.txt'
import operationEn from '../../assets/data/terms/operation/en-US.txt'
import operationKo from '../../assets/data/terms/operation/ko-KR.txt'
import privacyEn from '../../assets/data/terms/privacy/en-US.txt'
import privacyKo from '../../assets/data/terms/privacy/ko-KR.txt'
import serviceEn from '../../assets/data/terms/service/en-US.txt'
import serviceKo from '../../assets/data/terms/service/ko-KR.txt'
import youthEn from '../../assets/data/terms/youth/en-US.txt'
import youthKo from '../../assets/data/terms/youth/ko-KR.txt'
import locationEn from '../../assets/data/terms/location/en-US.txt'
import locationKo from '../../assets/data/terms/location/ko-KR.txt'
import dataEn from '../../assets/data/terms/data/en-US.txt'
import dataKo from '../../assets/data/terms/data/ko-KR.txt'
import marketingEn from '../../assets/data/terms/marketing/en-US.txt'
import marketingKo from '../../assets/data/terms/marketing/ko-KR.txt'

class UiTxt extends Component {
  state = {
    text: ''
  }

  componentDidMount () {
    const {
      i18n,
      dataName
    } = this.props

    let data = ''
    if (dataName === 'terms/openSource') {
      data = i18n.language === 'ko-KR' ? openSourceKo : openSourceEn
    } else if (dataName === 'terms/operation') {
      data = i18n.language === 'ko-KR' ? operationKo : operationEn
    } else if (dataName === 'terms/privacy') {
      data = i18n.language === 'ko-KR' ? privacyKo : privacyEn
    } else if (dataName === 'terms/service') {
      data = i18n.language === 'ko-KR' ? serviceKo : serviceEn
    } else if (dataName === 'terms/youth') {
      data = i18n.language === 'ko-KR' ? youthKo : youthEn
    } else if (dataName === 'terms/location') {
      data = i18n.language === 'ko-KR' ? locationKo : locationEn
    } else if (dataName === 'terms/data') {
      data = i18n.language === 'ko-KR' ? dataKo : dataEn
    } else if (dataName === 'terms/marketing') {
      data = i18n.language === 'ko-KR' ? marketingKo : marketingEn
    }

    fetch(data)
      .then(r => r.text())
      .then(text => {
        this.setState({ text })
      })
  }

  render() {
    const {
      text
    } = this.state

    return (
      <div className="ui-txt">
        {text}
      </div>
    )
  }
}

UiTxt.defaultProps = {
  dataName: null
}

const StateContainer = (props) => StateConsumer(UiTxt, props)
export default StateContainer
