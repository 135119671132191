import Component from '../../utils/Component'
import { StateConsumer } from '../../context/StateProvider'

import { boardRequest } from '../../api/community'

import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiAlert from '../../components/ui/layer/Alert'
import UiConfirm from '../../components/ui/layer/Confirm'

import './BoardRequest.scss'

class BoardRequest extends Component {
  state = {
    requestName: '',
    showAlert: {
      confirm: false,
      limit: false,
      already: false,
      exist: false,
      complete: false,
      unknown: false
    }
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  onChange = (value) => {
    this.setState({ requestName: value })
  }

  onConfirm = () => {
    const {
      requestName
    } = this.state

    boardRequest({
      name: requestName
    }).then(() => {
      this.toggleAlert('complete')
    }).catch(error => {
      if (error.message) {
        this.toggleAlert('confirm')
        if (error.message === '"limit"') {
          this.toggleAlert('limit')
        } else if (error.message === '"already"') {
          this.toggleAlert('already')
        } else if (error.message === '"exist"') {
          this.toggleAlert('exist')
        } else {
          this.toggleAlert('unknown')
        }
      } else {
        console.error(error)
      }
    })
  }

  onComplete = () => {
    const {
      onClose
    } = this.props

    this.toggleAlert('confirm')
    this.toggleAlert('complete')
    onClose()
  }

  render() {
    const {
      requestName,
      showAlert
    } = this.state

    const {
      t,
      onClose
    } = this.props

    return(
      <div className="community-board-request">
        <div className="layout-container">
          <div className="request-header">
            <div className="request-back">
              <UiIcon name="back"
                      onClick={() => { onClose() }} />
            </div>
            <div className="request-title">
              <h1>
                {t('community.request.title')}
              </h1>
            </div>
          </div>
        </div>
        <div className="request-body">
          <div className="request-content">
            <div className="layout-container">
              <UiInput type="text"
                       color="fill"
                       placeholder={t('community.request.placeholder')}
                       value={requestName}
                       onChange={value => this.onChange(value)} />

              <div className="description">
                <h5>{t('community.request.description.title')}</h5>
                <ol>
                  <li>
                    {t('community.request.description.1')}
                  </li>
                  <li>
                    {t('community.request.description.2')}
                  </li>
                  <li>
                    {t('community.request.description.3')}
                    <ul>
                      <li>
                        {t('community.request.description.3-1')}
                      </li>
                      <li>
                        {t('community.request.description.3-2')}
                      </li>
                      <li>
                        {t('community.request.description.3-3')}
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="request-footer">
          <div className="layout-container">
            <UiButton text={t('common.complete')}
                      isDisabled={requestName.length < 1}
                      isFixed={true}
                      onClick={() => { this.toggleAlert('confirm') }} />
          </div>
        </div>

        {showAlert.confirm &&
          <UiConfirm content={t('community.request.alert.confirm')}
                     okayText={t('common.yes')}
                     onOkay={() => { this.onConfirm() }}
                     cancelText={t('common.no')}
                     onCancel={() => { this.toggleAlert('confirm') }} />
        }

        {showAlert.limit &&
          <UiAlert content={t('community.request.alert.limit')}
                   onOkay={() => { this.toggleAlert('limit') }} />
        }

        {showAlert.already &&
          <UiAlert content={t('community.request.alert.already')}
                   onOkay={() => { this.toggleAlert('already') }} />
        }

        {showAlert.exist &&
          <UiAlert content={t('community.request.alert.exist')}
                   onOkay={() => { this.toggleAlert('exist') }} />
        }

        {showAlert.complete &&
          <UiAlert content={t('community.request.alert.complete')}
                   onOkay={() => { this.onComplete() }} />
        }

        {showAlert.unknown &&
          <UiAlert content={t('error.unknownRetry')}
                   onOkay={() => { this.toggleAlert('unknown') }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(BoardRequest, props)
export default StateContainer