import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import { boardList, boardListBookmark } from '../../api/community'

import BoardRequest from '../../pages/community/BoardRequest'

import UiIcon from '../../components/ui/Icon'
import UiContext from '../../components/ui/Context'

import './BoardList.scss'

class CommunityBoardList extends Component {
  state = {
    loading: false,
    showSelected: false,
    selected: null,
    list: [],
    showRequest: false
  }

  preventContextMenu (event) {
    event.preventDefault()
    event.stopPropagation()
    return false
  }

  onPressBoardPress (board) {
    this.buttonPressTimer = setTimeout(() => {
      this.onSelectBoard(board)
      this.buttonPressed = true
    }, 1200)
  }

  onPressBoardRelease (board, mouseUp = false) {
    clearTimeout(this.buttonPressTimer)
    if (mouseUp && !this.buttonPressed) {
      this.onClickBoard(board)
    }
    this.buttonPressed = false
  }

  onClickBoard = (board, replace = false) => {
    const {
      action,
      history
    } = this.props

    action.setNavbarTitle(board.title)
    if (replace) {
      history.replace('/c/' + board.id)
    } else {
      history.push('/c/' + board.id)
    }
  }

  onSelectBoard = (board) => {
    if (board) {
      this.setState({
        showSelected: true,
        selected: board
      })
    } else {
      this.setState({
        showSelected: false
      })
      setTimeout(() => {
        this.setState({
          selected: null
        })
      }, 300)
    }
  }

  onToggleBookmark = (board) => {
    this.setState({
      showSelected: false
    })

    boardListBookmark({
      board: board.id
    }).catch(error => {
      console.error(error)
    })

    setTimeout(() => {
      board.bookmark = !board.bookmark
      this.setState({
        selected: null
      })
    }, 300)
  }

  toggleRequest = () => {
    const {
      action
    } = this.props

    const {
      showRequest
    } = this.state

    const {
      userData
    } = this.props.state

    if (userData) {
      action.setLayoutShowFooter(showRequest)
      this.setState({showRequest: !showRequest})
    } else {
      action.setLayoutShowLogin(true)
    }
  }

  loadList = (loading = true) => {
    const {
      location,
      history
    } = this.props

    if (loading) {
      this.setState({ loading: true })
    }
    boardList().then(result => {
      if (result.data.list.length === 1) {
        const firstBoard = result.data.list[0]
        if (location.state && location.state.back) {
          history.replace('/')
        } else {
          this.onClickBoard(firstBoard, true)
        }
        return
      }
      this.setState({
        loading: false,
        list: [...result.data.list]
      })
    }).catch(error => {
      console.error(error)
    })
  }

  componentDidMount () {
    const {
      t,
      action
    } = this.props


    action.setNavbarTitle(t('community.title'))
    action.setNavbarBackbutton(null)
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(true)

    this.loadList()
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefList = createRef(null)
    const nodeRefRequest = createRef(null)

    const {
      loading,
      showSelected,
      selected,
      list,
      showRequest
    } = this.state

    const {
      t
    } = this.props

    const {
      userData
    } = this.props.state

    const selectedBoard = { ...(selected || {}) }

    return(
      <div className="layout-container">
        <div className="community-board-list">
          <CSSTransition in={loading}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefLoader}
                         classNames="fade">
            <div className="data-loader"
                 ref={nodeRefLoader}>
              <UiIcon name="loader" />
            </div>
          </CSSTransition>

          <CSSTransition in={list.length > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefList}
                         classNames="fade">
            <ul className="board-list"
                ref={nodeRefList}>
              {list.map((board, i) => {
                return (
                  <li key={`list-button-${i}`}>
                    <button onContextMenu={this.preventContextMenu}
                            onTouchStart={() => { this.onPressBoardPress(board) }}
                            onTouchEnd={() => { this.onPressBoardRelease(board, true) }}
                            onMouseDown={() => { this.onPressBoardPress(board) }}
                            onMouseUp={() => { this.onPressBoardRelease(board, true) }}
                            onMouseLeave={() => { this.onPressBoardRelease(board) }}>
                      <span className={`bookmark ${board.bookmark ? 'active' : ''}`}>
                        <UiIcon name="star" />
                      </span>
                      {board.title}
                    </button>
                  </li>
                )
              })}
              <li className="request">
                <button onClick={this.toggleRequest}>
                  {t('community.request.title')}
                </button>
              </li>
            </ul>
          </CSSTransition>

          {userData &&
            <CSSTransition in={showRequest}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefRequest}
                           classNames="page-slide">
              <div className={`community-request ${showRequest ? '' : 'backward'}`}
                   ref={nodeRefRequest}>
                <BoardRequest onClose={this.toggleRequest}/>
              </div>
            </CSSTransition>
          }

          {userData &&
            <UiContext show={showSelected}
                       title={selectedBoard.title}
                       items={[{
                           text: selectedBoard.bookmark ? t('community.favorite.remove') : t('community.favorite.add'),
                           action: () => { this.onToggleBookmark(selected) }
                         }]}
                       onTryClose={() => { this.onSelectBoard(null) }} />
          }
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(CommunityBoardList, props)
export default withRouter(StateContainer)
