import React from 'react'
import Component from '../utils/Component'

import Blank from '../pages/Blank'

class BlankLayout extends Component {
  render() {
    const {
        page
    } = this.props

    return (
      <div>
        {page}
      </div>
    )
  }
}

BlankLayout.defaultProps = {
    page: Blank
}

export default BlankLayout
