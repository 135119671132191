import Component from '../utils/Component'

class Blank extends Component {
  render() {
    return(
      <div>
      </div>
    )
  }
}

export default Blank
