import Component from '../../utils/Component'
import { StateConsumer } from '../../context/StateProvider'
import { isEmail } from '../../utils/tool'
import { setVerifyToken } from '../../utils/auth'
import { sendVerifyEmail, confirmVerifyEmail } from '../../api/auth'

import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiIcon from '../../components/ui/Icon'
import UiAlert from '../../components/ui/layer/Alert'

import './EmailVerification.scss'

class EmailVerification extends Component {
  state = {
    step: 1,
    showAlert: {
      verification: false
    },
    value: {
      email: '',
      verification: ''
    },
    error: {
      email: null,
      verification: null
    }
  }

  step1OnSubmit = () => {
    const {
      t,
      isSignUp,
      action
    } = this.props

    const {
      email
    } = this.state.value

    sendVerifyEmail({
      email,
      isSignUp
    }).then(response => {
      if (response.success) {
        action.setNavbarBackbutton(
          <UiIcon name="back"
                  onClick={() => { this.setState({ step: 1 }) }} />
        )
        this.setState({step: 2})
      }
    }).catch(error => {
      if (error.message === '"User Already Exist"') {
        this.setStateInside('error', 'email', t('emailVerification.error.alreadyUsingEmail'))
      } else {
        this.toggleAlert('unknown')
      }
    })
  }

  step2OnSubmit = () => {
    const {
      action,
      onSuccess
    } = this.props

    const {
      email,
      verification
    } = this.state.value

    confirmVerifyEmail({
      email,
      verifyCode: verification
    }).then(response => {
      if (response.success) {
        setVerifyToken(response.data.emailVerifiedToken)
        action.setNavbarBackbutton(
          <UiIcon name="back"
                  onClick={() => { this.setState({ step: 2 }) }} />
        )
        onSuccess(email)
      }
    }).catch(error => {
      if (error.message === '"Wrong verify code"') {
        this.toggleAlert('verification')
      } else {
        this.toggleAlert('unknown')
      }
    })
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  inputEmailOnChange = (value) => {
    const {
      t
    } = this.props

    this.inputOnChange('email', value)
    if (value) {
      if (!isEmail(value)) {
        this.setStateInside('error', 'email', t('emailVerification.error.notCorrectEmail'))
      }
    }
  }

  inputVerificationOnChange = (value) => {
    this.inputOnChange('verification', value)
  }

  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
  }

  render() {
    const {
      step,
      showAlert,
      value,
      error
    } = this.state

    const {
      t
    } = this.props

    const isStep1SubmitDisabled = () => {
      return value.email.length <= 0 || !isEmail(value.email)
    }

    const isStep2SubmitDisabled = () => {
      return value.verification.length !== 5
    }

    return (
      <div className="email-verification">
        <div className="layout-container">
          {step === 1 &&
            <div className="step-email-1">
              <UiInput type="email"
                       placeholder={t('emailVerification.placeholder.email')}
                       label={t('common.email')}
                       value={value.email}
                       error={error.email}
                       onChange={val => this.inputEmailOnChange(val)}
                       onEnter={() => { if (!isStep1SubmitDisabled()) this.step1OnSubmit() }} />

              <UiButton text={t('emailVerification.sendCode')}
                        isDisabled={isStep1SubmitDisabled()}
                        isFixed={true}
                        onClick={this.step1OnSubmit} />
            </div>
          }

          {step === 2 &&
            <div className="step-email-2">
              <div className="description">
                <p>
                  <span dangerouslySetInnerHTML={{ __html: t('emailVerification.description', { email: value.email }) }} />
                </p>
                <button className="resend"
                        onClick={this.step1OnSubmit}>
                  {t('emailVerification.resendCode')}
                </button>
              </div>

              <UiInput type="number"
                       placeholder={t('emailVerification.placeholder.code')}
                       label={t('emailVerification.code')}
                       value={value.verification}
                       error={error.verification}
                       onChange={val => this.inputVerificationOnChange(val)}
                       onEnter={() => { if (!isStep2SubmitDisabled()) this.step2OnSubmit() }} />

              <UiButton text={t('common.confirm')}
                        isDisabled={isStep2SubmitDisabled()}
                        isFixed={true}
                        onClick={this.step2OnSubmit} />
            </div>
          }
        </div>

        {showAlert.verification &&
          <UiAlert content={t('emailVerification.error.wrongCode')}
                        onOkay={() => {
                          this.inputVerificationOnChange('')
                          this.toggleAlert('verification')
                        }} />
        }

        {showAlert.email &&
          <UiAlert content={t('emailVerification.error.wrongEmail')}
                        onOkay={() => {
                          this.inputEmailOnChange('')
                          this.toggleAlert('email')
                        }} />
        }

        {showAlert.unknown &&
          <UiAlert content={t('error.unknownRetry')}
                        onOkay={() => { this.toggleAlert('unknown') }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(EmailVerification, props)
export default StateContainer
