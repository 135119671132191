import React from 'react'
import Component from '../../../utils/Component'
import './Confirm.scss'

class UiConfirm extends Component {
  state = {
    transition: 0
  }

  onClick = (isOkay) => {
    const {
      preventClose
    } = this.state

    const {
      onClickAny,
      onOkay,
      onCancel
    } = this.props

    if (!preventClose) {
      this.setState({
        transition: -1
      })
    }
    setTimeout(() => {
      if (onClickAny) {
        onClickAny()
      }
      if (isOkay && onOkay){
        onOkay()
      } else if (!isOkay && onCancel){
        onCancel()
      }
    }, 200)
  }

  componentDidMount() {
    const {
      transition
    } = this.state

    if (transition === 0) {
      setTimeout(() => {
        this.setState({
          transition: 1
        })
      }, 1)
    }
  }

  render() {
    const {
      transition
    } = this.state

    const {
      children,
      content,
      okayText,
      cancelText
    } = this.props

    return (
      <div className={"confirm-wrap" + (transition < 1 ? " confirm-transition" : '')}>
        <div className="confirm-dim"> </div>
        <div className="confirm-layer">
          {content &&
            <div className="confirm-content">
              <span dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          }
          {children &&
            <div className="confirm-content">
              {children}
            </div>
          }
          <button className="confirm-button"
                  onClick={() => {this.onClick(false)}}>
            {cancelText}
          </button>
          <button className="confirm-button"
                  onClick={() => {this.onClick(true)}}>
            {okayText}
          </button>
        </div>
      </div>
    )
  }
}

UiConfirm.defaultProps = {
  content: '',
  okayText: 'Okay',
  onOkay: null,
  cancelText: 'Cancel',
  onCancel: null,
  onClickAny: null,
  preventClose: false
}

export default UiConfirm