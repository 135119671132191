import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { setLang } from '../../utils/lang'
import { isPassword, isUserId } from '../../utils/tool'
import {
  checkUserId,
  checkPassword,
  changeUserLanguage,
  changeUserNotification,
  changeUserId,
  changePassword,
  deleteAccount
} from '../../api/user'

import lang from '../../assets/data/lang'

import UiIcon from '../../components/ui/Icon'
import UiCheck from '../../components/ui/Check'
import UiCheckItem from '../../components/ui/CheckItem'
import UiInput from '../../components/ui/Input'
import UiButton from '../../components/ui/Button'
import UiAlert from '../../components/ui/layer/Alert'
import UiConfirmLayer from '../../components/ui/layer/Confirm'

import './Setting.scss'

class Setting extends Component {
  state = {
    backward: false,
    step: null,
    checkingPassword: false,
    showAlert: {
      userIdSuccess: false,
      passwordSuccess: false,
      leaveConfirm: false,
      leaveSuccess: false
    },
    value: {
      userId: '',
      password: '',
      passwordConfirm: '',
      currentPassword: ''
    },
    error: {
      userId: null,
      password: null,
      passwordConfirm: null,
      currentPassword: null
    },
    description: {
      userId: null,
      password: null,
      passwordConfirm: null,
      currentPassword: null
    },
    notification: {
      nearcleNotice: false,
      nearcleRecommend: false,
      communityComment: false,
      communityPopular: false,
      chattingGroup: false,
      chattingDirect: false
    },
    language: null,
    translation: true
  }

  onLogout = () => {
    const {
      action,
      history
    } = this.props

    setTimeout(() => {
      history.push('/h')
      window.location.reload(false)
    }, 500)

    action.setUserData(null)
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
  }

  inputCurrentPasswordOnChange = (value) => {
    this.inputOnChange('currentPassword', value)
    if (value) {
      this.setStateInside('error', 'currentPassword', '')
    }
  }

  inputUserIdOnChange = (value) => {
    const {
      t
    } = this.props

    this.inputOnChange('userId', value)
    if (value) {
      if (!isUserId(value)) {
        this.setStateInside('error', 'userId', t('signUp.error.userIdLimit'))
      } else {
        this.setStateInside('description', 'userId', t('signUp.error.userIdLimit'))
      }
    } else {
      this.setStateInside('description', 'userId', null)
    }
  }

  inputPasswordOnChange = (value) => {
    const {
      t
    } = this.props

    const {
      passwordConfirm
    } = this.state.value

    this.inputOnChange('password', value)
    if (value) {
      if (!isPassword(value)) {
        this.setStateInside('error', 'password', t('signUp.error.passwordLimit'))
      } else {
        this.setStateInside('description', 'password', t('signUp.error.canUsePassword'))
      }
    } else {
      this.setStateInside('description', 'password', null)
    }
    this.inputPasswordConfirmOnChange(passwordConfirm, value)
  }

  inputPasswordConfirmOnChange = (value, pw) => {
    const {
      t
    } = this.props

    const {
      password
    } = this.state.value

    const currentPassword = pw || password
    this.inputOnChange('passwordConfirm', value)
    if (value && value === currentPassword) {
      this.setStateInside('description', 'passwordConfirm', t('signUp.error.passwordMatch'))
    } else {
      this.setStateInside('description', 'passwordConfirm', null)
    }
  }

  onChangeTranslation = () => {
    const {
      translation
    } = this.state

    this.setState({ 'translation': !translation })
  }

  onChangeNotification = (type) => {
    const {
      notification
    } = this.state

    this.setStateInside('notification', type, !notification[type])

    changeUserNotification({ type: type })
  }

  stepCurrentPasswordOnSubmit = () => {
    const {
      t
    } = this.props

    const {
      currentPassword
    } = this.state.value

    if (!isPassword(currentPassword)) {
      this.setStateInside('error', 'currentPassword', t('signUp.error.passwordCheck'))
    } else {
      checkPassword({
        password: currentPassword.trim()
      }).then(response => {
        if (response.success && response.data && response.data.match) {
          this.setState({checkingPassword: false})
          this.setStateInside('value', 'currentPassword', '')
          this.setStateInside('error', 'currentPassword', '')
        } else {
          this.setStateInside('error', 'currentPassword', t('signUp.error.passwordCheck'))
        }
      }).catch(error => {
        this.toggleAlert('unknown')
      })
    }
  }

  stepUserIdOnSubmit = () => {
    const {
      t
    } = this.props

    const {
      userId
    } = this.state.value

    const {
      setUserData
    } = this.props.action

    checkUserId({
      userId: userId.trim()
    }).then(response => {
      if (response.data.isDuplicatedId) {
        this.setStateInside('error', 'userId', t('signUp.error.alreadyUsingId'))
      } else {
        changeUserId({
          userId: userId.trim()
        }).then(response => {
          if (response.success) {
            setUserData(response.data)
            this.toggleAlert('userIdSuccess')
          } else {
            this.toggleAlert('unknown')
          }
        }).catch(error => {
          this.toggleAlert('unknown')
        })
      }
    }).catch(error => {
      this.toggleAlert('unknown')
    })
  }

  stepPasswordOnSubmit = () => {
    const {
      password,
      passwordConfirm
    } = this.state.value

    changePassword({
      password: password.trim(),
      passwordConfirm: passwordConfirm.trim()
    }).then(response => {
      if (response.success) {
        this.toggleAlert('passwordSuccess')
      } else {
        this.toggleAlert('unknown')
      }
    }).catch(error => {
      this.toggleAlert('unknown')
    })
  }

  stepLeaveOnSubmit = () => {
    deleteAccount().then(response => {
      if (response.success) {
        this.toggleAlert('leaveSuccess')
      } else {
        this.toggleAlert('unknown')
      }
    }).catch(error => {
      this.toggleAlert('unknown')
    })
  }

  onChangeLanguage = (language) => {
    const {
      setUserData
    } = this.props.action

    this.setState({ language })
    setLang(language)

    changeUserLanguage({
      lang: language
    }).then(response => {
      if (response.success) {
        setUserData(response.data)
      } else {
        this.toggleAlert('unknown')
      }
    }).catch(error => {
      this.toggleAlert('unknown')
    })

    window.location.reload(false)
  }

  toStepIndex = () => {
    const {
      t,
      history,
      action
    } = this.props

    action.setNavbarTitle(t('setting.preference'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/my') }} />
    )
    this.setState({
      backward: true
    })
    setTimeout(() => {
      this.setState({
        step: null,
        checkingPassword: false
      })
    }, 0)
  }

  toStepUserId = () => {
    const {
      t,
      action,
      state
    } = this.props

    action.setNavbarTitle(t('setting.changeUserId.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.toStepIndex() }} />
    )
    this.setState({
      backward: false
    })
    setTimeout(() => {
      this.setState({
        step: 'userId',
        checkingPassword: true
      })
    }, 0)
    this.setStateInside('value', 'userId', state.userData.userId)
  }

  toStepPassword = () => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('setting.changePassword.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.toStepIndex() }} />
    )
    this.setState({
      backward: false
    })
    setTimeout(() => {
      this.setState({
        step: 'password',
        checkingPassword: true
      })
    }, 0)
  }

  toStepLeave = () => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('setting.deleteAccount.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.toStepIndex() }} />
    )
    this.setState({
      backward: false
    })
    setTimeout(() => {
      this.setState({
        step: 'leave',
        checkingPassword: true
      })
    }, 0)
  }

  toStepNotification = () => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('setting.notification.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.toStepIndex() }} />
    )
    this.setState({
      backward: false
    })
    setTimeout(() => {
      this.setState({
        step: 'notification',
        checkingPassword: false
      })
    }, 0)
  }

  toStepLanguage = () => {
    const {
      t,
      action
    } = this.props

    const {
      userData
    } = this.props.state

    action.setNavbarTitle(t('setting.language.title'))
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.toStepIndex() }} />
    )
    this.setState({
      language: userData.lang,
      backward: false
    })
    setTimeout(() => {
      this.setState({
        step: 'language',
        checkingPassword: false
      })
    }, 0)
  }

  setNotificationData = () => {
    const {
      notification
    } = this.state

    const {
      userData
    } = this.props.state

    if (userData && userData.notification) {
      const noti = {}
      for (const key of Object.keys(notification)) {
        noti[key] = !!userData.notification[key]
      }
      this.setState({
        notification: noti
      })
    }
  }

  componentDidMount() {
    const {
      action
    } = this.props

    action.fetchUserData(true).then(() => {
      this.setNotificationData()
    })

    this.toStepIndex()
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)
  }

  render() {
    const nodeRefContent = createRef(null)

    const {
      backward,
      step,
      checkingPassword,
      showAlert,
      value,
      error,
      description,
      notification,
      language,
      // translation
    } = this.state

    const {
      t,
      // state
    } = this.props

    const {
      userData
    } = this.props.state

    const isStepCurrentPasswordSubmitDisabled = () => {
      return !value.currentPassword || error.currentPassword
    }

    const isStepUserIdSubmitDisabled = () => {
      return !value.userId || error.userId || value.userId === userData.userId
    }

    const isStepPasswordSubmitDisabled = () => {
      return !description.password || !description.passwordConfirm
    }

    return(
      <TransitionGroup>
        <CSSTransition key={`${step}-${checkingPassword}`}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefContent}
                       classNames="page-slide">
          <div className={`my-setting ${backward ? 'backward' : ''}`}
               ref={nodeRefContent}>
            <div className="layout-container">
              {!step &&
                <div className="setting-index">
                  <ul className="my-menu">
                    <li className="label">
                      {t('setting.appSetting')}
                    </li>
                    <li>
                      <button onClick={() => { this.toStepNotification() }}>
                        <span className="value-left">{t('setting.notification.title')}</span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow" />
                        </span>
                      </button>
                    </li>
                    <li>
                      <button onClick={() => { this.toStepLanguage() }}>
                        <span className="value-left">{t('setting.language.my')}</span>
                        {(userData && userData.lang) &&
                          <span className="value-right">
                            {lang[userData.lang].name}
                          </span>
                        }
                      </button>
                    </li>
                    {/*<li>*/}
                    {/*  <span className="value-left">{t('setting.notification.translation')}</span>*/}
                    {/*  <span className="value-right">*/}
                    {/*    <UiCheck type="switch"*/}
                    {/*             thisValue={true}*/}
                    {/*             value={[translation]}*/}
                    {/*             onChange={() => { this.onChangeTranslation() }} />*/}
                    {/*  </span>*/}
                    {/*</li>*/}
                  </ul>
                  <ul className="my-menu">
                    <li className="label">
                      {t('setting.accountSetting')}
                    </li>
                    <li>
                      <span className="value-left">{t('common.email')}</span>
                      {userData &&
                        <span className="value-right gray">
                            {userData.email}
                        </span>
                      }
                    </li>
                    <li>
                      <button onClick={() => { this.toStepUserId() }}>
                        <span className="value-left">{t('setting.changeUserId.title')}</span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow" />
                        </span>
                      </button>
                    </li>
                    <li>
                      <button onClick={() => { this.toStepPassword() }}>
                        <span className="value-left">{t('setting.changePassword.title')}</span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow" />
                        </span>
                      </button>
                    </li>
                    <li>
                      <button onClick={() => { this.toStepLeave() }}>
                        <span className="value-left">{t('setting.deleteAccount.title')}</span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow" />
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              }

              {checkingPassword &&
                <div className="setting-check-password">
                  <UiInput type="password"
                           placeholder={t('signUp.placeholder.password')}
                           label={t('common.currentPassword')}
                           value={value.currentPassword}
                           error={error.currentPassword}
                           description={description.currentPassword}
                           onChange={val => this.inputCurrentPasswordOnChange(val)} />

                  <UiButton text={t('common.complete')}
                            isDisabled={isStepCurrentPasswordSubmitDisabled()}
                            isFixed={true}
                            onClick={this.stepCurrentPasswordOnSubmit} />
                </div>
              }

              {!checkingPassword && step === 'userId' &&
                <div className="setting-userid">
                  <UiInput type="text"
                           placeholder={t('signUp.placeholder.userId')}
                           label={t('common.userId')}
                           value={value.userId}
                           error={error.userId}
                           description={description.userId}
                           onChange={val => this.inputUserIdOnChange(val)} />

                  <UiButton text={t('common.complete')}
                            isDisabled={isStepUserIdSubmitDisabled()}
                            isFixed={true}
                            onClick={this.stepUserIdOnSubmit} />

                  {showAlert.userIdSuccess &&
                    <UiAlert content={t('setting.changeUserId.complete')}
                                  onOkay={() => {
                                    this.toStepIndex()
                                    this.toggleAlert('userIdSuccess')
                                  }} />
                  }
                </div>
              }

              {!checkingPassword && step === 'password' &&
                <div className="setting-password">
                  <UiInput type="password"
                           placeholder={t('signUp.placeholder.password')}
                           label={t('common.newPassword')}
                           value={value.password}
                           error={error.password}
                           description={description.password}
                           onChange={val => this.inputPasswordOnChange(val)} />

                  <UiInput type="password"
                           placeholder={t('signUp.placeholder.password')}
                           value={value.passwordConfirm}
                           error={error.passwordConfirm}
                           description={description.passwordConfirm}
                           onChange={val => this.inputPasswordConfirmOnChange(val)} />

                  <UiButton text={t('common.confirm')}
                            isDisabled={isStepPasswordSubmitDisabled()}
                            isFixed={true}
                            onClick={this.stepPasswordOnSubmit} />

                  {showAlert.passwordSuccess &&
                    <UiAlert content={t('setting.changePassword.complete')}
                                  onOkay={() => {
                                    this.toStepIndex()
                                    this.toggleAlert('passwordSuccess')
                                  }} />
                  }
                </div>
              }

              {!checkingPassword && step === 'leave' &&
                <div className="setting-leave">
                  <h3>{t('setting.deleteAccount.tip.title')}</h3>
                  <ul>
                    <li>
                      {t('setting.deleteAccount.tip.description.0')}
                    </li>
                    <li>
                      {t('setting.deleteAccount.tip.description.1')}
                    </li>
                    <li>
                      {t('setting.deleteAccount.tip.description.2')}
                    </li>
                    <li>
                      {t('setting.deleteAccount.tip.description.3')}
                    </li>
                  </ul>

                  <UiButton text={t('setting.deleteAccount.title')}
                            isFixed={true}
                            onClick={() => { this.toggleAlert('leaveConfirm') }} />

                  {showAlert.leaveConfirm &&
                    <UiConfirmLayer content={t('setting.deleteAccount.warning')}
                                    okayText={t('common.yes')}
                                    onOkay={() => { this.stepLeaveOnSubmit() }}
                                    cancelText={t('common.no')}
                                    onCancel={() => {
                                      this.toggleAlert('leaveConfirm')
                                    }} />
                  }

                  {showAlert.leaveSuccess &&
                    <UiAlert content={t('setting.deleteAccount.complete')}
                                  onOkay={() => { this.onLogout() }} />
                  }
                </div>
              }

              {step === 'notification' &&
                <div className="setting-notification">
                  <ul className="my-menu">
                    <li className="label">
                      {t('setting.notification.nearcle')}
                    </li>
                    <li>
                      <span className="value-left">{t('setting.notification.nearcleNotice')}</span>
                      <span className="value-right">
                        <UiCheck type="switch"
                                 thisValue={true}
                                 value={[notification.nearcleNotice]}
                                 onChange={() => { this.onChangeNotification('nearcleNotice') }} />
                      </span>
                    </li>
                    <li>
                      <span className="value-left">{t('setting.notification.nearcleRecommend')}</span>
                      <span className="value-right">
                        <UiCheck type="switch"
                                 thisValue={true}
                                 value={[notification.nearcleRecommend]}
                                 onChange={() => { this.onChangeNotification('nearcleRecommend') }} />
                      </span>
                    </li>
                  </ul>
                  <ul className="my-menu">
                    <li className="label">
                      {t('setting.notification.community')}
                    </li>
                    <li>
                      <span className="value-left">{t('setting.notification.communityComment')}</span>
                      <span className="value-right">
                        <UiCheck type="switch"
                                 thisValue={true}
                                 value={[notification.communityComment]}
                                 onChange={() => { this.onChangeNotification('communityComment') }} />
                      </span>
                    </li>
                    <li>
                      <span className="value-left">{t('setting.notification.communityPopular')}</span>
                      <span className="value-right">
                        <UiCheck type="switch"
                                 thisValue={true}
                                 value={[notification.communityPopular]}
                                 onChange={() => { this.onChangeNotification('communityPopular') }} />
                      </span>
                    </li>
                  </ul>
                  <ul className="my-menu">
                    <li className="label">
                     {t('setting.notification.chatting')}
                    </li>
                    <li>
                      <span className="value-left">{t('setting.notification.nearcle')}</span>
                      <span className="value-right">
                        <UiCheck type="switch"
                                 thisValue={true}
                                 value={[notification.chattingGroup]}
                                 onChange={() => { this.onChangeNotification('chattingGroup') }} />
                      </span>
                    </li>
                    <li>
                      <span className="value-left">{t('setting.notification.chattingDirect')}</span>
                      <span className="value-right">
                        <UiCheck type="switch"
                                 thisValue={true}
                                 value={[notification.chattingDirect]}
                                 onChange={() => { this.onChangeNotification('chattingDirect') }} />
                      </span>
                    </li>
                  </ul>
                </div>
              }

              {step === 'language' &&
                <div className="setting-language">
                  {Object.entries(lang).map(([key, value]) => (
                    <UiCheckItem key={key}
                                 thisValue={key}
                                 value={[language]}
                                 title={value.name}
                                 onChange={() => this.onChangeLanguage(key)} />
                  ))}
                </div>
              }
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

const StateContainer = (props) => StateConsumer(Setting, props)
export default withRouter(StateContainer)
