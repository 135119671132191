import React from 'react'
import { StateConsumer } from '../../../context/StateProvider'
import Component from '../../../utils/Component'
import './Alert.scss'

class UiAlert extends Component {
  state = {
    transition: 0
  }

  okayOnClick = () => {
    const {
      preventClose,
      onOkay
    } = this.props

    if (!preventClose) {
      this.setState({
        transition: -1
      })
    }
    setTimeout(() => {
      if (onOkay){
        onOkay()
      }
    }, 200)
  }

  componentDidMount() {
    if (this.state.transition === 0) {
      setTimeout(() => {
        this.setState({
          transition: 1
        })
      }, 1)
    }
  }

  render() {
    const {
      t,
      content,
      children,
      okayText
    } = this.props

    const ok = okayText || t('common.confirm')

    return (
      <div className={"alert-wrap" + (this.state.transition < 1 ? " alert-transition" : '')}>
        <div className="alert-dim" />
        <div className="alert-layer">
          {content &&
            <div className="alert-content">
              <span dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          }
          {children &&
            <div className="alert-content">
              {children}
            </div>
          }
          <button className="alert-button"
                  onClick={this.okayOnClick}>
              {ok}
          </button>
        </div>
      </div>
    )
  }
}

UiAlert.defaultProps = {
  content: '',
  okayText: '',
  onOkay: null,
  preventClose: false
}

const StateContainer = (props) => StateConsumer(UiAlert, props)
export default StateContainer