import Component from './utils/Component'
import { BrowserRouter } from 'react-router-dom'

import './assets/styles/base.scss'

import Router from './routes'
import { StateProvider } from './context/StateProvider'

class App extends Component {
  render() {
    return (
      <StateProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </StateProvider>
    )
  }
}

export default App
