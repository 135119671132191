import React from 'react';
import Component from '../../utils/Component';

import './Icon.scss'

class UiIcon extends Component {
  onClick = () => {
    const {
      onClick
    } = this.props;

    if (onClick) {
      onClick();
    }
  };

  render() {
    const {
      name,
      hoverName,
      activeName,
      badge,
      alt,
      width,
      height,
      size,
      onClick,
      button
    } = this.props;

    let className = `ui-icon`
    if (onClick) {
      className += ` clickable`
    }
    if (hoverName) {
      className += ` has-hover`
    }
    if (activeName) {
      className += ` has-active`
    }
    if (button) {
      className += ` button`
    }

    let sizeName = ``
    if (size) {
      sizeName = `size--${size}`
    }

    let style = {}
    if (width) {
      style.width = width
    }
    if (height) {
      style.height = height
    }

    let altText = null
    if (alt) {
      altText = (<span className="a11y-invisible">{alt}</span>)
    }

    let badgeText = null
    if (badge) {
      badgeText = (<span className="icon-badge">{badge}</span>)
    }

    return (
      <span className={className}
            onClick={this.onClick}>
        <i className={`icon--${name} ${sizeName}`}
           style={style} />
        { hoverName &&
          <i className={`hover-icon icon--${hoverName} ${sizeName}`}
             style={style} />
        }
        { activeName &&
          <i className={`active-icon icon--${activeName} ${sizeName}`}
             style={style} />
        }
        {altText}
        {badgeText}
      </span>
    );
  }
}

UiIcon.defaultProps = {
  name: '',
  hoverName: null,
  activeName: null,
  badge: null,
  alt: '',
  width: null,
  height: null,
  size: null,
  onClick: null,
  button: false
};

export default UiIcon;
