import { withRouter } from 'react-router-dom'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { timeString } from '../../utils/tool'
import {
  boardPostCommentInfo
} from '../../api/community'

import UiIcon from '../../components/ui/Icon'
import UiCountry from '../../components/ui/Country'
import UiWysiwygContent from '../../components/ui/WysiwygContent'

import BoardPostCommentItem from '../../pages/community/BoardPostCommentItem'

import './BoardPostCommentItem.scss'

class CommunityBoardPostComment extends Component {
  state = {
    originalComment: null,
    seeOriginal: false
  }

  onSeeOriginal = () => {
    const {
      originalComment,
      seeOriginal
    } = this.state

    if (!originalComment) {
      this.loadOriginalLang().then(() => {
        this.setState({
          seeOriginal: !seeOriginal
        })
      })
    } else {
      this.setState({
        seeOriginal: !seeOriginal
      })
    }
  }

  loadOriginalLang = () => {
    const {
      comment,
      loading
    } = this.props

    loading(true)
    return new Promise(resolve => {
      boardPostCommentInfo({
        id: comment.id,
        lang: comment.originalLang,
        isOriginal: true
      }).then(response => {
        this.setState({
          originalComment: response.data
        })
        loading(false)
        resolve()
      })
    })
  }

  render () {
    const {
      originalComment,
      seeOriginal
    } = this.state

    const {
      t,
      comment,
      openCommentContext,
      onLikeComment,
      onReplyingComment,
      onToggleProfile,
      loading
    } = this.props

    const {
      userData
    } = this.props.state

    let isOriginal = originalComment && seeOriginal

    const blocked = userData ? (userData.blockList && comment.author && userData.blockList.includes(comment.author.id)) : false

    return (
      <div className="board-post-comment-item">
        {blocked &&
          <>
            <span className="author blocked">
              {t('community.blocked')}
            </span>
            <span className="meta">
              <span className="date">
                {timeString(comment.createdAt, t)}
              </span>
            </span>
          </>
        }
        {!blocked &&
          <>
            {comment.author &&
              <span className="author">
                <span className="author-wrap"
                      onClick={() => { if (onToggleProfile) { onToggleProfile(comment.author) } }}>
                  <UiCountry code={comment.country} />
                  {comment.author.nickname}
                </span>

                {!comment.reported &&
                  <span className="hamburger">
                    <UiIcon name={`translate ${seeOriginal ? 'active' : ''}`}
                            onClick={this.onSeeOriginal} />
                    <UiIcon name="more"
                            onClick={() => { openCommentContext(comment) }} />
                  </span>
                }
              </span>
            }
            {!comment.reported &&
              <span className="summary">
                <UiWysiwygContent content={isOriginal ? originalComment.content : comment.content} />
              </span>
            }
            {comment.reported &&
              <span className="summary reported">
                <UiWysiwygContent content={t('community.reported.comment')} />
              </span>
            }
            <span className="meta">
              {!comment.reported &&
                <span className="count">
                  <button className="like"
                          onClick={() => { onLikeComment(comment) }}>
                    <UiIcon name={`post-like ${comment.liked ? 'active' : ''}`} />
                    {comment.meta.countLike || t('common.like')}
                  </button>
                  {!comment.parent &&
                    <button className="comment"
                            onClick={() => { onReplyingComment(comment) }}>
                      <UiIcon name="post-comment"/>
                      {t('community.writeReply')}
                    </button>
                  }
                </span>
              }
              <span className="date">
                {timeString(comment.createdAt, t)}
              </span>
            </span>
          </>
        }
        {comment.children && comment.children.length > 0 &&
          <ul className="children">
            {comment.children.map((child, i) => (
              <li key={`comment-list-${comment.id}-${i}`}
                  id={`comment-${child.id}`}>
                <div className="reply-icon">
                  <UiIcon name="reply" />
                </div>
                <BoardPostCommentItem comment={child}
                                      openCommentContext={openCommentContext}
                                      onLikeComment={onLikeComment}
                                      onReplyingComment={onReplyingComment}
                                      onToggleProfile={onToggleProfile}
                                      loading={loading} />
              </li>
            ))}
          </ul>
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(CommunityBoardPostComment, props)
export default withRouter(StateContainer)
