import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { objectToForm, br2nl, removeTags } from '../../utils/tool'

import { boardPostCreate } from '../../api/community'

import UiIcon from '../../components/ui/Icon'
import UiImages from '../../components/ui/Images'
import UiAlert from '../../components/ui/layer/Alert'

import './BoardPostCreate.scss'

class CommunityBoardPostCreate extends Component {
  state = {
    loading: false,
    showAlert: {
      titleRequired: false,
      contentRequired: false,
      timeLimit: false
    },
    value: {
      title: '',
      content: '',
      files: [],
      thumbnails: []
    },
    initialFiles: [],
    error: {
      title: null,
      content: null,
      files: null
    },
    titleHeight: 51
  }

  toggleAlert = (name) => {
    const {
      showAlert
    } = this.state

    this.setStateInside('showAlert', name, !showAlert[name])
  }

  inputOnChange = (name, value) => {
    if (name === 'title') {
      value = value.replace(/\s/g, ' ').substr(0, 100)
    }
    this.setStateInside('value', name, value)
    this.setStateInside('error', name, null)
  }

  onAddFile = (file, thumbnail) => {
    const {
      value
    } = this.state

    if (!thumbnail) {
      file = thumbnail
    }

    this.setStateInside('value', 'files', [
      ...value.files,
      file
    ])
    this.setStateInside('value', 'thumbnails', [
      ...value.thumbnails,
      thumbnail
    ])
  }

  onRemoveFile = (index) => {
    const {
      files,
      thumbnails
    } = this.state.value

    files.splice(index, 1)
    thumbnails.splice(index, 1)

    this.setStateInside('value', 'files', [
      ...files
    ])
    this.setStateInside('value', 'thumbnails', [
      ...thumbnails
    ])
  }

  onPost = () => {
    const {
      value,
    } = this.state

    if (!value.title) {
      this.toggleAlert('titleRequired')
    } else if (!value.content) {
      this.toggleAlert('contentRequired')
    } else {
      const {
        board,
        post,
        afterPost
      } = this.props

      this.setState({ loading: true })

      const values = {
        board: board.id,
        ...value
      }

      if (post) {
        values.id = post.id
      }

      boardPostCreate(objectToForm(values)).then(response => {
        if (afterPost) {
          afterPost(response.data)
        }
      }).catch(error => {
        this.setState({ loading: false })
        if (error.message === '"Time Limit"') {
          this.toggleAlert('timeLimit')
        } else {
          this.toggleAlert('unknown')
        }
      })
    }
  }

  componentDidMount () {
    const {
      action,
      post
    } = this.props

    action.setNavbarButtons(
      <UiIcon name="send"
              activeName="send active"
              onClick={() => { this.onPost() }} />
    )
    action.setLayoutShowFooter(false)

    if (post) {
      this.setStateInside('value', 'title', post.title)
      this.setStateInside('value', 'content', removeTags(br2nl(post.content)))
      if (post.files.length) {
        this.setStateInside('value', 'files', post.files)
        this.setStateInside('value', 'thumbnails', post.files.map(file => file.thumbKey))
        this.setState({
          initialFiles: post.files
        })
      }
    }
  }

  setTitleHeight = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight - 27}px`;
    this.setState({
      titleHeight: e.target.scrollHeight
    })
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefDescription = createRef(null)

    const {
      loading,
      showAlert,
      value,
      initialFiles,
      titleHeight
    } = this.state

    const {
      t
    } = this.props

    return(
      <div className="board-post-write">
        <CSSTransition in={loading}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefLoader}
                       classNames="fade">
          <div className="data-loader"
               ref={nodeRefLoader}>
            <UiIcon name="loader" />
          </div>
        </CSSTransition>
        <div className="layout-container">
          <div className="title">
            <textarea id="titleInput"
                      placeholder={t('community.placeholder.title')}
                      value={value.title}
                      rows="1"
                      onInput={this.setTitleHeight}
                      onChange={(e) => { this.inputOnChange('title', String(e.target.value)) }} />
          </div>
          <div className="content">
            <textarea placeholder={t('community.placeholder.content')}
                      style={{ height: `calc(100vh - ${titleHeight + 23 + 57 + 146}px)` }}
                      value={value.content}
                      onChange={(e) => { this.inputOnChange('content', String(e.target.value)) }} />
            <CSSTransition in={!(value.title || value.content)}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           nodeRef={nodeRefDescription}
                           classNames="fade">
              <div className="description"
                   ref={nodeRefDescription}>
                <h5>{t('community.tip.title')}</h5>
                <ol>
                  <li>{t('community.tip.description.0')}</li>
                  <li>{t('community.tip.description.1')}</li>
                </ol>
              </div>
            </CSSTransition>
          </div>
        </div>
        <div className="files">
          <UiImages value={value.files}
                    initialFiles={initialFiles}
                    resizeSize={{ height: 750 }}
                    onAddFile={this.onAddFile}
                    onRemoveFile={this.onRemoveFile} />
        </div>

        {showAlert.titleRequired &&
          <UiAlert content={t('community.error.title')}
                        onOkay={() => {
                          this.toggleAlert('titleRequired')
                        }} />
        }
        {showAlert.contentRequired &&
          <UiAlert content={t('community.error.content')}
                        onOkay={() => {
                          this.toggleAlert('contentRequired')
                        }} />
        }
        {showAlert.timeLimit &&
          <UiAlert content={t('community.error.articleTimeLimit')}
                        onOkay={() => {
                          this.toggleAlert('timeLimit')
                        }} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(CommunityBoardPostCreate, props)
export default withRouter(StateContainer)
