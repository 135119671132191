import Dropzone from 'react-dropzone'
import { withRouter } from 'react-router-dom'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { resizeImage } from '../../utils/tool'

import UiIcon from '../../components/ui/Icon'
import UiAlert from '../../components/ui/layer/Alert'

import './ChatCreate.scss'

class TalkChatCreate extends Component {
  state = {
    value: {
      content: '',
      files: [],
      thumbnails: []
    },
    contentHeight: 51,
    resizeSize: { width: 500, height: null },
    imageType: 'image/jpeg',
    imageQuality: 0.7,
    error: null
  }

  inputOnChange = (name, value) => {
    this.setStateInside('value', name, value)
    this.setState({ 'error': null })
    setTimeout(() => {
      this.setContentHeight()
    }, 0)
  }

  onPost = () => {
    const {
      value,
    } = this.state

    const {
      onSubmit
    } = this.props

    const inputEl = document.getElementById('chatInput')
    inputEl.focus()

    if (value.content) {
      onSubmit({
        text: value.content
      })
      this.inputOnChange('content', '')
    }
  }

  onDrop = (files) => {
    const {
      t,
      resizeSize,
      imageType,
      imageQuality
    } = this.state

    const setFile = (tempId, file) => {
      return new Promise(resolve => {
        const reader = new FileReader()

        const setError = () => {
          this.setState({ error: t('community.error.image') })
          resolve()
        }
        reader.onabort = () => setError()
        reader.onerror = () => setError()
        reader.onload = () => {
          resizeImage(file, reader.result, { resizeSize, imageType, imageQuality })
            .then(({ dataUri, thumbnailUrl, resizedFile, originalFile, ratio }) => {
              this.onFile(originalFile, resizedFile, dataUri, thumbnailUrl, ratio, tempId)
              resolve()
            }).catch(err => {
              console.error(err)
              setError()
            })
        }
        reader.readAsDataURL(file)
      })
    }

    const promises = []
    const tempId = this.onFile(null, null, null, null, null)

    for (const [index, file] of files.entries()) {
      if (!file || (file.type && file.type.substr(0, 6) !== 'image/')) {
        this.setState({ error: t('community.error.imageOnly') })
      } else {
        let id = tempId + (index === files.length - 1 ? '' : ` ${index}`)
        promises.push(() => setFile(id, file))
      }
    }

    if (promises.length) {
      promises.reduce((chain, promise) => chain.then(() => promise()), Promise.resolve())
    }
  }

  onFile = (originalFile, resizedFile, originalUrl, thumbnailUrl, ratio, temp) => {
    const {
      onSubmit
    } = this.props

    const now = new Date()
    const tempId = temp || now.toString()

    const message = {
      type: 'image',
      originalFile,
      resizedFile,
      originalUrl,
      thumbnailUrl,
      ratio,
      tempId
    }

    if (!thumbnailUrl) {
      message.dummyId = tempId
    }

    onSubmit(message)

    return tempId
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault()
    }
  }

  onKeyUp = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      this.onPost()
    }
  }

  setContentHeight = () => {
    const inputEl = document.getElementById('chatInput')
    inputEl.style.height = 'inherit';
    inputEl.style.height = `${inputEl.scrollHeight - 22}px`;
    this.setState({
      contentHeight: inputEl.scrollHeight
    })
  }

  render () {
    const {
      value,
      error
    } = this.state

    const {
      t,
      replying,
      // onEndReplying,
      onFocus,
      disabled
    } = this.props

    return(
      <div className="chat-create">
        <div className="layout-container">
          {/*{replying &&*/}
          {/*  <div className="replying">*/}
          {/*    <em>{t('community.reply')}</em>*/}
          {/*    {replying.summary}*/}
          {/*    <UiIcon name="close-circle"*/}
          {/*            onClick={onEndReplying} />*/}
          {/*  </div>*/}
          {/*}*/}

          <div className={`content ${replying ? 'replying' : ''}`}>
            {/*<div className="reply-icon">*/}
            {/*  <UiIcon name="reply" />*/}
            {/*</div>*/}

            <textarea id="chatInput"
                      placeholder={disabled ? t('talk.placeholder.disabled') : ''}
                      value={value.content}
                      rows="1"
                      onInput={this.setContentHeight}
                      onFocus={onFocus}
                      onKeyDown={e => { this.onKeyDown(e) } }
                      onKeyUp={e => { this.onKeyUp(e) } }
                      onChange={(e) => { this.inputOnChange('content', String(e.target.value)) }} />
          </div>

          <Dropzone noClick
                    onDrop={acceptedFiles => this.onDrop(acceptedFiles)}>
            {({ getInputProps, open }) => {
              return (
                <div className="add-button">
                  <input { ...getInputProps() } />
                  <UiIcon name="plus"
                          onClick={open} />
                </div>
              )
            }}
          </Dropzone>

          <div className="button">
            <UiIcon name="send"
                    activeName="send active"
                    onClick={this.onPost} />
          </div>
        </div>

        {error &&
          <UiAlert content={error} />
        }
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(TalkChatCreate, props)
export default withRouter(StateContainer)
