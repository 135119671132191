import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import { format } from 'date-fns'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'
import { addListener, removeListener } from '../../utils/tool'
import constants from '../../utils/constant'

import { articleList, articleInfo } from '../../api/archive'

import UiIcon from '../../components/ui/Icon'
import UiInput from '../../components/ui/Input'
import UiWysiwygContent from '../../components/ui/WysiwygContent'

import './ServiceCommon.scss'

class ServiceNotice extends Component {
  state = {
    loading: false,
    list: [],
    searchList: [],
    article: null,
    showArticle: 0,
    showSearch: 0,
    keyword: '',
    listOption: {},
    searchListOption: {},
    initialListOption: {
      lastDate: null,
      page: 0,
      count: 20
    },
    noMoreList: false,
    noMoreSearchList: false
  }

  keywordOnChange = (keyword) => {
    const { keywordTimer } = this.state
    clearTimeout(keywordTimer)
    this.setState({
      loading: true,
      keyword,
      keywordTimer: setTimeout(() => {
        this.onSearch()
      }, 1000)
    })
  }

  onSearch = () => {
    const {
      keyword,
      initialListOption
    } = this.state

    if (keyword.trim()) {
      this.setState({
        searchListOption: initialListOption,
        noMoreSearchList: false
      })

      removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)

      articleList({
        id: constants.NOTICE_ID,
        keyword,
        ...initialListOption
      }).then(result => {
        const list = result.data.list
        this.setState({
          loading: false,
          searchList: list,
          searchListOption: {
            ...initialListOption,
            lastDate: list.length ? list[list.length - 1].createdAt : false
          },
          noMoreSearchList: list.length < initialListOption.count
        })

        addListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)
      }).catch(error => {
        console.error(error)
      })
    } else {
      this.setState({
        loading: false,
        searchList: []
      })
    }
  }

  loadNextSearchPage = (loading = true) => {
    const {
      keyword,
      searchList,
      searchListOption
    } = this.state

    const newListOption = {
      ...searchListOption,
      page: searchListOption.page + 1
    }

    this.setState({
      searchListOption: newListOption
    })

    if (loading) {
      this.setState({ loading: true })
    }
    articleList({
      id: constants.NOTICE_ID,
      keyword,
      ...newListOption
    }).then(result => {
      const nextList = result.data.list
      const newList = searchList.concat(nextList)
      this.setState({
        loading: false,
        searchList: newList,
        searchListOption: {
          ...newListOption,
          lastDate: newList.length ? newList[newList.length - 1].createdAt : false
        },
        noMoreSearchList: nextList.length < searchListOption.count
      })
    }).catch(error => {
      console.error(error)
    })
  }

  openArticle = (article) => {
    this.loadArticle(article.id)
  }

  closeArticle = () => {
    const {
      showSearch
    } = this.state

    if (showSearch) {
      this.setSearchTitle(true)
    } else {
      this.setInitialTitle(true)
    }
    this.setState({
      showArticle: -1
    })
  }

  openSearch = () => {
    this.setState({
      showSearch: 1,
      searchList: [],
      keyword: ''
    })

    this.setSearchTitle()
  }

  closeSearch = () => {
    this.setInitialTitle(true)
    this.setState({
      showSearch: -1
    })
  }

  loadList = (loading = true) => {
    const {
      initialListOption
    } = this.state

    this.setState({
      listOption: initialListOption,
      noMoreList: false
    })

    if (loading) {
      this.setState({ loading: true })
    }

    removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    articleList({
      id: constants.NOTICE_ID,
      ...initialListOption
    }).then(result => {
      const list = result.data.list
      this.setState({
        loading: false,
        list,
        listOption: {
          ...initialListOption,
          lastDate: list.length ? list[list.length - 1].createdAt : false
        },
        firstDate: list.length ? list[0].createdAt : false,
        noMoreList: list.length < initialListOption.count
      })

      addListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    }).catch(error => {
      console.error(error)
    })
  }

  loadNextPage = (loading = true) => {
    const {
      list,
      listOption
    } = this.state

    const newListOption = {
      ...listOption,
      page: listOption.page + 1
    }

    this.setState({
      listOption: newListOption
    })

    if (loading) {
      this.setState({ loading: true })
    }
    articleList({
      id: constants.NOTICE_ID,
      ...newListOption
    }).then(result => {
      const nextList = result.data.list
      const newList = list.concat(nextList)
      this.setState({
        loading: false,
        list: newList,
        listOption: {
          ...newListOption,
          lastDate: newList.length ? newList[newList.length - 1].createdAt : false
        },
        noMoreList: nextList.length < listOption.count
      })
    }).catch(error => {
      console.error(error)
    })
  }

  trackListScrolling = () => {
    const {
      loading,
      noMoreList
    } = this.state
    if (noMoreList) {
      removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    }
    const el = document.getElementById('boardList');
    if (!noMoreList && !loading) {
      if (el.getBoundingClientRect().bottom < window.innerHeight + 500) {
        this.setState({ loading: true })
        this.loadNextPage()
      }
    }
  }

  trackSearchListScrolling = () => {
    const {
      loading,
      noMoreSearchList
    } = this.state
    if (noMoreSearchList) {
      document.getElementById('searchContainer').removeEventListener('scroll', this.trackSearchListScrolling)
    }
    const el = document.getElementById('boardSearchList');
    if (!noMoreSearchList && !loading) {
      if (el.getBoundingClientRect().bottom < window.innerHeight + 500) {
        this.setState({ loading: true })
        this.loadNextSearchPage()
      }
    }
  }

  loadArticle = (id) => {
    const {
      action
    } = this.props

    this.setState({ loading: true })
    articleInfo({
      id
    }).then(result => {
      this.setState({
        loading: false,
        article: result.data,
        showArticle: 1
      })

      action.setNavbarTitle(null)
      action.setNavbarBackbutton(
        <UiIcon name="back"
                onClick={() => { this.closeArticle() }} />
      )
      action.setNavbarButtons(null)
    }).catch(error => {
      console.error(error)
    })
  }

  setSearchTitle = (backward = false) => {
    const {
      t,
      action
    } = this.props

    action.setNavbarTitle(t('common.search'), backward)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { this.closeSearch() }} />
    )
    action.setNavbarButtons(null)
  }

  setInitialTitle = (backward = false) => {
    const {
      t,
      action,
      history
    } = this.props

    action.setNavbarTitle(t('setting.notice.title'), backward)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/my') }} />
    )
    action.setNavbarButtons(
      <UiIcon name="search"
              onClick={() => { this.openSearch() }} />
    )
  }

  componentDidMount () {
    const {
      action
    } = this.props

    this.setInitialTitle()
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)

    this.loadList()
  }

  componentWillUnmount() {
    removeListener(document.getElementById('defaultLayout'), 'scroll', this.trackListScrolling)
    removeListener(document.getElementById('searchContainer'), 'scroll', this.trackSearchListScrolling)
  }

  render() {
    const nodeRefLoader = createRef(null)
    const nodeRefList = createRef(null)
    const nodeRefSearchList = createRef(null)
    const nodeRefSearchListLoading = createRef(null)
    const nodeRefArticle = createRef(null)
    const nodeRefSearch = createRef(null)

    const {
      loading,
      list,
      searchList,
      article,
      showArticle,
      showSearch,
      keyword
    } = this.state

    const {
      t
    } = this.props

    return(
      <div className="layout-container">
        <div className="service-center">
          <CSSTransition in={loading}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefLoader}
                         classNames="fade">
            <div className="data-loader"
                 ref={nodeRefLoader}>
              <UiIcon name="loader" />
            </div>
          </CSSTransition>

          <CSSTransition in={list.length > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefList}
                         classNames="fade">
            <ul className="service-article"
                id="boardList"
                ref={nodeRefList}>
              {list.map((article, i) => {
                return (
                  <li key={`list-button-${i}`}>
                    <button onClick={() => { this.openArticle(article) }}>
                      <span className="title">
                        {article.title}
                      </span>
                      <span className="date">
                        {format(new Date(article.createdAt), 'yyyy. MM. dd')}
                      </span>
                    </button>
                  </li>
                )
              })}
            </ul>
          </CSSTransition>

          <CSSTransition in={showSearch > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefSearch}
                         classNames="page-slide">
            <div className={`service-content ${showSearch < 0 ? 'backward' : ''}`}
                 id="searchContainer"
                 ref={nodeRefSearch}>
              <div className="layout-container">
                <div className="search">
                  <UiInput type="text"
                           color="fill"
                           placeholder={t('common.search')}
                           icon="search"
                           value={keyword}
                           onChange={value => this.keywordOnChange(value)} />
                </div>
                <CSSTransition in={searchList.length < 1}
                               timeout={500}
                               mountOnEnter={true}
                               unmountOnExit={true}
                               nodeRef={nodeRefSearchListLoading}
                               classNames="fade">
                  <ul className="service-article"
                      ref={nodeRefSearchListLoading}>
                    <li className="data-loader">
                      <UiIcon name="loader-logo" />
                    </li>
                  </ul>
                </CSSTransition>
                <CSSTransition in={searchList.length > 0}
                               timeout={500}
                               mountOnEnter={true}
                               unmountOnExit={true}
                               nodeRef={nodeRefSearchList}
                               classNames="fade">
                  <ul className="service-article"
                      id="boardSearchList"
                      ref={nodeRefSearchList}>
                    {searchList.map((article, i) => {
                      return (
                        <li key={`list-button-${i}`}>
                          <button onClick={() => { this.openArticle(article) }}>
                          <span className="title">
                            {article.title}
                          </span>
                            <span className="date">
                            {format(new Date(article.createdAt), 'yyyy. MM. dd')}
                          </span>
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                </CSSTransition>
              </div>
            </div>
          </CSSTransition>

          <CSSTransition in={showArticle > 0}
                         timeout={500}
                         mountOnEnter={true}
                         unmountOnExit={true}
                         nodeRef={nodeRefArticle}
                         classNames="page-slide">
            <div className={`service-content ${showArticle < 0 ? 'backward' : ''}`}
                 ref={nodeRefArticle}>
              {article &&
                <div className="layout-container">
                  <h3 className="content-title">
                    {article.title}
                  </h3>
                  <div className="content-content">
                    <UiWysiwygContent content={article.content}
                                      allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li',
                                        'img', 'p', 'br', 'strong', 'i', 'em', 's', 'span']} />
                  </div>
                </div>
              }
            </div>
          </CSSTransition>
        </div>
      </div>
    )
  }
}

const StateContainer = (props) => StateConsumer(ServiceNotice, props)
export default withRouter(StateContainer)
