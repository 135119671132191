import { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { StateConsumer } from '../../context/StateProvider'
import Component from '../../utils/Component'

import UiIcon from '../../components/ui/Icon'
import UiTxt from '../../components/ui/Txt'

import './ServiceCommon.scss'

class ServiceTerm extends Component {
  state = {
    backward: false,
    currentTerm: ''
  }

  toTerm = (term) => {
    const {
      action
    } = this.props

    if (term) {
      action.setNavbarTitle('')
      action.setNavbarBackbutton(
        <UiIcon name="back"
                onClick={() => { this.toTerm('') }}/>
      )
      this.setState({
        backward: false
      })

    } else {
      this.setState({
        backward: true
      })
      this.setInitialTitle(true)
    }

    setTimeout(() => {
      this.setState({
        currentTerm: term
      })
    }, 0)
  }

  setInitialTitle (backward = false) {
    const {
      t,
      action,
      history
    } = this.props

    action.setNavbarTitle(t('setting.term.title'), backward)
    action.setNavbarBackbutton(
      <UiIcon name="back"
              onClick={() => { action.setMovingBack(history, '/service') }} />
    )
  }

  componentDidMount () {
    const {
      action,
      term
    } = this.props

    this.setInitialTitle()
    action.setNavbarButtons(null)
    action.setLayoutShowHeader(true)
    action.setLayoutShowFooter(false)

    if (term) {
      this.toTerm(term)
    }

  }

  render() {
    const nodeRefContent = createRef(null)

    const {
      backward,
      currentTerm
    } = this.state

    const {
      t
    } = this.props

    return(
      <TransitionGroup>
        <CSSTransition key={`${currentTerm}`}
                       timeout={500}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       nodeRef={nodeRefContent}
                       classNames="page-slide">
          <div className={`service-content term ${backward ? 'backward' : ''}`}
               ref={nodeRefContent}>
            <div className="layout-container">
              {!currentTerm &&
                <div className="service-center">
                  <ul className="service-menu">
                    <li>
                      <button onClick={() => { this.toTerm('service') }}>
                        <span className="value-left">
                          {t('setting.term.service')}
                        </span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow"/>
                        </span>
                      </button>
                    </li>
                    <li>
                      <button onClick={() => { this.toTerm('privacy') }}>
                        <span className="value-left">
                          {t('setting.term.privacy')}
                        </span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow"/>
                        </span>
                      </button>
                    </li>
                    {/*<li>*/}
                    {/*  <button onClick={() => { this.toTerm('openSource') }}>*/}
                    {/*    <span className="value-left">*/}
                    {/*      {t('setting.term.openSource')}*/}
                    {/*    </span>*/}
                    {/*    <span className="value-right">*/}
                    {/*      <UiIcon name="menu-arrow" />*/}
                    {/*    </span>*/}
                    {/*  </button>*/}
                    {/*</li>*/}
                    <li>
                      <button onClick={() => { this.toTerm('youth') }}>
                        <span className="value-left">
                          {t('setting.term.youth')}
                        </span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow"/>
                        </span>
                      </button>
                    </li>
                    <li>
                      <button onClick={() => { this.toTerm('operation') }}>
                        <span className="value-left">
                          {t('setting.term.operation')}
                        </span>
                        <span className="value-right">
                          <UiIcon name="menu-arrow"/>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              }

              {currentTerm === 'service' &&
                <div className="service-center">
                  <div className="term-content">
                    <UiTxt dataName="terms/service" />
                  </div>
                </div>
              }

              {currentTerm === 'privacy' &&
                <div className="service-center">
                  <div className="term-content">
                    <UiTxt dataName="terms/privacy" />
                  </div>
                </div>
              }

              {currentTerm === 'openSource' &&
                <div className="service-center">
                  <div className="term-content">
                    <UiTxt dataName="terms/openSource" />
                  </div>
                </div>
              }

              {currentTerm === 'youth' &&
                <div className="service-center">
                  <div className="term-content">
                    <UiTxt dataName="terms/youth" />
                  </div>
                </div>
              }

              {currentTerm === 'operation' &&
                <div className="service-center">
                  <div className="term-content">
                    <UiTxt dataName="terms/operation" />
                  </div>
                </div>
              }
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

const StateContainer = (props) => StateConsumer(ServiceTerm, props)
export default withRouter(StateContainer)
